import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSearchbar,
  IonItem,
  IonIcon,
  isPlatform
} from "@ionic/react";
import DataTable, { createTheme, defaultThemes } from "react-data-table-component";
import { Http } from '@capacitor-community/http';
import './Forms.css';
import Header from '../../components/Header/Header';
import { customStyles, columnsDesktop, columnsMobile } from './Constants'
import FormDetails from "./FormDetails";
import { searchIcon, refreshIcon } from '../../Constants/general'
import { authActions } from './../../store/auth'
import { useDispatch } from "react-redux";
import { get } from "./../../storage/storage";

const Forms: React.FC = () => {
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [filterBy, setFilterBy] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterError, setFilterError] = useState(false);
  const [isDesktop, setDesktop] = useState(false);
  const [isTablet, setTablet] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isIos,setIsIos] = useState(false);
 
  const dispatch = useDispatch();
  const fetchForms = async (page: any) => {
    
    setLoading(true);
    let searchText = (filterText) ? `&HoaDocumentSearch[${filterBy}]=${filterText}` : '';
    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/documents?hoa_id=${await get('loginUserHoaId')}&page=${page}&HoaDocumentSearch[hd_is_form]=1${searchText}`;
    const options = {
      url: encodeURI(url),
      headers: {
        authorization: `Bearer ${await get('token')}`
      },
    };

    const response = await Http.get(options);
    if (response.status != 200) {
      dispatch(authActions.logout());
      return;
    }
    setData(response.data.items);
    setTotalRows(response.data._meta.totalCount);
    setLoading(false);
  };
  const handlePageChange = (page: any) => {
    fetchForms(page);
  };
 
  useEffect(() => {
    fetchForms(1);
    if (isPlatform("desktop")) {
      setDesktop(true);
    }
    if (isPlatform("tablet")) {
      setTablet(true)
    }
    if (isPlatform("ios")) {
      setIsIos(true);
    }
    setFilterBy('')
    setFilterText('')
  }, [refresh]);
  const formsSearch = (e: any) => {
    e.preventDefault();
    if (!filterBy) {
      setFilterError(true);
      return
    }
    setFilterError(false);
    fetchForms(1);
  }
 
  return (
    <IonPage>
      <Header title='Forms' />
      <IonContent>
      <div className="filter-container" >
        <h3  className="page-tile" >
            Forms
          </h3>

          <form onSubmit={formsSearch}   className="filtet-form">
            <div className="filter-wrapper">
            <img className="refresh-page" src={refreshIcon} onClick={() => setRefresh(!refresh)} />
            <div className="filter-type-wrapper">
              <select className="filter-type" name="filter_by" value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
                <option value="">&nbsp;   Filter By</option>
                <option value="hd_title">&nbsp; Title</option>
                <option value="hd_description">&nbsp; Description</option>
              </select>
            </div>
            <div className={(isIos)?'filter-input-wrapper filter-input-wrapper-ios' :'filter-input-wrapper filter-input-wrapper-android'}>
                <button type="submit"> <img src={searchIcon} /> </button>
                <input type="text" className="filter-input" value={filterText} name="filter_text" onChange={(e) => setFilterText(e.target.value)} placeholder="&nbsp; Search" />
              </div>
            </div>
            {filterError && <div className="filter-error-msg">Please select column to filter by</div>}
          </form>
        </div>

<br />
        <div className="filterBottomLine"></div>

        <div className="formsTable">
          <DataTable
            columns={(isDesktop || isTablet) ? columnsDesktop : columnsMobile}
            data={data}
            progressPending={loading}
            expandableRows={true}
            selectableRowsComponentProps={{ inkDisabled: true }}
            expandableRowsComponent={<FormDetails />}
            expandOnRowClicked={true}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            customStyles={customStyles}
            striped={true}
            highlightOnHover={true}
          />
        </div>
      </IonContent>
    </IonPage >

  );

}

export default Forms;