import { createSlice } from '@reduxjs/toolkit';

const initialMenuState = {
    showMainMenu: true
  };

  const menuSlice  = createSlice({
    name: 'menuShowHide',
    initialState: initialMenuState,
    reducers: {
            toggleMenu(state ,action) {
              state.showMainMenu = action.payload.showMainMenu;
            }
          },
  });


  


export const menuActions = menuSlice.actions;

export default menuSlice.reducer;