import React, { useState, useEffect } from "react";
import {
    IonContent, IonPage, IonItem,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonSearchbar,
    IonButton,
    isPlatform
} from '@ionic/react'
import { searchIcon, refreshIcon } from '../../../Constants/general'
import runingImg from '../../../assets/sample-img/running.jpg'
import './Group.css'
const Group: React.FC = () => {

    const [filterBy, setFilterBy] = useState('');
    const [filterText, setFilterText] = useState('');
    const [filterError, setFilterError] = useState(false);
    const [isDesktop, setDesktop] = useState(false);
    const [isTablet, setTablet] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const searchContent = (e: any) => {
        e.preventDefault();
        if (!filterBy) {
            setFilterError(true);
            return
        }
        setFilterError(false);
        // fetchUsers(fiterBy,filterText);
    }
    useEffect(() => {
        if (isPlatform("desktop")) {
            setDesktop(true);
        }
        if (isPlatform("tablet")) {
            setTablet(true)
        }
        setFilterBy('')
        setFilterText('')
    }, [refresh]);

    return (
        <IonPage>
            <IonContent>
                <div className="groupBanner">
                    <div className="groupPhoto">
                        <img src={runingImg} />
                    </div>
                    <div className="groupDescription">
                        <div className="groupName">
                            Running Club        </div>
                        <div className="groupDescription">
                            <div className="groupDetail">For beginner runners, casual runners, and experienced runners who are looking to meet new people, get some exercise, and have fun. We welcome everyone and make it a point to make sure no one feels like they are left behind.

                                Come run with us!

                            </div>
                        </div>
                        <div className="groupUrl">
                            <a className="backTogroup" href="#">Back to My Groups</a> </div>
                    </div>
                </div>
                <div className="groupFilter">
                    <IonItem className="filterContainer">
                        {(isDesktop || isTablet) && <IonIcon className="refresh-page" src={refreshIcon} slot="end" onClick={() => setRefresh(!refresh)} />}
                        <form onSubmit={searchContent} slot="end" className="filtet-form" >
                            <div className="filter-wrapper">

                                <select className="filter-type" name="filter_by" value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
                                    <option value="">&nbsp;   Filter By</option>
                                    <option value="name"> &nbsp; Name</option>
                                    <option value="sescription"> &nbsp; Description</option>
                                    <option value="createdBy"> &nbsp; Created By</option>

                                </select>

                                <div className="filter-input-wrapper">
                                    <button type="submit"> <img src={searchIcon} /> </button>
                                    <input type="text" className="filter-input" value={filterText} name="filter_text" onChange={(e) => setFilterText(e.target.value)} placeholder="&nbsp; Search" />
                                </div>
                            </div>
                            {filterError && <div className="filter-error-msg">Please select column to filter by</div>}
                        </form>
                        <br />
                        {(isDesktop || isTablet) && <IonButton size="default" slot="end" >New Group</IonButton>}
                    </IonItem>
                </div>



            </IonContent>
        </IonPage>
    );
}

export default Group