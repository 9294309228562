
import React, { useState, useEffect } from 'react';
import {
    IonButton,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonIcon,
    IonContent,
    IonLabel,
    IonInput,
    IonTextarea
} from '@ionic/react';
import './Contact.css';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Http } from '@capacitor-community/http';
import Modal from '../../components/Modal/Modal';
import hoampageIcon from '../../assets/menu-icons/hoampage-icon.png'
import { authActions } from './../../store/auth'
import { useDispatch } from 'react-redux'
import { get } from "./../../storage/storage";

const Contact = (mangerData:any) => {
    const  mangerInfo = mangerData.mangerInfo
    
    const [isOpenModal, setOpenModal] = useState(false);

    const [successModal, setSuccessModal] = useState(false);
    const [buttonText, setButtonText] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [hoaId,setHoaId] = useState('');

    const dispatch = useDispatch();
   
    const {
        handleSubmit,
        register,
        getValues,
        setError,
        formState: { errors },
    } =
        useForm({
            defaultValues: {
                fullname: '',
                email: '',
                phone: '',
                message:''
            },
            mode: 'onBlur',
        });
        const onSubmit = async (data: any) => {

            let url = `${process.env.REACT_APP_HP_API_URL}/index.php/hoas/contact-community-manager?hoa_id=${await get('loginUserHoaId')}`;
            const options = {
                url: url,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: `Bearer ${await get('token')}`
                },
                data: {
                   
                    'ContactCommunityManagerForm[fullName]': getValues('fullname'),
                    'ContactCommunityManagerForm[email]': getValues('email'),
                    'ContactCommunityManagerForm[phone]': getValues('phone'),
                    'ContactCommunityManagerForm[message]': getValues('message'),
                },
            };
            const response = await Http.post(options);
            if (response.status !== 200) {
                dispatch(authActions.logout());
                return;
            }
            if (response.data.success) {
                closeModal()
                setSuccessModal(true);
                setTitle('THANK YOU!');
                setDescription(response.data.messageContent);
                setButtonText('Continue');
            } else {
                let errorMsg = response.data.validation
                for (const key in errorMsg) {
                    if (Object.prototype.hasOwnProperty.call(errorMsg, key)) {
                        const element = errorMsg[key];
                       
                        let inputName: any = key.replaceAll('contactcommunitymanagerform-', '');
                        setError(inputName, {
                            type: "manual",
                            message: element[0],
                        });
                    }
                }
            }
        };

    useEffect(() => {
       (async ()=>{
            setHoaId(await get('loginUserHoaId'));
        })();
       
       
    }, [errors]);

    const hidesuccessModal = async () => {
        await setSuccessModal(false);
    }
    const closeModal = async () => {
        await setOpenModal(false);

    }
    const showModal = async () => {
        await setOpenModal(true);

    }
    return (
        <>
            <div className="contactBoxContainer">
                <div className="contactBoxHeader" onClick={mangerData.contactBoxHandler}>
                    <span>Contact</span>
                    <span ></span>
                </div>
                <div className="contactBoxBody">
                    <div className="contactBoxDark">
                        <div className="contactBoxImg profile-image">
                                
                                {(mangerInfo.hcm_image !=null) && <img alt="community manager" src={`${process.env.REACT_APP_HP_API_URL}/index.php/hoas/view-image?hoa_id=${hoaId}&image=${mangerInfo?.hcm_image}`} />}
                                {(mangerInfo.hcm_image == null) && <img alt="community manager" src={`${process.env.REACT_APP_HP_API_URL}/index.php/auth/view-image?image=null`} />}
                                
                        </div>
                        <div className="manager-info">
                            <p className="manager-name">{`${mangerInfo.hcm_first_name} ${mangerInfo.hcm_last_name}`}</p>
                            <p className="manager-post">Community Manager</p>
                            <p className="manager-company">{mangerInfo.hcm_management_company}</p>
                            <IonButton mode="md" onClick={() => showModal()}>
                                Message
                            </IonButton>
                        </div>
                    </div>
                    <div className="community-info">
                        {mangerInfo.hcu_address && <p className="community-name">{mangerInfo.hoa_name}</p>}
                        <p className="community-address">
                            {mangerInfo.hcu_address}
                            {mangerInfo.hcu_address && <br />}

                            {mangerInfo.hcu_city}  {mangerInfo.hcu_zipcode}
                            {(mangerInfo.hcu_city || mangerInfo.hcu_zipcode) && <br />}
                        </p>
                        <p className="community-phone">{mangerInfo.hcu_phone_number}</p>
                        <p className="community-email">{mangerInfo.hcu_email_address}</p>
                    </div>
                    <div className="contactBoxFooter">
                    {mangerInfo?.hcu_map_link && 
                        <div>
                            <a href={mangerInfo.hcu_map_link} className="direction-link"> Get Directions</a>
                            <br /><br />
                        </div>}
                        
                        {mangerInfo.hcu_office_hours && <div className="community-time-info">
                            <p className="office-hours-lable">Office Hours</p>
                            <p dangerouslySetInnerHTML={{ __html: mangerInfo.hcu_office_hours }} />  
                        </div>}
                    </div>
                </div>
            </div>

            <IonModal isOpen={isOpenModal} >
                <IonHeader >
                    <IonToolbar>
                        <IonTitle>Send Message To {mangerInfo.hcm_first_name}</IonTitle>
                        <IonButtons slot='end'>
                            <IonButton onClick={() => closeModal()}>
                                <IonIcon name='close' slot='icon-only'></IonIcon>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className='ion-padding' >
                    <div className="manager-contact-form">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='form-input-group'>
                                <IonLabel className='form-input-label'>Name <span></span></IonLabel>
                                <IonInput
                                    {...register('fullname', {
                                        required: 'This is required',
                                    })}
                                    type='text'
                                    placeholder='Full name'
                                    className='form-input'
                                    mode="md"
                                // onIonChange={()}
                                ></IonInput>
                                <ErrorMessage
                                    errors={errors}
                                    name='fullname'
                                    as={<div className='error-message' />}
                                />
                            </div>
                            <div className="form-input-group-name">
                                <div className='form-input-group'>
                                    <IonLabel className='form-input-label'>Email Address<span></span></IonLabel>
                                    <IonInput
                                        {...register('email', {
                                            required: 'Email is a required field',
                                            pattern: {
                                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                              message: 'Invalid email address',
                                            },
                                          })}
                                        type='email'
                                        placeholder='Email Address'
                                        className='form-input'
                                        mode="md"
                                    ></IonInput>
                                    <ErrorMessage
                                        errors={errors}
                                        name='email'
                                        as={<div className='error-message' />}
                                    />
                                </div>
                                <div className='form-input-group'>
                                    <IonLabel className='form-input-label'>Phone Number<span></span></IonLabel>
                                    <IonInput
                                        {...register('phone')}
                                        type='text'
                                        placeholder='Phone Number'
                                        className='form-input'
                                        mode="md"
                                    ></IonInput>
                                    <ErrorMessage
                                        errors={errors}
                                        name='phone'
                                        as={<div className='error-message' />}
                                    />
                                </div>

                            </div>
                            <div className='form-input-group'>
                            <IonLabel mode="md" className='form-input-label'>Message<span></span></IonLabel>
                                <IonTextarea
                                    className='form-input'
                                    {...register('message', {
                                        required: 'This is required',
                                      })}
                                    placeholder='Message'
                                    rows={5}
                                    mode="md"
                                ></IonTextarea>
                                 <ErrorMessage
                                        errors={errors}
                                        name='message'
                                        as={<div className='error-message' />}
                                    />
                            </div>
                            <div className="submit-button"><IonButton  mode="md" type="submit">Submit</IonButton></div>
                        </form>
                    </div>
                </IonContent>
            </IonModal>

            <Modal
                logo={hoampageIcon}
                title={title}
                description={description}
                buttonText={buttonText}
                isOpen={successModal}
                closeAction={hidesuccessModal}
            ></Modal>

        </>
    );
}


export default Contact;