

import { useParams } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import {
    IonContent,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonSearchbar,
    IonIcon,
    IonItem,
    IonButton,
    IonAvatar,
    IonLabel,
    IonList,
    IonPopover,
    IonTextarea,
    isPlatform
} from "@ionic/react";
import { Http } from "@capacitor-community/http";
import DataTable, { createTheme, defaultThemes } from "react-data-table-component";
import './Threads.css';
import Header from '../../../components/Header/Header';
import profileIcon from "../../../assets/menu-icons/menuIcon.svg";
import NewDiscussion from './..//NewDiscussion/NewDiscussion'
import Modal from '../../../components/Modal/Modal';
import plusIcon from '../../../assets/menu-icons/plusIcon.svg';
import { searchIcon, refreshIcon } from '../../../Constants/general'
const Threads: React.FC = () => {

    const [filterBy, setFilterBy] = useState('');
    const [filterText, setFilterText] = useState('');
    const [filterError, setFilterError] = useState(false);
    const [isDesktop, setDesktop] = useState(false);
    const [isTablet, setTablet] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [successModal, setSuccessModal] = useState(false);
    const [successButton, setButtonText] = useState('');
    const [successtitle, setSuccessTitle] = useState('');
    const [successDescription, setSuccessDescription] = useState('');

    const closeModal = async () => {
        await setShowModal(false);

        setSuccessModal(true);
        setSuccessTitle('DONE!');
        setSuccessDescription('Your post has been added successfully!');
        setButtonText('Continue');

    }

    const hidesuccessModal = async () => {
        await setSuccessModal(false);
    }
    const [toggleMenu, setToggleMenu] = useState({
        showPopover: false,
        event: undefined,
    });

    const popoverHandler = (e: any) => {
        e.persist();
        setToggleMenu({ showPopover: true, event: e });
    };

    const onHide = (e: any) => {
        e.persist();
        setToggleMenu({ showPopover: false, event: e });
    };
    const searchPosts = (e: any) => {
        e.preventDefault();
        if (!filterBy) {
            setFilterError(true);
            return
        }
        setFilterError(false);
        // fetchUsers(1);
    }

    useEffect(() => {

        if (isPlatform("desktop")) {
            setDesktop(true);
        }
        if (isPlatform("tablet")) {
            setTablet(true)
        }
        setFilterBy('')
        setFilterText('')
    }, [refresh]);

    return (
        <IonPage >
            <Header title={(!isDesktop && !isTablet) ? 'Forums' : ''} />
            <IonContent >
                <IonItem className="filterContainer">
                    {(isDesktop || isTablet) && <h3 slot="start" className="page-tile" >
                        Forums
                    </h3>}
                    {(isDesktop || isTablet) && <IonIcon className="refresh-page" src={refreshIcon} slot="end" onClick={() => setRefresh(!refresh)} />}

                    <form onSubmit={searchPosts} slot="end"  >
                        <div className="filter-wrapper">

                            <select className="filter-type" name="filter_by" value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
                                <option value="">&nbsp;   Filter By</option>
                                <option value="forumContent">&nbsp; Content</option>
                                <option value="forumContent">&nbsp; Post By</option>

                            </select>

                            <div className="filter-input-wrapper">
                                <button type="submit"> <img src={searchIcon} /> </button>
                                <input type="text" className="filter-input" value={filterText} name="filter_text" onChange={(e) => setFilterText(e.target.value)} placeholder="&nbsp; Search" />
                            </div>
                        </div>
                        {filterError && <div className="filter-error-msg">Please select column to filter by</div>}
                    </form>
                    {(isDesktop || isTablet) && <IonButton size="default" slot="end" onClick={() => setShowModal(true)} >New Discussion</IonButton>}
                </IonItem>
                <div className="filterBottomLine"></div>
                {(!isDesktop && !isTablet) && <IonItem><IonButton size="default" slot="end" onClick={() => setShowModal(true)} >New Discussion</IonButton></IonItem>}

                <div className="threadContainer">
                    <IonItem className="backToForum">
                        <   a href="/Forums" slot="start" >Back to Forum</a>
                    </IonItem>
                    <IonItem className="threadTopic">

                        <div slot="start">Test Description</div>
                        <a href="#" slot="end">Unfollow Thread</a>
                    </IonItem>

                    <div className="threadBox threadBoxBackground">
                        <IonItem>
                            <IonAvatar slot="start">
                                <img src="https://bit.ly/2XIK7Wh" />
                            </IonAvatar>
                            <IonLabel>
                                <h3>Emil Matei</h3>
                                <small>11 minutes ago</small>
                            </IonLabel>
                            <img className="menuImage" slot="end" src={profileIcon} onClick={popoverHandler} />
                        </IonItem>

                        <div className="threadText">Posts in forums are made public to all registered Hoampage users in your community.
                            <div>Comments (20)</div>
                        </div>
                    </div>
                    <div className="threadBox threadReply">
                        <IonItem>
                            <IonAvatar slot="start">
                                <img src="https://bit.ly/2XIK7Wh" />
                            </IonAvatar>
                            <IonLabel>
                                <h3>Emil Matei</h3>
                                <small>11 minutes ago</small>
                            </IonLabel>
                            <img className="menuImage" slot="end" src={profileIcon} />
                        </IonItem>
                        <div className="threadText">Posts in forums are made public to all registered Hoampage users in your community.
                            <div>Comments (20)</div>
                        </div>
                    </div>
                    <div className="threadBox threadReply">
                        <IonItem>
                            <IonAvatar slot="start">
                                <img src="https://bit.ly/2XIK7Wh" />
                            </IonAvatar>
                            <IonLabel>
                                <h3>Emil Matei</h3>
                                <small>11 minutes ago</small>
                            </IonLabel>
                            <img className="menuImage" slot="end" src={profileIcon} />
                        </IonItem>
                        <div className="threadText">Posts in forums are made public to all registered Hoampage users in your community.
                            <div>Comments (20)</div>
                        </div>
                    </div>
                    <div className="threadBox threadReply">
                        <IonItem>
                            <IonAvatar slot="start">
                                <img src="https://bit.ly/2XIK7Wh" />
                            </IonAvatar>
                            <IonLabel>
                                <h3>Emil Matei</h3>
                                <small>11 minutes ago</small>
                            </IonLabel>
                            <img className="menuImage" slot="end" src={profileIcon} />
                        </IonItem>
                        <div className="threadText">Posts in forums are made public to all registered Hoampage users in your community.
                            <div>Comments (20)</div>
                        </div>
                    </div>
                    <div className="replyTextarea textareaMobile">
                        <IonTextarea placeholder="Write Something..." rows={4}></IonTextarea>
                        <IonButton>Post Reply</IonButton>
                    </div>
                </div>
            </IonContent >

            <IonPopover
                event={toggleMenu.event}
                isOpen={toggleMenu.showPopover}
                onDidDismiss={() =>
                    setToggleMenu({ showPopover: false, event: undefined })
                }
            >
                <IonList>
                    <IonItem detail={false} button key="edit">Edit Discussion</IonItem>
                    <IonItem routerDirection="none" detail={false} button routerLink="/Messages" key="delete">Delete Discussion</IonItem>
                    <IonItem routerDirection="none" detail={false} lines="none" button onClick={onHide} key="Closed">
                        Close
                    </IonItem>

                </IonList>
            </IonPopover>

            <NewDiscussion
                title="New Disscustion"
                isOpen={showModal}
                closeAction={closeModal}
            />
            <Modal
                logo={plusIcon}
                title={successtitle}
                description={successDescription}
                buttonText={successButton}
                isOpen={successModal}
                closeAction={hidesuccessModal}
            ></Modal>
        </IonPage >

    );
}
export default Threads;