import React, {useState,useEffect}from "react";
import {isPlatform, IonCard, IonCardContent, IonGrid, IonRow, IonCol} from "@ionic/react"


const MyListingDetail = (data: any) => {
    const [isDesktop, setDesktop] = useState(false);
    const [isTablet, setTablet] = useState(false);
    useEffect(() => {
        
        if (isPlatform("desktop")) {
          setDesktop(true);
        }
        if (isPlatform("tablet")) {
          setTablet(true)
        }
      }, []);
    return (
        <IonCard className="mylisting-detail">
            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonCol size={(isDesktop || isTablet)?'10':'12'}>
                            <IonRow>
                                <IonCol size={(isDesktop || isTablet)?'2':'4'} className="listing-label">Price</IonCol>
                                <IonCol size={(isDesktop || isTablet)?'10':'8'} className="listing-info">$8,000</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size={(isDesktop || isTablet)?'2':'4'} className="listing-label">Category</IonCol>
                                <IonCol size={(isDesktop || isTablet)?'10':'8'} className="listing-info" >Auto & Transportation</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size={(isDesktop || isTablet)?'2':'4'} className="listing-label">Date</IonCol>
                                <IonCol size={(isDesktop || isTablet)?'10':'8'} className="listing-info" >October 18, 2021</IonCol></IonRow>
                            <IonRow>
                                <IonCol size={(isDesktop || isTablet)?'2':'4'} className="listing-label">Description</IonCol>
                                <IonCol size={(isDesktop || isTablet)?'10':'8'} className="listing-info" >Pale blue and green bike with new tires and added baskets.</IonCol>
                            </IonRow>

                        </IonCol>
                        <IonCol size={(isDesktop || isTablet)?'2':'12'}>
                           
                                <div className="listing-option"><a href="#">View Listing</a></div>
                                <div className="listing-option"><a href="#">Edit Listing</a></div>
                                <div className="listing-option"><a href="#">Edit Photos (1)</a></div>
                                <div className="listing-option"><a href="#">Delete Listing</a></div>
                           
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
}

export default MyListingDetail;