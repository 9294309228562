import React from "react";
import {IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent} from '@ionic/react'
   import  '../LoginWrapper/LoginWrapper.css'
    type ModalProps = {
        heading?: string;
        title: string;
        description: string;
        isOpen: boolean;
        closeAction: Function;
      };

const PolicyModal:React.FC<ModalProps> = (props)=>{
    const { title, description, heading, isOpen, closeAction } = props;

        return(<IonModal isOpen={isOpen} cssClass="policyModal" >
            <IonHeader >
                <IonToolbar>
                    <IonTitle>{title}</IonTitle>
                    <IonButtons slot='end'>
                        <IonButton onClick={() => closeAction()}>
                            <IonIcon name='close' slot='icon-only'></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className='ion-padding' >
            <div dangerouslySetInnerHTML={{ __html:description }} />
               
            </IonContent>
        </IonModal>);
}
export default PolicyModal;