export const customStyles = {
  headRow: {
    style: {
      background: "#606163",
      paddingTop: "12px",
      paddingBottom: "12px"
    },
  },
  headCells: {
    style: {
      color: "#ffffff",
      fontSize: "18px",
      fontWeight: "500",
      pointerEvents: "none"
    },
  },

  rows: {
    style: {
      backgroundColor: '#ffffff',
      borderBottomColor: '#ffffff',
      // outline: '1px solid #9d9fa6',
      paddingTop: "8px",
      paddingBottom: "8px", textDecoration: "none"
    },
  },
  cells: {
    style: {
      textDecoration: "none",
      color: "#7c7d7f",
      fontSize: "16px",
    }
  },
  highlightOnHoverStyle: {
    color: "#fffff",
  }

};

export var columnsDesktop = [
  {
    name: "NAME",
    cell: (row: any) => `${row.hp_fname} ${row.hp_lname}`,
    // sortable: true,
  },
  {
    name: "TYPE",
    selector: (row: any) => row.type,
    // sortable: true,
  },
  {
    name: "PHONE",
    selector: (row: any) => row.hp_phone_number,
    // sortable: true,
  },
  {
    name: "EMAIL",
    selector: (row: any) => row.hp_email,
    // sortable: true,
  },
  {
    name: "STATUS",
    selector: (row: any) => row.statusLabel,
    // sortable: true,
  }
];

export const columnsMobile = [{
  name: "NAME",
  cell: (row: any) => `${row.hp_fname} ${row.hp_lname}`,
  // sortable: true,
}];


