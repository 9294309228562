import React from 'react';
import { IonContent, IonButton, IonModal } from '@ionic/react';
import './ErrorMessage.css';

type ErrorMessageProps = {
  description: string;
};

const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
  const { description } = props;

  return <div className='error-message'>{description}</div>;
};

export default ({ description }: ErrorMessageProps) => (
  <ErrorMessage description={description}></ErrorMessage>
);
