import React, { useEffect, useState } from "react";
import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonLabel,
    IonInput
} from '@ionic/react'
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Http } from '@capacitor-community/http';
import { authActions } from './../../../store/auth'
import { useDispatch } from 'react-redux'
import { get } from "../../../storage/storage";

type ModalProps = {
    isOpen: boolean;
    closeAction: Function;
    name:string,
    email:string,
    phone:string
    hpId:string
};
const DirectoryDetails: React.FC<ModalProps> = (props) => {
    const { isOpen, closeAction, name, email, phone,hpId } = props;
    
    const dispatch = useDispatch();
    
    const onSubmit = async (data: any) => {
        let url = `${process.env.REACT_APP_HP_API_URL}/index.php/auth/settings-save?hp_id=${hpId}`;
        const options = {
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${await get('token')}`
            },
            data: {
                'UserAccountSettings[hpds_name]': getValues('hpds_name'),
                'UserAccountSettings[hpds_phone_number]': getValues('hpds_phone_number'),
                'UserAccountSettings[hpds_email_address]': getValues('hpds_email_address'),
            },
        };
        const response = await Http.post(options);
        if (response.status != 200) {
            dispatch(authActions.logout());
            return;
        }
        if (response.data.success) {
           
        } else {
            let errorMsg = response.data
            for (const key in errorMsg) {
                if (Object.prototype.hasOwnProperty.call(errorMsg, key)) {
                    const element = errorMsg[key];
                   
                    let inputName: any = key.replaceAll('user-', '');
                    setError(inputName, {
                        type: "manual",
                        message: element[0],
                    });
                }
            }
        }
    }
    const {
        handleSubmit,
        register,
        getValues,
        setValue,
        setError,
        formState: { errors },
    } =
        useForm({
            defaultValues: {
                hpds_name: '',
                hpds_phone_number: '',
                hpds_email_address: ''
            },
            mode: 'onBlur',
        });
    useEffect(() => {
        setValue('hpds_name',name);
        setValue('hpds_email_address',email);
        setValue('hpds_phone_number',phone);
    }, [errors,name,email,phone]);

    return (
        <IonModal isOpen={isOpen} >
            <IonHeader >
                <IonToolbar>
                    <IonTitle>Directory Details</IonTitle>
                    <IonButtons slot='end'>
                        <IonButton onClick={() => closeAction()}>
                            <IonIcon name='close' slot='icon-only'></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className='ion-padding' >
                <div className="details-from">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='form-input-group'>
                            <IonLabel className='form-input-label'>Name(s)*</IonLabel>
                            <IonInput
                                {...register('hpds_name')}
                                // , {
                                //     required: 'This is required',
                                // }
                                type='text'
                                placeholder='Full name'
                                className='form-input'
                            ></IonInput>
                            <ErrorMessage
                                errors={errors}
                                name='hpds_name'
                                as={<div className='error-message' />}
                            />
                        </div>

                        <div className='form-input-group'>
                            <IonLabel className='form-input-label'>Phone number</IonLabel>
                            <IonInput
                                {...register('hpds_phone_number')}
                                type='text'
                                placeholder='Phone Number'
                                className='form-input'
                            ></IonInput>
                            <ErrorMessage
                                errors={errors}
                                name='hpds_phone_number'
                                as={<div className='error-message' />}
                            />
                        </div>
                        <div className='form-input-group'>
                            <IonLabel className='form-input-label'>Email Address</IonLabel>
                            <IonInput
                                {...register('hpds_email_address')}
                                type='email'
                                placeholder='Email Address'
                                className='form-input'
                            ></IonInput>
                            <ErrorMessage
                                errors={errors}
                                name='hpds_email_address'
                                as={<div className='error-message' />}
                            />
                        </div>
                        <div className="setting-button"><IonButton type="submit">Save Changes</IonButton></div>
                    </form>
                </div>
            </IonContent>
        </IonModal>
    );
}

export default DirectoryDetails;