/*
* 
*
* 
*
* 
* 
*/
import React from 'react';
import { personCircle } from 'ionicons/icons';
import { IonContent, IonPage,IonIcon, IonHeader, IonToolbar, IonTitle, IonButtons, IonMenuButton } from '@ionic/react';
import './ExploreGroups.css';
import Header from '../../../components/Header/Header';
const ExploreGroups: React.FC = ()=>{
       
        return (
          
                <IonPage >
                    <Header title="Explore Groups" />
                    <IonContent className='cameraContent'>
                        <h1 className="ion-text-center">ExploreGroups</h1>
                    </IonContent >
                </IonPage >
          
        );
    }


export default ExploreGroups;