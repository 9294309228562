import { Route, Redirect, Switch } from "react-router-dom";
import { IonApp, IonRouterOutlet, IonSplitPane, isPlatform } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, { useEffect, useState } from "react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./assets/fonts/OpenSans/font-face.css";
import "./assets/fonts/GaramondRegular/font-face.css";
import "./assets/fonts/Prata/font-face.css";
import "./assets/fonts/CenturyGothic/font-face.css";
import "./assets/fonts/CenturySchoolbook/font-face.css";
import "./assets/fonts/FontAwesome/font-face.css";
import "./theme/variables.css";
import "./theme/theme.css";

/* Custom imports */
import Menu from "./components/Menu/Menu";
import Home from "./pages/Home/Home";
import Login from "./pages/Users/Login/Login"
import Forgot from "./pages/Users/Forgot/Forgot";
import BoardofDirectors from "./pages/BoardofDirectors/BoardofDirectors";
import Calendar from "./pages/Calendar/Calendar";
import Directory from "./pages/Directory/Directory";
import Documents from "./pages/Documents/Documents";
import FAQ from "./pages/FAQ/FAQ";
import Forms from "./pages/Forms/Forms";
import News from "./pages/News/News";
import UsefulLinks from "./pages/UsefulLinks/UsefulLinks";
import Forums from "./pages/Forums/Forums";
import MyListings from "./pages/ForSales/MyListings/MyListings";
import AllListings from "./pages/ForSales/AllListings/AllListings";
import MyGroups from "./pages/Groups/MyGroups/MyGroups";
import ExploreGroups from "./pages/Groups/ExploreGroups/ExploreGroups";
import Group from "./pages/Groups/Group/Group";
import MyAccount from "./pages/MyAccount/MyAccount";
import Messages from "./pages/Messages/Messages";
import Household from "./pages/Household/Household";
import Settings from "./pages/Settings/Settings";
import PaymentPortal from "./pages/PaymentPortal/PaymentPortal";
import { useSelector } from "react-redux";
import { isUserloggedIn } from "./store/loginAction";
import { useDispatch } from "react-redux";
import { Http } from "@capacitor-community/http";
import Members from "./pages/Members/Members";
import Signup from "./pages/Users/Signup/Signup";
import ListingDetails from "./pages/ForSales/ListingDetails/ListingDetails";
import Threads from "./pages/Forums/Threads/Threads";
import LandingPage from "./pages/website/LandingPage";
let currentPath=  '';
const App: React.FC = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );

  useEffect(() => {
    dispatch(isUserloggedIn());
    currentPath = window.location.pathname.toLowerCase();
  }, [dispatch]);
  
  return (
    <IonApp>

      {!isAuthenticated && (
        <IonReactRouter>
          <IonRouterOutlet id="main">
            <Switch>
              <Route exact path="/Login">
                <Login />
              </Route>
              <Route exact path="/Signup">
                <Signup />
              </Route>
              <Route exact path="/Forgot">
                <Forgot />
              </Route>
              <Route exact path="/hoawebsite">
                <LandingPage />
              </Route>
              <Redirect to="/Login" />
            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
      )}

      {(isAuthenticated && currentPath ==='/hoawebsite') &&(
        <IonReactRouter>
          <IonRouterOutlet id="main">
            <Switch>
              <Route exact path="/hoawebsite">
                <LandingPage />
              </Route>
              <Redirect to="/hoawebsite" />
            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
      )}

      {(isAuthenticated && currentPath !=='/hoawebsite') && (
        <IonSplitPane contentId="main" disabled={false} className="sideSplitPane">
          <IonReactRouter>
            <Menu />
            <IonRouterOutlet id="main">
              <Switch>

                <Route exact path="/Home" >
                  <Home />
                </Route>

                <Route exact path="/BoardofDirectors">
                  <BoardofDirectors />
                </Route>

                <Route exact path="/Calendar">
                  <Calendar />
                </Route>

                <Route exact path="/Directory">
                  <Directory />
                </Route>

                <Route exact path="/Documents">
                  <Documents></Documents>
                </Route>

                <Route exact path="/FAQ">
                  <FAQ />
                </Route>

                <Route exact path="/Forms">
                  <Forms />
                </Route>

                <Route exact path="/News">
                  <News />
                </Route>

                <Route exact path="/UsefulLinks">
                  <UsefulLinks />
                </Route>

                <Route exact path="/Forums">
                  <Forums />
                </Route>

                <Route exact path="/Forums/Threads/:threadId">
                  <Threads />
                </Route>

                <Route exact path="/ForSales/MyListings">
                  <MyListings />
                </Route>

                <Route exact path="/ForSales/AllListings">
                  <AllListings />
                </Route>
                <Route exact path="/ForSales/listingDetails/:listingId">
                  <ListingDetails />
                </Route>

                <Route exact path="/Groups/MyGroups">
                  <MyGroups />
                </Route>

                <Route exact path="/Groups/ExploreGroups">
                  <ExploreGroups />
                </Route>
                <Route exact path="/Groups/Group/:groupId">
                  <Group />
                </Route>

                <Route exact path="/MyAccount">
                  <MyAccount />
                </Route>

                <Route exact path="/Messages">
                  <Messages isHomeWidget="false" />
                </Route>

                <Route exact path="/Household">
                  <Household />
                </Route>

                <Route exact path="/Settings">
                  <Settings />
                </Route>
                <Route exact path="/Members">
                  <Members />
                </Route>
                <Route exact path="/PaymentPortal">
                  <PaymentPortal />
                </Route>
                <Redirect to="/Home" />
              </Switch>
            </IonRouterOutlet>
          </IonReactRouter>
        </IonSplitPane>
      )}
    </IonApp>
  );
};

export default App;
