
import React, { useState, useEffect } from 'react';
import {
  IonButton,
  IonSearchbar,
  IonSelectOption,
  IonSelect,
  IonItem,
  isPlatform,
  IonContent,
  IonPage,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from '@ionic/react';
import './MyGroups.css';
import Header from '../../../components/Header/Header';
import runingImg from '../../../assets/sample-img/running.jpg'
import calendarWhiteIcon from '../../../assets/menu-icons/calendarWhiteIcon.svg'
import { searchIcon, refreshIcon } from '../../../Constants/general'
const MyGroups: React.FC = () => {

  const [filterBy, setFilterBy] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterError, setFilterError] = useState(false);
  const [isDesktop, setDesktop] = useState(false);
  const [isTablet, setTablet] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isIos, setIsIos] = useState(false);
  
  const seacrGroup = (e: any) => {
    e.preventDefault();
    if (!filterBy) {
      setFilterError(true);
      return
    }
    // fetchUsers(fiterBy,filterText);
  }

  useEffect(() => {

    if (isPlatform("desktop")) {
      setDesktop(true);
    }
    if (isPlatform("tablet")) {
      setTablet(true)
    }
    if (isPlatform("ios")) {
      setIsIos(true);
    }
    setFilterBy('')
    setFilterText('')
  }, [refresh]);

  return (

    <IonPage >
      <Header title="My Groups" />
      <IonContent >
        <div className="filter-container mygroup-filter" >
          <h3  className="page-tile" >
            My Groups
          </h3>

          <form onSubmit={seacrGroup}  className="filtet-form" >
            <div className="filter-wrapper">
              <img className="refresh-page" src={refreshIcon} onClick={() => setRefresh(!refresh)} />
              <div className="filter-type-wrapper">
                <select className="filter-type" name="filter_by" value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
                  <option value="">&nbsp;   Filter By</option>
                  <option value="name"> &nbsp; Name</option>
                  <option value="sescription"> &nbsp; Description</option>
                  <option value="createdBy"> &nbsp; Created By</option>
                </select>
              </div>
              <div className={(isIos) ? 'filter-input-wrapper filter-input-wrapper-ios' : 'filter-input-wrapper filter-input-wrapper-android'}>
                <button type="submit"> <img src={searchIcon} /> </button>
                <input type="text" className="filter-input" value={filterText} name="filter_text" onChange={(e) => setFilterText(e.target.value)} placeholder="&nbsp; Search" />
              </div>
              {(isDesktop || isTablet) && <IonButton mode="md" size="default" slot="end" >New Group</IonButton>}
            </div>

            {filterError && <div className="filter-error-msg">Please select column to filter by</div>}
          </form>

        </div>
        {(isDesktop || isTablet) && <IonItem > <a href="/Calendar" slot="start" className="myCalendarLink"><IonIcon src={calendarWhiteIcon} />  View My Calendar</a></IonItem>}
<br />
        <div className="filterBottomLine"></div>
        {(!isDesktop && !isTablet) && <IonItem>
          <a href="/Calendar" slot="start" className="myCalendarLink"><IonIcon src={calendarWhiteIcon} />  View My Calendar</a>
          <IonButton mode="md" size="default" slot="end" >New Group</IonButton>
        </IonItem>}
        <div className="mygroupContainer">
          <IonGrid>
            <IonRow >

              <IonCol className="ion-align-self-center" size={(isDesktop || isTablet) ? "3" : "12"}>
                <div className="groupCardContainer">
                  <IonCard >
                    <img src={runingImg} className="groupImage" />
                    <IonCardHeader>
                      <div className="groupName">
                        <IonCardTitle>
                          Running Club
                        </IonCardTitle>
                        <IonCardSubtitle >
                          Last updated 2 months ago
                        </IonCardSubtitle>
                      </div>
                    </IonCardHeader>
                    <IonCardContent >
                      <div className="groupInfo"><label htmlFor="">Members &nbsp;</label>   <span>6</span></div>
                      <div className="groupInfo"><label htmlFor="">Created By</label><span>Erica Saurin  </span></div>
                      <div className="groupViewBtn">
                        <IonButton size="default" href="/Groups/Group/3" className="">View Group</IonButton>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </div>
              </IonCol>

              <IonCol size={(isDesktop || isTablet) ? "3" : "12"}>
                <div className="groupCardContainer">
                  <IonCard >
                    <img src={runingImg} className="groupImage" />
                    <IonCardHeader>
                      <div className="groupName">
                        <IonCardTitle>
                          Running Club
                        </IonCardTitle>
                        <IonCardSubtitle >
                          Last updated 2 months ago
                        </IonCardSubtitle>
                      </div>
                    </IonCardHeader>
                    <IonCardContent >
                      <div className="groupInfo"><label htmlFor="">Members &nbsp;</label>   <span>6</span></div>
                      <div className="groupInfo"><label htmlFor="">Created By</label><span>Erica Saurin  </span></div>
                      <div className="groupViewBtn">
                        <IonButton size="default" className="" href="/Groups/Group/3">View Group</IonButton>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </div>
              </IonCol>

              <IonCol size={(isDesktop || isTablet) ? "3" : "12"}>
                <div className="groupCardContainer">
                  <IonCard >
                    <img src={runingImg} className="groupImage" />
                    <IonCardHeader>
                      <div className="groupName">
                        <IonCardTitle>
                          Running Club
                        </IonCardTitle>
                        <IonCardSubtitle >
                          Last updated 2 months ago
                        </IonCardSubtitle>
                      </div>
                    </IonCardHeader>
                    <IonCardContent >
                      <div className="groupInfo"><label htmlFor="">Members &nbsp;</label>   <span>6</span></div>
                      <div className="groupInfo"><label htmlFor="">Created By</label><span>Erica Saurin  </span></div>
                      <div className="groupViewBtn">
                        <IonButton size="default" href="/Groups/Group/1" className="">View Group</IonButton>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </div>
              </IonCol>

              <IonCol size={(isDesktop || isTablet) ? "3" : "12"}>
                <div className="groupCardContainer">
                  <IonCard >
                    <img src={runingImg} className="groupImage" />
                    <IonCardHeader>
                      <div className="groupName">
                        <IonCardTitle>
                          Running Club
                        </IonCardTitle>
                        <IonCardSubtitle >
                          Last updated 2 months ago
                        </IonCardSubtitle>
                      </div>
                    </IonCardHeader>
                    <IonCardContent >
                      <div className="groupInfo"><label htmlFor="">Members &nbsp;</label>   <span>6</span></div>
                      <div className="groupInfo"><label htmlFor="">Created By</label><span>Erica Saurin  </span></div>
                      <div className="groupViewBtn">
                        <IonButton size="default" className="" href="/Groups/Group/3">View Group</IonButton>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent >
    </IonPage >

  );
}


export default MyGroups;