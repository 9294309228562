import { authActions } from "./auth";
import { get, clear } from "../storage/storage";
export const isUserloggedIn = () => {
	return (dispatch) => {
		const localStoreHandler = async () => {
			const storedToken = await get("token");

			if (storedToken) {
				dispatch(
					authActions.login({
						isAuthenticated: true,
						token: storedToken,
						loginUserFname: await get("loginUserFname"),
						loginUserLname: await get("loginUserLname"),
						loginUserId: await get("loginUserId"),
						loginUserPropertyId: await get("loginUserPropertyId"),
						loginUserPhoto: await get("loginUserPhoto"),
						loginUserHoaId: await get("loginUserHoaId"),
						loginUserRole: await get("loginUserRole"),
						loginUserAddress: await get("loginUserAddress"),
						loginUserCity: await get("loginUserCity"),
						loginUserState: await get("loginUserState"),
						loginUserZipcode: await get("loginUserZipcode"),
						loginUserLeftMenu: await get("loginUserLeftMenu"),
						loginUserHoaName: await get("loginUserHoaName"),
						loginUserHoaSubscription: await get("loginUserHoaSubscription")
					})
				);
			} else {
				dispatch(authActions.login({
					isAuthenticated: false,
					token: "",
					loginUserFname: "",
					loginUserLname: "",
					loginUserId: "",
					loginUserPropertyId: "",
					loginUserPhoto: "",
					loginUserHoaId: "",
					loginUserRole: "",
					loginUserAddress: "",
					loginUserCity: "",
					loginUserState: "",
					loginUserZipcode: "",
					loginUserLeftMenu: "",
					loginUserHoaName: "",
					loginUserHoaSubscription: ""
				}));
				await clear();
			}
		}
		localStoreHandler();
	};
};
