import { createSlice } from "@reduxjs/toolkit";
import { set, clear } from "../storage/storage";


const initialAuthState = {
	isAuthenticated: false,
	token: "",
	loginUserId: "",
	loginUserFname: "",
	loginUserLname: "",
	loginUserPropertyId: "",
	loginUserRole: "",
	loginUserAddress: "",
	loginUserCity: "",
	loginUserState: "",
	loginUserZipcode: "",
	loginUserLeftMenu: "",
	loginUserHoaName: "",
};

const authSlice = createSlice({
	name: "authentication",
	initialState: initialAuthState,
	reducers: {
		login(state, action) {
			state.isAuthenticated = action.payload.isAuthenticated;
			state.token = action.payload.token;
			state.loginUserFname = action.payload.loginUserFname;
			state.loginUserLname = action.payload.loginUserLname;
			state.loginUserId = action.payload.loginUserId;
			state.loginUserPhoto = action.payload.loginUserPhoto;
			state.loginUserPropertyId = action.payload.loginUserPropertyId;
			state.loginUserHoaId = action.payload.loginUserHoaId;
			state.loginUserHoaName = action.payload.loginUserHoaName;
			state.loginUserLeftMenu = action.payload.loginUserLeftMenu;
			state.loginUserHoaSubscription = action.payload.loginUserHoaSubscription;

			const localStore = async () => {
				await set("token", action.payload.token);
				await set("loginUserFname", action.payload.loginUserFname);
				await set("loginUserLname", action.payload.loginUserLname);
				await set("loginUserId", action.payload.loginUserId);
				await set("loginUserPhoto", action.payload.loginUserPhoto);
				await set("loginUserPropertyId", action.payload.loginUserPropertyId);
				await set("loginUserHoaId", action.payload.loginUserHoaId);

				// this info not store in state
				await set("loginUserRole", action.payload.loginUserRole);
				await set("loginUserAddress", action.payload.loginUserAddress);
				await set("loginUserCity", action.payload.loginUserCity);
				await set("loginUserState", action.payload.loginUserState);
				await set("loginUserZipcode", action.payload.loginUserZipcode);
				await set("loginUserLeftMenu", action.payload.loginUserLeftMenu);
				await set("loginUserHoaName", action.payload.loginUserHoaName);
				await set("loginUserHoaSubscription", action.payload.loginUserHoaSubscription);
			}
			localStore();
		},
		logout(state) {
			state.isAuthenticated = false;
			state.token = "";
			state.loginUserId = "";
			state.loginUserFname = "";
			state.loginUserLname = "";
			state.loginUserPropertyId = "";
			state.loginUserPhoto = "";
			state.loginUserHoaId = "";
			state.loginUserHoaSubscription = "";

			const clearStore = async ()=>{
				await clear();
			}
			clearStore()
		},
	},
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
