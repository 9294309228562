/** */
import { Http } from '@capacitor-community/http';
import {
  IonRadioGroup,
  IonItem, IonRadio, IonButton, IonCheckbox, IonInput, IonLabel, IonRouterLink, IonText
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import LargeModal from '../../../components/Modal/LargeModal';
import { authActions } from '../../../store/auth';
import LoginWrapper from '../LoginWrapper/LoginWrapper';
import './Login.css';

type Error = {
  email: string;
  password: string;
  wrongCredentials:string
};
interface propertyState {
  hoaId: string,
  propertyId: string,
  propertyAccountNumber: string,
  propertyIdentifier: string,
  unitNumber: string,
  roleId: string
  role: string,
  address: string,
  city: string,
  state: string,
  zipcode: string,
  hoaName:string,
  hoaLeftMenu:Object,
  subscription: string
}
let loginApiResponse = {
  token: '',
  user_fname: '',
  user_lname: '',
  userPhoto: '',
  hoaId: '',
  user_id: ''
}

const Login: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [serverErrors, setServerErrors] = useState<Error>({
    email: '',
    password: '',
    wrongCredentials:''
  });
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const emailRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);
  const [resetCode, setResetCode] = useState(urlParams.get('reset_code'));
  const [resetEmail, setResetEmail] = useState(urlParams.get('reset_email'));
  const [showModal, setShowModal] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [defaultProperty, setDefaultProperty] = useState(false);
  const [property, setSetproperty] = useState<propertyState[]>([]);

  async function closeModal() {
    await setShowModal(false);
    setButtonText('');
    setTitle('');
    setDescription('');
    history.replace('/login');
  }

  const sendReset = async (code: string, email: string) => {
    const url =
      process.env.REACT_APP_HP_API_URL +
      '/index.php/auth/forgot-password-confirm';
    const options = {
      url: url,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: {
        reset_code: code,
        reset_email: email,
      },
    };

    const response = await Http.post(options);

    if (response.status == 200) {
      if (response && response.data) {
        if (response.data.success) {
          setTitle('GREAT!');
          setDescription(
            `Your password has been changed to: ${response.data.newPassword}`
          );
          setButtonText('Continue');
          setShowModal(true);
        }
      }
    } else if (response.status == 403) {
      setTitle('OOPS');
      setDescription(
        'Your password reset link has expired. Please request another link.'
      );
      setButtonText('Ok');
      setShowModal(true);
    }

    //setServerErrors('Invalid email or password');
  };

  useEffect(() => {
    if (resetCode && resetEmail) {
      sendReset(resetCode, resetEmail);
    }
  }, [resetCode, resetEmail]);

  /**
   *
   * @param data
   */
  const handleLogin = async () => {
    if (!emailRef.current?.value) {
      
      setServerErrors((prevState) => ({
        ...prevState,
        email: 'This filed is required.',
      }));
    }
    if (!passwordRef.current?.value) {
      setServerErrors((prevState)=>({
        ...prevState,
        password: 'This filed is required.',
      }));
    }
    if (emailRef.current?.value && passwordRef.current?.value) {
      const url = process.env.REACT_APP_HP_API_URL + '/index.php/auth/login';

      const options = {
        url: url,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          'LoginForm[username]': emailRef.current.value,
          'LoginForm[password]': passwordRef.current.value,
        },
      };
      const response = await Http.post(options);
      if (response.status == 200) {
        let properties = response.data.properties.propertiesList

        if (properties.length > 1) {
          // Homeowner has multiple properties
          loginApiResponse = response.data
          setDefaultProperty(true);
          setSetproperty(properties)
        } else {
          // Homeowner has just one property
          dispatch(
            authActions.login({
              isAuthenticated: true,
              token: response.data.token,
              loginUserFname: response.data.user_fname,
              loginUserLname: response.data.user_lname,
              loginUserId: response.data.user_id,
              loginUserPhoto: response.data.userPhoto,
              
              loginUserHoaId: response.data.properties.propertiesList[0].hoaId,
              loginUserPropertyId: response.data.properties.propertiesList[0].propertyId,

              loginUserRole: response.data.properties.propertiesList[0].role,
              loginUserAddress: response.data.properties.propertiesList[0].address,
              loginUserCity: response.data.properties.propertiesList[0].city,
              loginUserState: response.data.properties.propertiesList[0].state,
              loginUserZipcode: response.data.properties.propertiesList[0].zipcode,
              loginUserLeftMenu: JSON.stringify( response.data.properties.propertiesList[0].hoaLeftMenu),
              loginUserHoaName: response.data.properties.propertiesList[0].hoaName,
              loginUserHoaSubscription: response.data.properties.propertiesList[0].subscription

            })
          );
        }
        history.push(`/Home?community=${response.data.properties.propertiesList[0].hoaWebsite}`);

      } else if(response.status == 403){
        setServerErrors({ ...serverErrors, wrongCredentials: "Invalid username or password"});
      }
    }
  };

  const defaultPropertyHandler = (event: any) => {

    for (let i = 0; i < property.length; i++) {
      let single = property[i];
      if (single.propertyId == event.detail.value) {
        dispatch(
          authActions.login({
            isAuthenticated: true,
            token: loginApiResponse.token,
            loginUserFname: loginApiResponse.user_fname,
            loginUserLname: loginApiResponse.user_lname,
            loginUserId: loginApiResponse.user_id,
            loginUserPhoto: loginApiResponse.userPhoto,
            loginUserPropertyId: event.detail.value,
            loginUserHoaId: single.hoaId,
            loginUserRole: single.role,
            loginUserAddress: single.address,
            loginUserCity: single.city,
            loginUserState: single.state,
            loginUserZipcode: single.zipcode,
            loginUserLeftMenu: JSON.stringify(single.hoaLeftMenu),
            loginUserHoaName: single.hoaName,
            loginUserHoaSubscription: single.subscription
          })
        );
      }

    }


  }

  return (
    <LoginWrapper>
      {defaultProperty &&
        <div className='select-property'>
          <div className="title">Select Property</div>
          <IonRadioGroup onIonChange={defaultPropertyHandler}>
            {property.map((singleProp) =>
              <IonItem key={singleProp.propertyId}>
                <IonLabel>{`${singleProp.propertyIdentifier}`}</IonLabel>
                <IonRadio value={singleProp.propertyId} allow-empty-selection="true" />
              </IonItem>
            )}
          </IonRadioGroup>
        </div>
      }
      {!defaultProperty &&
        (<>
          <div className='form-login'>
            <div className='login-email'>
              <IonLabel className='control-label label--required'>Email Address</IonLabel>
              <IonInput
                className={
                  serverErrors.email
                    ? 'login-input-styles-error'
                    : 'login-input-styles'
                }
                type='text'
                placeholder='Email Address*'
                onIonChange={() => setServerErrors({ ...serverErrors, email: '' })}
                ref={emailRef}
              />
              {serverErrors.email && (
                <ErrorMessage description={serverErrors.email} />
              )}
            </div>
            <div className='login-email'>
              <IonLabel className='control-label label--required'>Password</IonLabel>
              <IonInput
                className={
                  serverErrors.password
                    ? 'login-input-styles-error'
                    : 'login-input-styles'
                }
                type='password'
                placeholder='Password*'
                onIonChange={() =>
                  setServerErrors({ ...serverErrors, password: '' })
                }
                ref={passwordRef}
              />
              {serverErrors.password && (
                <ErrorMessage description={serverErrors.password} />
              )}
            </div>
            {serverErrors.wrongCredentials && (
              <div className="wrong-credentials">{serverErrors.wrongCredentials}</div>
              )}
            <div className='login-sub-text-3'>
              <div className='login-check'>
                <IonCheckbox mode='md' className='login-checkbox-style'></IonCheckbox>
                <IonLabel className='login-remember-style'>Remember Me</IonLabel>
              </div>
            </div>
            <div className='login-button'>
              <IonButton mode="md" expand='block' type='button' onClick={handleLogin}>
                Log In
              </IonButton>
            </div>
            <div className='login-forgot'>
              <IonRouterLink routerLink='/Forgot'>
                <IonText className='login__lost-pass-link ion-padding-start'>
                  Lost Your Password ?
                </IonText>
              </IonRouterLink>
            </div>
          </div>
          <div className='login-sub-text-4'>
            <IonText>Don't have an account?</IonText>
            <IonRouterLink routerLink='/Signup'>
              <IonText class="btn-tertiary" color="tertiary">Get Started</IonText>
            </IonRouterLink>
          </div>
        </>)
      }
      <LargeModal
        title={title}
        description={description}
        buttonText={buttonText}
        isOpen={showModal}
        closeAction={closeModal}
      ></LargeModal>
    </LoginWrapper>
  );
};

export default Login;
