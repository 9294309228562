import React, { useEffect, useState } from "react";
import { isPlatform, IonCol, IonGrid, IonRow, IonButton, useIonAlert } from "@ionic/react"
import "./Messages.css";
import trashSmall from '../../assets/menu-icons/trash-small.svg'
import { Http } from "@capacitor-community/http";
import Modal from '../../components/Modal/Modal';
import { get } from "./../../storage/storage";
const MessageDetail = (data: any) => {

    const [isDesktop, setDesktop] = useState(false);
    const [isTablet, setTablet] = useState(false);

    const [successModal, setSuccessModal] = useState(false);
    const [buttonText, setButtonText] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [present] = useIonAlert();
    useEffect(() => {

        if (isPlatform("desktop")) {
            setDesktop(true);
        }
        if (isPlatform("tablet")) {
            setTablet(true)
        }
    }, []);

    const confirmation = (msgId: any) => {
        present({
            header: 'Confirm ',
            message: 'Are you sure you want to delete this item?',
            buttons: [
                'Cancel',
                { text: 'Yes', handler: () => deleteMessage(msgId) },
            ],
            onDidDismiss: (e) => { },
        });
    }

    const deleteMessage = async (id: any) => {
        const url = `${process.env.REACT_APP_HP_API_URL}/index.php/messages/delete-message?id=${id}`;
        const options = {
            url: url,
            headers: {
                authorization: `Bearer ${await get('token')}`
            },
        };
        const response = await Http.post(options);

        data.reloadApi();
        if (response.data.success) {
            setSuccessModal(true);
            setTitle('DONE!');
            setDescription(response.data.message);
            setButtonText('Continue');
        } else {
            setSuccessModal(true);
            setTitle('Oops!');
            setDescription('Something Went Wrong!');
            setButtonText('Try Again');
        }


    }
    const hidesuccessModal = async () => {
        await setSuccessModal(false);
    }
    return (
        <IonGrid className="message-details">
            <IonRow>
                <IonCol size="12" size-md="10">
                    <IonRow>
                        <IonCol size="2" className="label">Subject</IonCol>
                        <IonCol size="10" >{data.data.hm_subject}</IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="2" className="label">Date</IonCol>
                        <IonCol size="10">{data.data.messageDateFormatted}</IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="2" className="label">Body</IonCol>
                        <IonCol size="10">  <div dangerouslySetInnerHTML={{ __html: data.data.hm_body }} /></IonCol>
                    </IonRow>
                    {(data.data.attachments.length)?
                        <IonRow>
                            <IonCol size="2" className="label">Attachment(s)</IonCol>
                            <IonCol size="10">
                                {data.data.attachments.map((file: any) =>
                                    <span><a target="_blank" href={`${process.env.REACT_APP_HP_API_URL}/index.php/messages/download-attachment?attachmentId=${file.attachmentId}`} className="message-action">{file.title}</a> <br /></span>
                                    )}
                            </IonCol>
                        </IonRow>:''
                    }
                </IonCol>
                <IonCol size="12" size-md="2">
                    <div>
                        {/* {(data.data.hm_allow_reply == 1) ? <a className="message-action">Reply</a> : ''} <br /> */}
                        <a className="message-action" onClick={() => confirmation(data.data.hm_id)}>Delete Message</a>
                    </div>
                </IonCol>
            </IonRow>
            
            <Modal
                logo={trashSmall}
                title={title}
                description={description}
                buttonText={buttonText}
                isOpen={successModal}
                closeAction={hidesuccessModal}
            ></Modal>
        </IonGrid>
    )
}

export default MessageDetail;