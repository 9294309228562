import React from "react";
import "./PayNow.css";
import { IonIcon, IonCard, IonButton } from "@ionic/react";
import billingIcon from "../../assets/menu-icons/billingIcon.svg";
import { useEffect, useState } from "react";
import { Http } from "@capacitor-community/http";
import { useDispatch } from "react-redux";
import { authActions } from "./../../store/auth";
import { get } from "./../../storage/storage";
import { useSelector } from "react-redux";

interface payMyBillsState {
  payNowLink: string;
  amountDue: string;
  dueDate: string;
  asOfDate: string;
  viewStatementSample: string;
  viewStatementLink: string;
}

type PayNowProps = {
  isHomeWidget: string;
};

const PayNow: React.FC<PayNowProps> = (props) => {
  const { token, loginUserHoaId, loginUserPropertyId } = useSelector(
    (state: any) => state.auth
  );

  const [payMyBills, setPayMyBills] = useState<payMyBillsState>();
  const { isHomeWidget } = props;

  const [hoaId, setHoaId] = useState(loginUserHoaId);
  const [authToken, setAuthToken] = useState(token);
  const [propertyId, setPropertyId] = useState(loginUserPropertyId);


  const dispatch = useDispatch();

  const fetchPayMyBills = async () => {
    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/hoas/financials?propertyId=${propertyId}&hoa_id=${hoaId}`;
    const options = {
      url: url,
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    };
    const response = await Http.get(options);
    if (response.status != 200) {
      dispatch(authActions.logout());
      return;
    }

    setPayMyBills(response.data);
  };

  useEffect(() => {
    (async () => {
      if (await get('loginUserHoaId')) {

        setHoaId(await get('loginUserHoaId'));
      }
      if (await get('token')) {

        setAuthToken(await get('token'))
      }
      if (await get('loginUserPropertyId')) {

        setPropertyId(await get('loginUserPropertyId'))
      }

    })();

    setTimeout(async () => {
      await fetchPayMyBills();
    }, 1000);
  }, []);

  return (
    <div className={`${isHomeWidget === 'true' ? "" : "payNow-dedicated-page__container"}`}>
      {(payMyBills?.payNowLink || payMyBills?.amountDue) && (
        <div className="cardContainer">
          {isHomeWidget === 'true' && <div className="cardHeader">
            <div className="cardTitle">
              <IonIcon src={billingIcon}></IonIcon>
              <h6> Pay My Bill</h6>
            </div>
          </div>}
          <div className="cardBody">
            <div className="payMybillsInfo">

              {/* Amount Due */}
              {payMyBills?.amountDue && (
                <IonCard className="myBillDueAmount">
                  <div className="myBillDueAmountCard">
                    <p>Amount Due</p>
                    <p>{payMyBills.amountDue}</p>
                  </div>
                  <div></div>
                </IonCard>
              )}

              {/* Due Date */}
              {payMyBills?.dueDate && (
                <IonCard className="myBillDueDate">
                  <div className="myBillDueDateCard">
                    <p>Due Date</p>
                    <p>{payMyBills.dueDate}</p>
                  </div>
                </IonCard>
              )}

              {/* As Of Date - it's sent by API only if the due date is not available */}
              {payMyBills?.asOfDate && (
                <IonCard className="myBillDueDate">
                  <div className="myBillDueDateCard">
                    <p>As Of</p>
                    <p>{payMyBills.asOfDate}</p>
                  </div>
                </IonCard>
              )}
            </div>

            <div className="payMybills">
              {/* Show icon on the Dedicated page when we have only the payment link */}
              {isHomeWidget === 'false' && payMyBills?.payNowLink && !payMyBills?.amountDue && (<IonIcon className="payNow__icon--link" src="/images/common/dolar-blue.svg"></IonIcon>)}

              {/* Do not show the text if we have the amounts */}
              {!payMyBills?.amountDue && (
                <div className="payNow__link-desc">
                  View your balance or pay <br /> your dues online.
                </div>
              )}

              {/* Pay Now link */}
              {payMyBills?.payNowLink && (
                <IonButton mode="md" size="default" target="_blank" href={`${payMyBills.payNowLink}`} >Pay Now</IonButton>
              )}

              {/* View Statement Sample */}
              {payMyBills?.viewStatementSample && (
                <a href={`${process.env.REACT_APP_HP_API_URL}/index.php/hoas/download-resource?file=${payMyBills.viewStatementSample}`}>View Statement</a>
              )}

              {/* View Statement */}
              {payMyBills?.viewStatementLink && (
                <a href={`${process.env.REACT_APP_HP_API_URL}/index.php/hoas/download-statement?hoa_id=${hoaId}&url=${payMyBills.viewStatementLink}`}>View Statement</a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PayNow;
