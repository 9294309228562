export const customStyles = {
    headRow: {
      style: {
        background: "#606163",
        paddingTop: "12px",
        paddingBottom: "12px"
      },
    },
    headCells: {
      style: {
        color: "#ffffff",
        fontSize: "17px",
        fontWeight: "500",
        pointerEvents: "none"
      },
    },
  
    rows: {
      style: {
        backgroundColor: '#ffffff',
        borderBottomColor: '#ffffff',
        // outline: '1px solid #9d9fa6',
        paddingTop: "8px",
        paddingBottom: "8px",textDecoration:"none"
      },
    },
    cells:{
      style:{
        textDecoration: "none",
        color: "#7c7d7f",
        fontSize: "16px",
      }
  },
    highlightOnHoverStyle: {
      color: "#fffff",
    }
    
  };

  export var columnsDesktop = [
    {
      name: "FROM",
      cell: (row: any) => row.messageFromName,
      // sortable: true,
    },
    {
      name: "SUBJECT",
      selector: (row: any) => row.hm_subject,
      // sortable: true,
    },
    {
        name: "DATE",
        selector: (row: any) => row.messageDateFormatted,
        // sortable: true,
      }
  ];

  export var columnsMobile = [
    {
      name: "FROM",
      cell: (row: any) => row.messageFromName,
      // sortable: true,
    },
  ];

 

  