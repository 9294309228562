/** */
import React, { useState, useEffect } from "react";
import {
    IonContent,
    IonPage,
    isPlatform,
    IonIcon,
    IonItem,
} from "@ionic/react";
import { Http } from "@capacitor-community/http";
import DataTable, { createTheme, defaultThemes } from "react-data-table-component";
import { searchIcon, refreshIcon } from '../../Constants/general'
import './Messages.css';
import Header from '../../components/Header/Header';
import { customStyles, columnsDesktop,columnsMobile } from './Constants'
import { useDispatch } from 'react-redux'
import { menuActions } from '../../store/menuShowHide'
import { authActions } from './../../store/auth'
import MessageDetail from "./MessageDetail";
import messageIcon from "../../assets/menu-icons/message-icon.svg";
import { get } from "./../../storage/storage";

type MessagesProps = {
    isHomeWidget: string;
};

const Messages: React.FC<MessagesProps> = (props) => {
    const dispatch = useDispatch();
    const updaeState = () => {
        dispatch(menuActions.toggleMenu({ showMainMenu: false }));
    }

    const { isHomeWidget } = props;  
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterBy, setFilterBy] = useState('');
    const [filterText, setFilterText] = useState('');
    const [filterError, setFilterError] = useState(false);
    const [isDesktop, setDesktop] = useState(false);
    const [isTablet, setTablet] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [isIos,setIsIos] = useState(false);
    const [messageDeleted, setMessageDeleted] = useState(false);

    
    const fetchMessages = async (page: any) => {
        setLoading(true);

        let searchText = (filterText) ? `&HoaMessageSearch[${filterBy}]=${filterText}` : '';
        const url = `${process.env.REACT_APP_HP_API_URL}/index.php/messages/index?page=${page}${searchText}`;
        const options = {
            url: encodeURI(url),
            headers: {
                authorization: `Bearer ${await get('token')}`
            },
        };
        const response = await Http.get(options);
        if (response.status != 200) {
            dispatch(authActions.logout());
            return;
        }
        setData(response.data.items);
        setTotalRows(response.data._meta ? response.data._meta.totalCount : 0);
        setLoading(false);
    };
    const handlePageChange = (page: any) => {
        fetchMessages(page);
    };
    useEffect(() => {
        updaeState()
        fetchMessages(1);
        if (isPlatform("desktop")) {
            setDesktop(true);
        }
        if (isPlatform("tablet")) {
            setTablet(true)
        }
        if (isPlatform("ios")) {
            setIsIos(true);
          }
        setFilterBy('')
        setFilterText('')
    }, [dispatch, refresh, messageDeleted]);

    const searchMessages = (e: any) => {
        e.preventDefault();
        if (!filterBy) {
            setFilterError(true);
            return
        }
        setFilterError(false);
        fetchMessages(1);
    }
const checkIsMessageDelete = ()=>{
    setMessageDeleted(!messageDeleted);
}
    return (
         <div>
            {isHomeWidget == "false" &&
            <div>
                <IonPage >
                    <Header title='Messages' />       
                    <IonContent >
                        <div className="filter-container" >
                            <h3  className="page-tile" >
                                Messages
                            </h3>
                            <form onSubmit={searchMessages}  className="filtet-form" >
                                <div className="filter-wrapper">
                                <img className="refresh-page" src={refreshIcon} onClick={() => setRefresh(!refresh)} />
                                <div className="filter-type-wrapper">
                                    <select className="filter-type" name="filter_by" value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
                                        <option value="">&nbsp;   Filter By</option>
                                        <option value="fromFullName"> &nbsp; From</option>
                                        <option value="hm_subject"> &nbsp; Subject</option>
                                    </select>
                                    </div>
                                    <div className={(isIos)?'filter-input-wrapper filter-input-wrapper-ios' :'filter-input-wrapper filter-input-wrapper-android'}>
                                        <button type="submit"> <img src={searchIcon} /> </button>
                                        <input type="text" className="filter-input" value={filterText} name="filter_text" onChange={(e) => setFilterText(e.target.value)} placeholder="&nbsp; Search" />
                                    </div>
                                </div>
                                {filterError && <div className="filter-error-msg">Please select column to filter by</div>}
                            </form>
                        </div>
                        <br />
                        <div className="filterBottomLine"></div>
                        <div className="messages-table">
                            <DataTable
                                columns={(isDesktop || isTablet) ? columnsDesktop : columnsMobile}
                                data={data}
                                progressPending={loading}
                                expandableRowsComponent={<MessageDetail reloadApi={checkIsMessageDelete}/>}
                                expandOnRowClicked={true}
                                expandableRows={true}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangePage={handlePageChange}
                                customStyles={customStyles}
                                striped={true}
                                highlightOnHover={true}
                            />
                        </div>
                </IonContent >
            </IonPage >
        </div>
        }
        {isHomeWidget == "true" &&
            <div>
                <div className="cardTitle">
                    <IonIcon src={messageIcon}></IonIcon>
                    <h6> Messages</h6>
                </div>
                <div className="home-widget messages-table">
                    <DataTable
                        columns={(isDesktop || isTablet) ? columnsDesktop : columnsMobile}
                        data={data}
                        progressPending={loading}
                        expandableRowsComponent={<MessageDetail reloadApi={checkIsMessageDelete}/>}
                        expandOnRowClicked={true}
                        expandableRows={true}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        customStyles={customStyles}
                        striped={true}
                        highlightOnHover={true}
                    />
                </div>
            </div>
        }
</div>
 );
}


export default Messages;