import { isPlatform } from "@ionic/react";
import refreshIcon from '../assets/menu-icons/refresh-icon.svg'
import searchIcon from '../assets/menu-icons/searchIcon.svg'
var isDesktop = false;
var isTablet = false;
var isIOS = false;
if (isPlatform("desktop")) {
  isDesktop = true;
}
if (isPlatform("tablet")) {
  isTablet = true;
}
if (isPlatform("ios")) {
  isIOS = true
}

const convetDateToString = (stringDate: any) => {
  let date = new Date(stringDate);
  return `${date.toLocaleDateString('default', { weekday: 'long' })}, ${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;
}
const timeConvert = (time: any) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  if (time.length > 1) { // If time format correct
    time = time.slice(1);  // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

const realoadPage = () => {
  window.location.reload();
}
// var isDesktopTablet = false
export {
  // isDesktopTablet,
  isDesktop,
  isTablet,
  isIOS,
  refreshIcon,
  searchIcon,
  convetDateToString,
  timeConvert,
  realoadPage
};