/** */
import React, {  useState, } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'

import {
  IonIcon,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonList,
  IonItem,
  IonButton,
  isPlatform,
  IonPopover,
} from "@ionic/react";
import "./Header.css";

import notiicationIcon from "../../assets/menu-icons/notiication-icon.svg";
import profileIcon from "../../assets/menu-icons/profile-icon.svg";
import { authActions } from '../../store/auth'
const Header: React.FC<{ title: string; }> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });


  const sendToMessages = () => {
    history.push('/Messages');
  };

  const showPopoverHandler = (e: any) => {
    e.persist();
    setShowPopover({ showPopover: true, event: e });
  };


  const logoutHandler = () => {
    dispatch(authActions.logout());
  }
 
  return (
    <IonHeader>
      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        <IonList>
          {!isPlatform("desktop") &&
          <IonItem detail={false} button routerLink="/PaymentPortal" key="PaymentPortal">Pay Now</IonItem>}
          <IonItem detail={false} button routerLink="/MyAccount" key="MyAccount">My Account</IonItem>
          <IonItem routerDirection="none" detail={false} button routerLink="/Messages" key="Messages">Messages</IonItem>
          {/* <IonItem detail={false} button routerLink="/Household" key="Household">Household</IonItem> */}
          <IonItem detail={false} button routerLink="/Settings" key="Settings">Settings</IonItem>
          <IonItem detail={false} button onClick={logoutHandler} key="Logout">Logout</IonItem>
        </IonList>
      </IonPopover>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton></IonMenuButton>
        </IonButtons>
        <IonTitle slot="start" className="title-ios toolbarTitle">
          {props.title}
        </IonTitle>
          <IonButtons slot="end">

            <IonButton onClick={sendToMessages}>
           
              <IonIcon slot="icon-only" src={notiicationIcon} className="notificationIcon">
              </IonIcon>
              {/* <IonBadge  className="notificatioBadge">5</IonBadge> */}
            </IonButton>

            <IonButton onClick={showPopoverHandler} >
              <IonIcon className="HeaderSideMenuIcon" slot="icon-only" src={profileIcon}></IonIcon>
            </IonButton>

          </IonButtons>
        
       
      </IonToolbar>
    </IonHeader>

  );
};

export default Header;
