export const customStyles = {
    headRow: {
        style: {
            background: "#606163",
            paddingTop: "15px",
            paddingBottom: "15px"
        },
    },
    headCells: {
        style: {
            color: "#ffffff",
            fontSize: "17px",
            fontWeight: "100",
            pointerEvents: "none"
        },
    },

    rows: {
        style: {
            backgroundColor: '#ffffff',
            borderBottomColor: '#ffffff',
            // outline: '1px solid #9d9fa6',
            paddingTop: "8px",
            paddingBottom: "8px"
        },
    },
    cells: {
        style: {
            color: "#3a3a3a",
            fontSize: "16px",
            // textDecoration: 'underline'
        }
    },
    highlightOnHoverStyle: {
        color: "#fffff",
    },
};

export var columnsDesktop = [
    {
        name: "TITLE",
        cell: (row: any) => row.hd_title,
        // sortable: true,
    },
    {
        name: "LAST UPDATED",
        selector: (row: any) => {
            let lastDate = new Date(row.hd_created_time * 1000);// convert timestamp to date object
            return `${lastDate.toLocaleString('default', { month: 'long' })} ${lastDate.getDate()} ${lastDate.getFullYear()}`
        },
        // sortable: true,
    }
];
export var columnsMobile = [
    {
        name: "TITLE",
        cell: (row: any) => row.hd_title,
        // sortable: true,
    },
];

