/** */
import {
  IonContent, IonHeader, IonIcon, IonItem,
  IonLabel, IonList, IonMenu, IonMenuToggle, IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import billingIcon from "../../assets/menu-icons/billingIcon.svg";
import calendarIcon from "../../assets/menu-icons/calender-icon.svg";
import boardOfDirectorIcon from "../../assets/menu-icons/directors-icon.svg";
import directoryIcon from "../../assets/menu-icons/directory-icon.svg";
import documentIcon from "../../assets/menu-icons/document-icon.svg";
import faqIcon from "../../assets/menu-icons/faqs-icon.svg";
import formsIcon from "../../assets/menu-icons/forms-icon.svg";
import forumsIcon from "../../assets/menu-icons/forums-icon.svg";
import homeIcon from "../../assets/menu-icons/home-icon.svg";
import lock from "../../assets/menu-icons/lock.svg";
import { default as myAccountIcon, default as newsIcon } from "../../assets/menu-icons/news-icon.svg";
import unlockIcon from '../../assets/menu-icons/unlock-icon.svg';
import logo from "../../assets/white-logo.svg";
import LargeModal from '../../components/Modal/LargeModal';
import { menuActions } from '../../store/menuShowHide';
import { Http } from "@capacitor-community/http";
import { authActions } from "./../../store/auth";
import "./Menu.css";
import { get } from "./../../storage/storage";


const Menu: React.FC = () => {
  const {loginUserHoaName,loginUserLeftMenu, token, loginUserHoaId, loginUserId} = useSelector(
    (state: any) => state.auth
  );
  const [forSales, setForSales] = useState(false);
  const [groups, setGroups] = useState(false);
  const [hoaName,setHoaName] = useState(loginUserHoaName);
  const [menuData,setMenuData] = useState(loginUserLeftMenu);
  const dispatch = useDispatch();

  const menuToggle = useSelector(
    (state: any) => state.menu.showMainMenu
  );
  const updaeStateMenu = (bolValue:any)=>{
    dispatch(menuActions.toggleMenu({ showMainMenu: bolValue }));
  }

// menu states 
const leftMenu = localStorage.getItem('loginUserLeftMenu');
const parsLeftMenu = (leftMenu)? JSON.parse(leftMenu):null;

const [calendar,setCalendar] = useState("enabled");
const [directory,setdirectory] = useState("enabled");
const [documents,setDocuments] = useState("enabled");
const [faqs,setFaqs] = useState("enabled");
const [forms,setForms] = useState("enabled");
const [news,setNews] = useState("enabled");
const [usefulLinks,setUsefulLinks] = useState("enabled");
const [boardofDirectors,setBoardofDirectors] = useState("enabled");
const [forSale,setForSale] = useState("enabled");
const [forums,setForums] = useState("enabled");
const [paymentPortalDisabled,setMenuPaymentPortal] = useState("enabled");
const [membersDisabled,setMenuMembers] = useState("enabled");
const [menuGroups,setMenuGroups] = useState("enabled");

//unlock modal info
const [successModal, setSuccessModal] = useState(false);
const [buttonText, setButtonText] = useState('I want the Community Plan!');
const [title, setTitle] = useState('Unlock more features with the Hoampage Community Plan!');
const [description, setDescription] = useState('Cozy Hills HOA is currently using the Hoampage Household Plan. Want access to more interactive and informative features? Let us know you’re interested, and we will pass it on!');

// enabled disabled menu base on logins
const lockMenuHandler = async()=>{
  
  const leftMenu = ( await get('loginUserLeftMenu') )? await get('loginUserLeftMenu') :menuData
  const parsLeftMenu = (leftMenu)? JSON.parse(leftMenu):null;
  for (let key in parsLeftMenu) {
    if (Object.prototype.hasOwnProperty.call(parsLeftMenu, key)) {
      const menuItem = parsLeftMenu[key];
      if (menuItem.feature=='Calendar') {
        if(!menuItem.accessible) {
          setCalendar("lock");
        } else if(!menuItem.enabled) {
          setCalendar("hide")
        }
      }
      if (menuItem.feature=='Directory') {
        if(!menuItem.accessible) {
          setdirectory("lock");
        } else if(!menuItem.enabled) {
          setdirectory("hide")
        }
      }
      if (menuItem.feature=='Documents') {
        if(!menuItem.accessible) {
          setDocuments("lock");
        } else if(!menuItem.enabled) {
          setDocuments("hide")
        }
      }
      if (menuItem.feature=='FAQs') {
        if(!menuItem.accessible) {
          setFaqs("lock");
        } else if(!menuItem.enabled) {
          setFaqs("hide")
        }
      }
      if (menuItem.feature=='Forms') {
        if(!menuItem.accessible) {
          setForms("lock");
        } else if(!menuItem.enabled) {
          setForms("hide")
        }
      }
      if (menuItem.feature=='News') {
        if(!menuItem.accessible) {
          setNews("lock");
        } else if(!menuItem.enabled) {
          setNews("hide")
        }
      }
      if (menuItem.feature=='Useful Links') {
        if(!menuItem.accessible) {
          setUsefulLinks("lock");
        } else if(!menuItem.enabled) {
          setUsefulLinks("hide")
        }
      }
      if (menuItem.feature=='Board of Directors') {
        if(!menuItem.accessible) {
          setBoardofDirectors("lock");
        } else if(!menuItem.enabled) {
          setBoardofDirectors("hide")
        }
      }
      if (menuItem.feature=='For Sale') {
        if(!menuItem.accessible) {
          setForSale("lock");
        } else if(!menuItem.enabled) {
          setForSale("hide")
        }
      }
      if (menuItem.feature=='Forums') {
        if(!menuItem.accessible) {
          setForums("lock");
        } else if(!menuItem.enabled) {
          setForums("hide")
        }
      }
      if (menuItem.feature=='Groups') {
        if(!menuItem.accessible) {
          setMenuGroups("lock");
        } else if(!menuItem.enabled) {
          setMenuGroups("hide")
        }
      }
      if (menuItem.feature=='Payment Portal') {
        if(!menuItem.accessible) {
          setMenuPaymentPortal("lock");
        } else if(!menuItem.enabled) {
          setMenuPaymentPortal("hide")
        }
      }
      if (menuItem.feature=='Additional Members') {
        if(!menuItem.accessible) {
          setMenuMembers("lock");
        } else if(!menuItem.enabled) {
          setMenuMembers("hide")
        }
      }
    }
  }
}

const hidesuccessModal = async (sendMail: boolean) => {
  if(sendMail) {
    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/hoas/send-request-community-plan?hoa_id=${loginUserHoaId}&user_id=${loginUserId}`;
    const options = {
      url: url,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const response = await Http.get(options);
    if (response.status == 401) {
      dispatch(authActions.logout());
    }
  }

  await setSuccessModal(false);
}

const showSuccessModal = async () => {
  await setSuccessModal(true);
}

const menuItemClicked = (item: string) => {
  if(item == "lock") {
    showSuccessModal();
  }
}

useEffect(()=>{
  (async ()=>{
      if (await get('loginUserHoaName')) {
        setHoaName(await get('loginUserHoaName'));
      }
  })();

  setTimeout(function() { //Start the timer
    lockMenuHandler();
  }, 1000)
    
},[dispatch]);

const reset = () => {
      setForSales(false);
      setGroups(false)
  }

const featureToggle = (feature: any) => {
  if (feature == 'groups') {
    setGroups(!groups);
    setForSales(false);
  }
  if (feature == 'forSales') {
    setForSales(!forSales);
    setGroups(false)
  }
}

return (
    <>
      <IonMenu contentId="main" className="main-menu">
        <IonHeader >
          <IonToolbar>
            <img className="hoa__logo"  width="55" height="55" slot="start" src={logo} alt="Hoampage"></img>
            <IonTitle className="menu__hoa-name" slot="start">{hoaName}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="mainMenuContainer">
          {menuToggle && (
            <IonList >
              <IonMenuToggle key="MyDashboard" auto-hide="false" >
                <IonItem
                  routerDirection="none"
                  button
                  routerLink="/Home"
                  detail={false}>
                  <IonIcon slot="start" src={homeIcon} color="warning"></IonIcon>
                  <IonLabel mode="md">Home</IonLabel>
                </IonItem>
              </IonMenuToggle>

              {boardofDirectors != "hide" &&
                <IonMenuToggle key="BoardofDirectors" auto-hide="false">
                  <IonItem
                    button
                    routerDirection="none"
                    routerLink={boardofDirectors == "lock" ? undefined : "/BoardofDirectors"}
                    detail={false}
                    onClick={() => menuItemClicked(boardofDirectors)}>
                    <IonIcon
                      slot="start"
                      src={boardOfDirectorIcon}
                    ></IonIcon>
                    <IonLabel mode="md">Board of Directors {boardofDirectors == "lock" && <IonIcon className="menu-lock-icon" src={lock}> </IonIcon>}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              }

              {calendar != "hide" &&
                <IonMenuToggle key="Calendar" auto-hide="false">
                  <IonItem
                    button
                    routerDirection="none"
                    routerLink={calendar == "lock" ? undefined : "/Calendar"}
                    detail={false}
                    onClick={() => menuItemClicked(calendar)}>
                    <IonIcon
                      slot="start"
                      src={calendarIcon}
                    ></IonIcon>
                    <IonLabel  mode="md">Calendar {calendar == "lock" && <IonIcon className="menu-lock-icon" src={lock}> </IonIcon>}</IonLabel>
                    
                  </IonItem>
                </IonMenuToggle>
              }

              {directory != "hide" &&
                <IonMenuToggle key="Directory" auto-hide="false">
                  <IonItem 
                  routerDirection="none" 
                  button 
                  routerLink={directory == "lock" ? undefined :"/Directory" }
                  detail={false}
                  onClick={() => menuItemClicked(directory)}>
                    <IonIcon
                      slot="start"
                      src={directoryIcon}
                    ></IonIcon>
                    <IonLabel mode="md">Directory {directory == "lock" && <IonIcon className="menu-lock-icon" src={lock}> </IonIcon>}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              }

              {documents != "hide" &&
                <IonMenuToggle key="Documents" auto-hide="false">
                  <IonItem  routerDirection="none" button 
                    routerLink={documents == "lock" ? undefined :"/Documents" }
                    detail={false}
                    onClick={() => menuItemClicked(documents)}
                  >
                    <IonIcon
                      slot="start"
                      src={documentIcon}
                    ></IonIcon>
                    <IonLabel mode="md">Documents {documents == "lock" && <IonIcon className="menu-lock-icon" src={lock}> </IonIcon>}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              }

              {faqs != "hide" &&
                <IonMenuToggle key="FAQ" auto-hide="false">
                  <IonItem 
                    routerDirection="none" button 
                    routerLink={faqs == "lock" ? undefined :"/FAQ" }
                    detail={false}
                    onClick={() => menuItemClicked(faqs)}
                  >
                    <IonIcon
                      slot="start"
                      src={faqIcon}
                    ></IonIcon>
                    <IonLabel mode="md">FAQs {faqs == "lock" && <IonIcon className="menu-lock-icon" src={lock}> </IonIcon>}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              }

              {forms != "hide" &&
                <IonMenuToggle key="Forms" auto-hide="false">
                  <IonItem 
                    routerDirection="none" button 
                    routerLink={forms == "lock" ? undefined : "/Forms"}
                    onClick={() => menuItemClicked(forms)} 
                    detail={false}
                  >
                    <IonIcon
                      slot="start"
                      src={formsIcon}
                    ></IonIcon>
                    <IonLabel mode="md">Forms {forms == "lock" && <IonIcon className="menu-lock-icon" src={lock}> </IonIcon>}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              }

              {news != "hide" &&
                <IonMenuToggle key="News" auto-hide="false">
                  <IonItem 
                    routerDirection="none" 
                    button 
                    routerLink={news == "lock" ? undefined : "/News" }
                    detail={false}
                    onClick={() => menuItemClicked(news)}
                  >
                    <IonIcon
                      slot="start"
                      src={newsIcon}
                      color="dark"
                    ></IonIcon>
                    <IonLabel mode="md">News {news == "lock" && <IonIcon className="menu-lock-icon" src={lock}> </IonIcon>}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              }
              
              {/* <IonMenuToggle key="UsefulLinks" auto-hide="false">
                <IonItem disabled={usefulLinks} routerDirection="none" button routerLink="/UsefulLinks" detail={false}>
                  <IonIcon
                    slot="start"
                    src={usefullLinkIon}
                  ></IonIcon>
                  <IonLabel mode="md">Useful Links {usefulLinks && <IonIcon className="menu-lock-icon" src={lock}> </IonIcon>}</IonLabel>
                </IonItem>
              </IonMenuToggle> */}

              {/* <IonMenuToggle key="Forums" auto-hide="false">
                <IonItem
                  disabled={forums}
                  button
                  routerDirection="none"
                  routerLink="/Forums"
                  detail={false}>
                  <IonIcon
                    slot="start"
                    src={forumsIcon}
                    color="danger"
                  ></IonIcon>
                  <IonLabel mode="md">Forums {forms && <IonIcon className="menu-lock-icon" src={lock}> </IonIcon>}</IonLabel>
                </IonItem>
              </IonMenuToggle> */}

              {/* <IonItem
                disabled={forSale}
                key="forSales"
                button
                onClick={() => featureToggle('forSales')}
                detail={true}>
                <IonIcon slot="start" src={salesIcon} color="tertiary"></IonIcon>
                <IonLabel mode="md"> For Sales {forSale && <IonIcon className="menu-lock-icon" src={lock}> </IonIcon>}</IonLabel>
              </IonItem> */}
              {/* {forSales && (
                <IonList className="marginStart" key="MyListings">
                  <IonMenuToggle key="Forums" auto-hide="false">
                    <IonItem
                      button
                      routerDirection="none"
                      routerLink="/ForSales/MyListings"
                      detail={false}>
                      <IonLabel mode="md">- My Listings</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key="AllListing" auto-hide="false">
                    <IonItem
                      button
                      routerDirection="none"
                      routerLink="/ForSales/AllListings"
                      detail={false}>
                      <IonLabel mode="md">- All Listing</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                </IonList>
              )} */}
              
              {/* <IonItem
              disabled={menuGroups}
                key="groups"
                button
                onClick={() => featureToggle('groups')}
                detail={true}>
                <IonIcon slot="start" src={groupIcon} color="tertiary"></IonIcon>
                <IonLabel mode="md"> Groups {menuGroups && <IonIcon className="menu-lock-icon" src={lock}> </IonIcon>}</IonLabel>
              </IonItem>
              {groups && (
                <IonList className="marginStart" key="MyListings">
                  <IonMenuToggle key="MyGroups" auto-hide="false">
                    <IonItem
                      button
                      routerDirection="none"
                      routerLink="/Groups/MyGroups"
                      detail={false}>
                      <IonLabel mode="md">- My Groups</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key="ExploreGroups" auto-hide="false">
                    <IonItem
                      button
                      routerDirection="none"
                      routerLink="/Groups/ExploreGroups"
                      detail={false}>
                      <IonLabel mode="md">- Explore Groups</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                </IonList>
              )} */}
              
            </IonList>
          )}

          {!menuToggle && (
            <IonList >
              <IonMenuToggle key="home" auto-hide="false">
                <IonItem
                  routerDirection="none"
                  button
                  routerLink="/Home"
                  detail={false}
                  onClick={()=>updaeStateMenu(true)}
                  >
                  <IonIcon slot="start" src={homeIcon} color="warning"></IonIcon>
                  <IonLabel mode="md">Home</IonLabel>
                </IonItem>
              </IonMenuToggle>

              <IonMenuToggle key="myAccount" auto-hide="false">
                <IonItem routerDirection="none" button routerLink="/MyAccount" detail={false}>
                  <IonIcon
                    slot="start"
                    src={myAccountIcon}
                    color="dark"
                  ></IonIcon>
                  <IonLabel mode="md">My Account</IonLabel>
                </IonItem>
              </IonMenuToggle>

              <IonMenuToggle key="messages" auto-hide="false">
                <IonItem
                  button
                  routerDirection="none"
                  routerLink="/Messages"
                  detail={false}>
                  <IonIcon
                    slot="start"
                    src={calendarIcon}
                  ></IonIcon>
                  <IonLabel mode="md">Messages</IonLabel>
                </IonItem>
              </IonMenuToggle>

              <IonMenuToggle key="settings" auto-hide="false">
                <IonItem
                  button
                  routerDirection="none"
                  routerLink="/Settings"
                  detail={false}>
                  <IonIcon
                    slot="start"
                    src={forumsIcon}
                    color="danger"
                  ></IonIcon>
                  <IonLabel mode="md">Settings</IonLabel>
                </IonItem>
              </IonMenuToggle>

              {membersDisabled != "hide" &&
                <IonMenuToggle key="members" auto-hide="false">
                  <IonItem 
                    routerDirection="none" 
                    button 
                    routerLink={membersDisabled == "lock" ? undefined : "/Members" }
                    detail={false}
                    onClick={() => menuItemClicked(membersDisabled)}
                  >
                    <IonIcon
                      slot="start"
                      src={directoryIcon}
                    ></IonIcon>
                    <IonLabel mode="md">Members {membersDisabled == "lock" && <IonIcon className="menu-lock-icon" src={lock}> </IonIcon>}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              }

              {paymentPortalDisabled != "hide" &&
                <IonMenuToggle key="paymentPortal" auto-hide="false">
                  <IonItem
                    disabled={paymentPortalDisabled == "lock"}
                    button
                    routerDirection="none"
                    routerLink="/PaymentPortal"
                    detail={false}
                  >
                    <IonIcon
                      slot="start"
                      src={billingIcon}
                    ></IonIcon>
                    <IonLabel mode="md">Payment Portal {paymentPortalDisabled == "lock" && <IonIcon className="menu-lock-icon" src={lock}> </IonIcon>}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              }
            </IonList>
          )}
        </IonContent>
      </IonMenu>

      <LargeModal
            logo={unlockIcon}
            title={title}
            description={description}
            buttonText={buttonText}
            isOpen={successModal}
            closeAction={hidesuccessModal}
            className="lock-modal"
            secondaryButton="Learn More about Hoampage Community"
            secondaryButtonUrl="https://pageperpage.com/wp-content/uploads/2021/11/PRS-Tutorial.pdf"
            showCloseButton={true}
      ></LargeModal>
    </>
  );

}
export default Menu;