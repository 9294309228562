
import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSearchbar,
  IonIcon,
  IonItem,
  isPlatform
} from "@ionic/react";
import { Http } from "@capacitor-community/http";
import './FAQ.css';
import Header from '../../components/Header/Header';
import Accordion from "../../components/FaqAccordion/Accordion";
import { searchIcon, refreshIcon } from '../../Constants/general'
import { authActions } from './../../store/auth'
import { useDispatch } from "react-redux";
import { get } from "./../../storage/storage";
interface apiDataState {
  hf_id: string,
  hf_hoa_id: string,
  hf_title: string,
  hf_body: string,
  hf_created_time: string,
  hf_updated_time: string,
  hf_active: string
}
const FAQ: React.FC = () => {

  const [filterBy, setFilterBy] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterError, setFilterError] = useState(false);
  const [isDesktop, setDesktop] = useState(false);
  const [isTablet, setTablet] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isIos,setIsIos] = useState(false);
  var [faqApiData, setFaqApiData] = useState<apiDataState[]>([]);
  const dispatch = useDispatch();
  
  const fetchFaqs = async () => {
    let searchText = (filterText) ? `&HoaFaqSearch[${filterBy}]=${filterText}` : '';
    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/faqs?hoa_id=${await get('loginUserHoaId')}${searchText}`;
    const options = {
      url: encodeURI(url),
      headers: {
        authorization: `Bearer ${await get('token')}`
      },
    };
    const response = await Http.get(options);
    if (response.status != 200) {
      dispatch(authActions.logout());
      return;
    }
    setFaqApiData(response.data.items);
  };

  const seacrFaqs = (e: any) => {
    e.preventDefault();
    if (!filterBy) {
      setFilterError(true);
      return
    }
    setFilterError(false);
    fetchFaqs();
  }

  useEffect(() => {
    fetchFaqs();
    if (isPlatform("desktop")) {
      setDesktop(true);
    }
    if (isPlatform("tablet")) {
      setTablet(true)
    }
    if (isPlatform("ios")) {
      setIsIos(true);
    }
    setFilterBy('')
    setFilterText('')
  }, [refresh]);

  return (
    <IonPage>
      <Header title="FAQs" />
      <IonContent>
      <div className="filter-container" >
        <h3 className="page-tile" >
            FAQs
          </h3>

          <form onSubmit={seacrFaqs}  className="filtet-form" >
            <div className="filter-wrapper">
            <img className="refresh-page" src={refreshIcon} onClick={() => setRefresh(!refresh)} />
            <div className="filter-type-wrapper">
              <select className="filter-type" name="filter_by" value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
                <option value="">&nbsp;   Filter By</option>
                <option value="hf_title">&nbsp; Question</option>
                <option value="hf_body">&nbsp; Answer</option>

              </select>
            </div>
            <div className={(isIos)?'filter-input-wrapper filter-input-wrapper-ios' :'filter-input-wrapper filter-input-wrapper-android'}>
                <button type="submit"> <img src={searchIcon} /> </button>
                <input type="text" className="filter-input" value={filterText} name="filter_text" onChange={(e) => setFilterText(e.target.value)} placeholder="&nbsp; Search" />
              </div>
            </div>
            {filterError && <div className="filter-error-msg">Please select column to filter by</div>}
          </form>
        </div>
        <br />
        <div className="filterBottomLine"></div>

        <div className="faqContainer">
          {faqApiData.map((faq: any) =>
            <Accordion
              key={faq.hf_id}
              title={faq.hf_title}
              content={faq.hf_body}
            />
          )}

        </div>
      </IonContent>
    </IonPage >
  );
}

export default FAQ;