/** */
import React, { useEffect, useState } from 'react';
import { isPlatform, IonPopover, IonList, IonContent, IonHeader, IonPage, IonToolbar, IonItem, IonButton, IonRow, IonCol, IonLabel, IonInput, IonTextarea, IonText } from '@ionic/react';
import logo from '../../assets/landing-page/blue-logo-small.png'
import lock from '../../assets/landing-page/lock.svg'
import copyright from '../../assets/landing-page/copyright.png'
import newspager from '../../assets/landing-page/newspaper.svg'
import comment from '../../assets/landing-page/comment.svg'
import home from '../../assets/landing-page/home.svg'
import messages from '../../assets/landing-page/messages.svg'
import accountIcon from '../../assets/landing-page/blue-account-icon.svg'
import hoampageIcon from '../../assets/menu-icons/hoampage-icon.png'

import './LandingPage.css'
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Http } from '@capacitor-community/http';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { authActions } from '../../store/auth'
import { Link, useHistory, useLocation } from "react-router-dom";
import { get, set } from '../../storage/storage';
import { markAsUntransferable } from 'worker_threads';
import Modal from '../../components/Modal/Modal';

interface initWebState {

    hoa_id: number,
    hoa_name: string,
    hoa_logo: string,
    hoa_banner: string,
    header: string,
    description: string
    //featuresSection main section 
    myHouseholdUrl: string,
    myHouseholdLogo: string,
    myHouseholdText: string,
    myCommunityUrl: string,
    myCommunityLogo: string,
    myCommunityText: string,
    mySocialUrl: string,
    mySocialLogo: string,
    mySocialText: string,
    showHideContact: boolean
    // contact info "get in touch section"
    hcu_id: number,
    hcu_address: string,
    hcu_zipcode: string,
    hcu_phone_number: string,
    hcu_email_address: string,
    hcu_show_map: string,
    hcu_map_link: string,
    hcu_city: string,
    stateName: string
    officeHoursDisplay: string,
    fullAddress: string,
    // show hide contact section 
    showContactFormOnHOAWebsite:{ enabled:boolean, public:boolean}
}

const LandingPage: React.FC = () => {

    const isAuthenticated = useSelector(
        (state: any) => state.auth.isAuthenticated
    );
    const dispatch = useDispatch();
    const [popoverState, setShowPopover] = useState({
        showPopover: false,
        event: undefined,
    });
    let location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [websiteData, setwebsiteData] = useState<initWebState>();
    const history = useHistory();

    // Landing Page website details (url, hoa id)
    const lpWebsiteName = urlParams.get('community');
    const [lpWebsiteHoaId, setlpWebsiteHoaId] = useState('');

    const [buttonText, setButtonText] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [successModal, setSuccessModal] = useState(false);
    
    const websiteDataHandler = async () => {
        let url = `${process.env.REACT_APP_HP_API_URL}/index.php/hoas/view?website=${lpWebsiteName}`;
        const options = {
            url: url

        };
        const response = await Http.get(options);

        if (response === null || response.data === null || response.data.hoa_id === null) {
            history.replace(`/Login`);
            return
        }

        setlpWebsiteHoaId(response.data.hoa_id);

        //if enabledContact is true how contact option in menu
        let enabledContact = false;
        let enabledFeatures = response.data.enabledFeatures;
        for (const key in enabledFeatures) {
            let element = enabledFeatures[key];
            if (element.enabled) {
                enabledContact = true;
            }
        }

        setwebsiteData({
            hoa_id: response.data.hoa_id,
            hoa_name: response.data.hoa_name,
            hoa_logo: response.data?.hoa_logo,
            hoa_banner: response.data?.hoa_banner,
            header: response.data?.aboutSection?.header,
            description: response.data?.aboutSection?.description,
            //featuresSection
            myHouseholdUrl: response.data.featuresSection.myHouseholdUrl,
            myHouseholdLogo: response.data.featuresSection.myHouseholdLogo,
            myHouseholdText: response.data.featuresSection.myHouseholdText,
            myCommunityUrl: response.data.featuresSection.myCommunityUrl,
            myCommunityLogo: response.data.featuresSection.myCommunityLogo,
            myCommunityText: response.data.featuresSection.myCommunityText,
            mySocialUrl: response.data.featuresSection.mySocialUrl,
            mySocialLogo: response.data.featuresSection.mySocialLogo,
            mySocialText: response.data.featuresSection.mySocialText,
            showHideContact: enabledContact,
            // contact info "get in touch section"
            hcu_id: response.data?.contactInfo?.hcu_id,
            hcu_address: response.data?.contactInfo?.hcu_address,
            hcu_zipcode: response.data?.contactInfo?.hcu_zipcode,
            hcu_phone_number: response.data?.contactInfo?.hcu_phone_number,
            hcu_email_address: response.data?.contactInfo?.hcu_email_address,
            hcu_show_map: response.data?.contactInfo?.hcu_show_map,
            hcu_map_link: response.data?.contactInfo?.hcu_map_link,
            hcu_city: response.data?.contactInfo?.hcu_city,
            stateName: response.data?.contactInfo?.stateName,
            officeHoursDisplay: response.data?.contactInfo?.officeHoursDisplay,
            fullAddress: response.data?.contactInfo?.fullAddress,
            showContactFormOnHOAWebsite:response.data?.contactInfo?.showContactFormOnHOAWebsite
        });
    }

    const {
        handleSubmit,
        register,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            fullname: '',
            email: '',
            phone: '',
            message: ''
        },
        mode: 'onBlur',
    });

    const contactHOAonSubmit = async (data: any) => {

        let url = `${process.env.REACT_APP_HP_API_URL}/index.php/hoas/contact-community-manager?hoa_id=${lpWebsiteHoaId}`;
        const options = {
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: {
                'ContactCommunityManagerForm[fullName]': getValues('fullname'),
                'ContactCommunityManagerForm[email]': getValues('email'),
                'ContactCommunityManagerForm[phone]': getValues('phone'),
                'ContactCommunityManagerForm[message]': getValues('message'),
            },
        };
        const response = await Http.post(options);

        if (response.data.success) {
            setSuccessModal(true);
            setTitle('THANK YOU!');
            setDescription(response.data.messageContent);
            setButtonText('Continue');
        } else {
            setSuccessModal(true);
            setTitle('OOPS!');
            setDescription('There was an error and the message was not delivered');
            setButtonText('Try Again');
        }
    };

    const showPopoverHandler = (e: any) => {
        e.persist();
        setShowPopover({ showPopover: true, event: e });
    };

    const logoutHandler = () => {
        dispatch(authActions.logout());
    }

    const hidesuccessModal = async () => {
        await setSuccessModal(false);
        setValue('fullname', '')
        setValue('email', '')
        setValue('phone', '')
        setValue('message', '')
    }

    useEffect(() => {
        websiteDataHandler();
    }, []);

    return (
            <>
            {lpWebsiteHoaId && <IonPage>
            
            <IonPopover
                event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() =>
                    setShowPopover({ showPopover: false, event: undefined })
                }
            >
                <IonList>
                    {!isPlatform("desktop") &&
                        <IonItem detail={false} button href="/PaymentPortal" key="PaymentPortal">Pay Now</IonItem>}
                    <IonItem detail={false} button href="/MyAccount" key="MyAccount">My Account</IonItem>
                    <IonItem routerDirection="none" detail={false} button href="/Messages" key="Messages">Messages</IonItem>
                    <IonItem detail={false} button href="/Settings" key="Settings">Settings</IonItem>
                    <IonItem detail={false} button onClick={logoutHandler} key="Logout">Logout</IonItem>
                </IonList>
            </IonPopover>
            <IonContent>
                <IonHeader className="header-container">
                    <IonToolbar className="navbar">
                        <div className="navbar-header" slot="start">
                            {/* {websiteData?.hoa_logo &&
                                <a className="navbar__logo" >
                                    <img className="navbar__logo-img" src=`${process.env.REACT_APP_HP_API_URL}/index.php/hoas/view-image?image=${websiteData alt="Hoampage" />
                                </a>
                            } */}
                            {/*!websiteData?.hoa_logo && */
                                <a className="navbar__logo" href={`/Home?community=${lpWebsiteName}`}>
                                    <img className="hoa__logo"  width="55" height="55" slot="start" src={logo} alt="Hoampage"></img>
                                    <span className="navbar__name">{websiteData?.hoa_name}</span>
                                </a>
                            }

                        </div>
                        <IonItem slot="end">
                            {isAuthenticated &&
                                <a href="/Messages" className="navbar__link"> <img width="38" src={messages} alt="" /></a>}

                            <a href={`hoawebsite?community=${lpWebsiteName}#homeowners-section`} className="navbar__link">Homeowners    <span></span></a>
                            {(websiteData?.showHideContact) && <a href={`hoawebsite?community=${lpWebsiteName}#contact-section`} className="navbar__link">Contact <span></span></a>}

                            {!isAuthenticated && <>
                                <IonButton href={`/Login`} slot="end" className="navbar__login-btn" size="default" fill='clear' >
                                    <img src={lock} alt="lock-icon" /> <span>Log In </span> </IonButton>
                                <IonButton href={`/Signup?community=${lpWebsiteName}`} slot="end" className="navbar__signup-btn" color="primary" size="default" ><span>Sign Up</span></IonButton>
                            </>}
                            {isAuthenticated && <a slot="end" className="account-info" onClick={showPopoverHandler}><span>Welcome Home Emil</span> <img width="45" src={accountIcon} alt="account" /></a>}
                        </IonItem>

                    </IonToolbar>
                </IonHeader>
                <div className="website-banner" style={{ 'backgroundImage': `url(${process.env.REACT_APP_HP_API_URL}/index.php/hoas/view-image?image=${websiteData?.hoa_banner}&hoa_id=${lpWebsiteHoaId}&placeholder=images%2Fhoa-website%2Fbanner.jpg` } as React.CSSProperties}>
                    <div className="banner-container">
                        <h2 className="heading--serif-no-transform hoaWebsite__heading--h1">{(websiteData?.header) ? websiteData?.header : 'Welcome Home'}</h2>
                        <div className="description">{websiteData?.description}</div>
                        <a className="btn learn-more-btn" href={`hoawebsite?community=${lpWebsiteName}#homeowners-section`}>Learn More</a>
                    </div>
                </div>

                <div className="homeowners-section" id="homeowners-section">
                    <div className="container">
                        <IonRow className="heading-section">
                            <IonCol size="12">
                                <h2 className="hoaWebsite__heading--h2" >Are you a My New HOA Homeowner?</h2>
                                <p className="hoaWebsite__paragraph" >
                                    Begin exploring your community and connecting with your neighbors.
                                </p>
                            </IonCol>
                        </IonRow>
                        {/* <IonRow>
                            <IonCol size="12">
                                <a href="homeowner-registration">
                                    <i aria-hidden="true" className="fas fa-home logo"></i>
                                </a>
                                <h3 className="hoaWebsite__heading--h4">My Household</h3>
                                <p className="hoaWebsite__paragraph second-para">Manage household member profiles, and handle HOA correspondence.</p>

                                <a href="homeowner-registration" className="second-btn reg-login-btn">Register / Login</a>
                            </IonCol>
                        </IonRow> */}

                        <div className="row section-wrapper">
                            <div className="text-center">
                                <div className="action-box">
                                    <div className="action-box-description">
                                        <a href={(isAuthenticated) ? websiteData?.myHouseholdUrl : `/Login`} >
                                            <img width="50" src="/images/hoa-website/logo-household.svg" alt="icon" />
                                        </a>
                                        <h3 className="hoaWebsite__heading--h4 heading--serif">My Household</h3>
                                        <p className="hoaWebsite__paragraph--action-box">{websiteData?.myHouseholdText}</p>
                                    </div>
                                    {isAuthenticated && <a href={`/Home?community=${lpWebsiteName}`} className="btn btn-second">Visit My Household</a>}
                                    {!isAuthenticated && <a href={`/Login`} className="btn btn-second">Register / Login</a>}
                                </div>
                            </div>
                            <div className="text-center">
                                <div className="action-box">
                                    <div className="action-box-description">
                                        <a href={(isAuthenticated) ? websiteData?.myCommunityUrl : `/Login`}>
                                            <img width="50" src="/images/hoa-website/logo-community.svg" alt="icon" />
                                        </a>
                                        <h3 className="hoaWebsite__heading--h4 heading--serif">My Community</h3>
                                        <p className="hoaWebsite__paragraph--action-box">{websiteData?.myCommunityText}</p>
                                    </div>
                                    {isAuthenticated && <a href={`/Home?community=${lpWebsiteName}`} className="btn btn-second">Visit My Community</a>}
                                    {!isAuthenticated && <a href={`/Login`} className="btn btn-second">Register / Login</a>}
                                </div>
                            </div>
                            <div className="text-center">
                                <div className="action-box">
                                    <div className="action-box-description">
                                        <a href={(isAuthenticated) ? websiteData?.mySocialUrl : `/Login`}>
                                            <img width="50" src="/images/hoa-website/logo-social.svg" alt="icon" />
                                        </a>
                                        <h3 className="hoaWebsite__heading--h4 heading--serif">My Neighbors</h3>
                                        <p className="hoaWebsite__paragraph--action-box">{websiteData?.mySocialText}</p>
                                    </div>
                                    {isAuthenticated && <a href={`/Home?community=${lpWebsiteName}`} className="btn btn-second">Visit My Neighbors</a>}
                                    {!isAuthenticated && <a href={`/Login`} className="btn btn-second">Register / Login</a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {( (websiteData?.showContactFormOnHOAWebsite.enabled && websiteData?.showContactFormOnHOAWebsite.public)
            || (websiteData?.showContactFormOnHOAWebsite.enabled && !websiteData?.showContactFormOnHOAWebsite.public && isAuthenticated) ) &&        
                <div className="contact-section" id="contact-section">
                    <div className="container">
                        <div className="contact-sample">
                            {(websiteData?.hcu_id) &&
                                 <div className="contact-details">
                                 <h1 className="hoaWebsite__heading--h2">Get in touch...</h1>
                                 <div className="row">
                                     <div className="without-map">
                                         <div className="contact-field contact-office-hours">
                                             <div className="schedule">
                                                 {websiteData?.officeHoursDisplay && <div dangerouslySetInnerHTML={{ __html: websiteData.officeHoursDisplay }} />}
                                             </div>
                                         </div>
                                         <div className="contact-field contact-phone">
                                             <i aria-hidden="true" className="fas fa-phone"></i>
                                             <div className="phone">{websiteData?.hcu_phone_number}</div>
                                         </div>
                                         <div className="contact-field contact-location">
                                             <i aria-hidden="true" className="fas fa-map-marker-alt"></i>
                                             <div className="location">{websiteData?.fullAddress}</div>
                                         </div>
                                         <div className="contact-field contact-email">
                                             <i className="fas fa-envelope"></i>
                                             <div className="email">{websiteData?.hcu_email_address}</div>
                                         </div>
                                         <div className="contact-field contact-link">
                                             <a className="btn-tertiary" href={websiteData?.hcu_map_link} target="_blank" rel="noreferrer">Get Directions</a>
                                         </div>
                                     </div>
 
                                 </div>
                             </div>
                            }
                           
                            <div className={(websiteData?.hcu_id)?'contact-form' :'contact-form-fullwidth'}>

                                <div className="hoa-faq-form">
                               {(!websiteData?.hcu_id) &&  <h1 className="hoaWebsite__heading--h2 text-center">Get in touch...</h1>}
                                    <form onSubmit={handleSubmit(contactHOAonSubmit)}>
                                        <div className='form-input-group'>
                                            <IonLabel className='form-input-label'>Name <span></span></IonLabel>
                                            <IonInput
                                                {...register('fullname', {
                                                    required: 'This is required',
                                                })}
                                                type='text'
                                                placeholder='Full name'
                                                className='form-input'
                                                mode="md"
                                            // onIonChange={()}
                                            ></IonInput>
                                            <ErrorMessage
                                                errors={errors}
                                                name='fullname'
                                                as={<div className='error-message' />}
                                            />
                                        </div>
                                        <div className="form-input-group-name">
                                            <div className='form-input-group'>
                                                <IonLabel className='form-input-label'>Email Address<span></span></IonLabel>
                                                <IonInput
                                                    {...register('email', {
                                                        required: 'Email is a required field',
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                            message: 'Invalid email address',
                                                        },
                                                    })}
                                                    type='email'
                                                    placeholder='Email Address'
                                                    className='form-input'
                                                    mode="md"
                                                ></IonInput>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name='email'
                                                    as={<div className='error-message' />}
                                                />
                                            </div>
                                            <div className='form-input-group'>
                                                <IonLabel className='form-input-label'>Phone Number<span></span></IonLabel>
                                                <IonInput
                                                    {...register('phone')}
                                                    type='text'
                                                    placeholder='Phone Number'
                                                    className='form-input'
                                                    mode="md"
                                                ></IonInput>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name='phone'
                                                    as={<div className='error-message' />}
                                                />
                                            </div>

                                        </div>
                                        <div className='form-input-group'>
                                            <IonLabel mode="md" className='form-input-label'>Message<span></span></IonLabel>
                                            <IonTextarea
                                                className='form-input'
                                                {...register('message', {
                                                    required: 'This is required',
                                                })}
                                                placeholder='Message'
                                                rows={5}
                                                mode="md"
                                            ></IonTextarea>
                                            <ErrorMessage
                                                errors={errors}
                                                name='message'
                                                as={<div className='error-message' />}
                                            />
                                        </div>
                                        <div className="submit-button"><IonButton mode="md" type="submit">Submit</IonButton></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    }
                <div className="footer-container">
                    <div className="container ws-footer home-footer">
                        <div className="row">
                            {/* <div className="useful-links">
                                <div>
                                    <a className="showModalButton btn-tertiary" title="Rules &amp; Guidelines" >Rules &amp; Guidelines</a>
                                </div>
                                <div>
                                    <a className="showModalButton btn-tertiary" title="Privacy Policy" >Privacy Policy</a>
                                </div>
                            </div> */}
                            <div className="copyright">
                                <div>
                                    <img src={copyright} alt="copyright" />
                                    Powered by Page Per Page |  © {new Date().getFullYear()}  <span className="home-footer__copyright__hoa-name">My New HOA</span>
                                </div>
                            </div>
                        </div>
                    </div>        </div>
            </IonContent>
            <Modal
                logo={hoampageIcon}
                title={title}
                description={description}
                buttonText={buttonText}
                isOpen={successModal}
                closeAction={hidesuccessModal}
            ></Modal>
        </IonPage> }
        </>
    );
};

export default LandingPage;
