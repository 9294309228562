import { chatbubbles } from "ionicons/icons";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonLabel,
  IonButton,
  IonItem,
  IonAvatar,
  IonRouterLink,
  IonText
} from "@ionic/react";
import "./HomeDesktop/HomeDesktop.css";
import Header from "../../components/Header/Header";
import Contact from "../../components/Contact/Contact";
import PayNow from "../../components/PayNow/PayNow";
import Messages from "../../pages/Messages/Messages";
import calendarIcon from "../../assets/menu-icons/calender-icon.svg";
import forumsIcon from "../../assets/menu-icons/forums-icon.svg";
import newsIcon from "../../assets/menu-icons/news-icon.svg";
import { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import { Http } from "@capacitor-community/http";
import { authActions } from './../../store/auth'
import { useDispatch } from "react-redux";
import { convetDateToString, timeConvert, } from '../../Constants/general';
import { get } from "../../storage/storage";
import { useSelector } from "react-redux";

interface newsState {
  hn_id: string,
  hn_title: string,
  hn_body: string,
  hn_image: string,
  hn_createdByName: string,
  hn_userPhoto: string,
  hn_createdTime: string
};

interface calendarState {
  ce_id: string,
  ce_title: string,
  ce_start_date: string,
  ce_start_time: string,
  ce_end_date: string,
  ce_end_time: string,
  ce_category: string,
  ce_group_id: string,
  ce_hoa_id: string,
  ce_description: string,
  ce_location: string,
  ce_deleted: string,
  ce_created_by: string
};

interface forumState {
  ftp_id: string,
  ftp_thread_id: string,
  ftp_user_id: string,
  ftp_body: string,
  ftp_is_main: string,
  ftp_created_time: string,
  ftp_updated_time: string,
  ftp_hidden: string
}
let communitymanagerInfo = {}
const HomeDesktop: React.FC = () => {
  const {token,loginUserHoaId,loginUserFname,loginUserPhoto, loginUserLeftMenu, loginUserHoaSubscription} = useSelector(
    (state: any) => state.auth
  );
  const parsLeftMenu = (loginUserLeftMenu)? JSON.parse(loginUserLeftMenu):null;
  
  const [contactBox, setContactBox] = useState(false);
  const [showContactBtn, setShowContactBtn] = useState(true);
  const [newsData, setNewsData] = useState<newsState[]>([]);
  const [calendarEvent, setCalendarEvent] = useState<calendarState[]>([]);
  const [calendar, setCalendar] = useState(false);
  const [news, setNews] = useState(false);
  const [forums, setForums] = useState(false);
  const [paymentPortal, setPaymentPortal] = useState(false);
  const [message, setMessages] = useState(false);

  const [forumApiData, setForumApiData] = useState<forumState[]>([])

  const [hoaId, setHoaId] = useState(loginUserHoaId);
  const [authToken, setAuthToken] = useState(token);
  const [userFname, setLoginUserFname] = useState(loginUserFname);
  const [userPhoto, setLoginUserPhoto] = useState(loginUserPhoto);

  const dispatch = useDispatch();

  const contactBoxHandler = () => {
    setContactBox(!contactBox);
  }
  const fetchManagerInfo = async () => {
    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/hoas/view?id=${hoaId}`;
    const options = {
      url: url,
      headers: {
        authorization: `Bearer ${authToken}`
      },
    };
    let response = await Http.get(options);
    if (response.status != 200) {
      dispatch(authActions.logout());
      return;
    }
    if (response.data.communityManager == null) {
      setShowContactBtn(false);
      return
    }
    
    communitymanagerInfo = {
      hoa_name: response.data.hoa_name,
      hcm_first_name: response.data.communityManager.hcm_first_name,
      hcm_last_name: response.data.communityManager.hcm_last_name,
      hcm_image: response.data.communityManager.hcm_image,
      hcm_management_company: response.data.communityManager.hcm_management_company,
      hcm_management_company_website: response.data.communityManager.hcm_management_company_website,

      hcu_address: response.data.contactInfo.hcu_address,
      hcu_city: response.data.contactInfo.hcu_city,
      hcu_zipcode: response.data.contactInfo.hcu_zipcode,

      hcu_email_address: response.data.contactInfo.hcu_email_address,
      hcu_phone_number: response.data.contactInfo.hcu_phone_number,
      hcu_map_link:response.data.contactInfo.hcu_map_link,
      hcu_office_hours: response.data.contactInfo.officeHoursDisplay
      
    }
  };
  const fetchNews = async () => {

    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/news?hoa_id=${hoaId}`;
    const options = {
      url: url,
      headers: {
        authorization: `Bearer ${authToken}`
      },
    };
    const newsResponse = await Http.get(options);
    if (newsResponse.status != 200) {
      dispatch(authActions.logout());
      return;
    }
    setNewsData(newsResponse.data.items)

  };

  const fetchCalendarEvents = async () => {
    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/hoacalendarevents/get-upcoming-events?hoa_id=${hoaId}`;
    const options = {
      url: url,
      headers: {
        authorization: `Bearer ${authToken}`
      },
    };
    const response = await Http.get(options);
    if (response.status != 200) {
      dispatch(authActions.logout());
      return;
    }
    let alterResponse = [];
    for (let i = 0; i < response.data.length; i++) {
      let aObject = response.data[i];
      aObject.ce_start_date = convetDateToString(aObject.ce_start_date);
      aObject.ce_start_time = timeConvert(aObject.ce_start_time)
      aObject.ce_end_time = timeConvert(aObject.ce_start_time)
      alterResponse.push(aObject)
    }

    setCalendarEvent(alterResponse);
  };
  const fetchForumThread = async () => {
    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/forumthreadposts?hoa_id=${hoaId}`;
    const options = {
      url: url,
      headers: {
        authorization: `Bearer ${authToken}`
      },
    };
    const forumResponse = await Http.get(options);
    if (forumResponse.status != 200) {
      dispatch(authActions.logout());
      return;
    }

    if(forumResponse.data.items) {
      for (let i = 0; i < forumResponse.data.items.length; i++) {
        let post = forumResponse.data.items[i];
        let dateObject = new Date(post.ftp_created_time * 1000);// convert timestamp to date object
        post.ftp_created_time = `${dateObject.toLocaleString('default', { month: 'long' })} ${dateObject.getDate()} ${dateObject.getFullYear()}`
      }

      setForumApiData(forumResponse.data.items)
    }
  };

  // show sections if they are enabled
  const sectionsHandler = ()=>{
    for (let key in parsLeftMenu) {
      if (Object.prototype.hasOwnProperty.call(parsLeftMenu, key)) {
        const menuItem = parsLeftMenu[key];
        if (menuItem.feature=='Calendar' && menuItem.enabled) {
            setCalendar(true);
            fetchCalendarEvents();
        }
        if (menuItem.feature=='News' && menuItem.enabled) {
            setNews(true);
            fetchNews();
        }
        if (menuItem.feature=='Forums' && menuItem.enabled) {
            setForums(true);
            fetchForumThread();
        }
        if (menuItem.feature=='Payment Portal' && menuItem.enabled) {
            setPaymentPortal(true);
        }
      }
    }
    // For Tier 1 (subscription 1) display the messages section
    if(loginUserHoaSubscription == "1") {
      setMessages(true)
    }
  }

  useEffect(() => {
      (async () => {
        if (await get('loginUserHoaId')) {
          setHoaId(await get('loginUserHoaId'));
        }
        if (await get('loginUserFname')) {
          
          setLoginUserFname(await get('loginUserFname'))
        }
        if (await get('loginUserPhoto')) {
          
          setLoginUserPhoto(await get('loginUserPhoto'))
        }
        if (await get('token')) {
          setAuthToken(await get('token'))
        }
      })();
   
    setTimeout(function() { 
      (async () => {
        sectionsHandler();
        fetchNews();
        fetchCalendarEvents();
        fetchForumThread();
        fetchManagerInfo();
      })();
    }, 1000)
}, []);
  return (
    <>
    <IonPage>
      <Header title="" />
      <IonContent className="homeContainer">
        <IonItem className="userProfile">
          <IonAvatar slot="start">
            <img alt="news" src={`${process.env.REACT_APP_HP_API_URL}/index.php/auth/view-image?image=${userPhoto}`} />
          </IonAvatar>
          <IonLabel>
            <h2>Welcome Home, {userFname}</h2>
          </IonLabel>
        </IonItem>
        <IonGrid>
          <IonRow>
            {news && 
              <IonCol className="home__col1" size-md="7" size-xl="8">
                {/* WHAT'S NEW #1 - visible only on higher screens (768+) */}
                <div className="cardContainer">
                  <div className="cardHeader">
                    <div className="cardTitle">
                      <IonIcon src={newsIcon}></IonIcon><h6>  What’s New?</h6>
                    </div>
                  </div>
                  {(newsData.length > 0) &&
                    <div className="cardBody">
                      {newsData.map((news) =>
                        <IonCard key={news.hn_id} className="newsNotification">
                          <IonItem>
                            <IonAvatar slot="start">
                              <img src={`${process.env.REACT_APP_HP_API_URL}/index.php/auth/view-image?image=${news.hn_userPhoto}`} />
                            </IonAvatar>
                            <IonLabel>
                              <h3>{news.hn_createdByName}</h3>
                              <p>{news.hn_createdTime}</p>
                            </IonLabel>
                          </IonItem>
                          <IonCardContent>
                            <p>{news.hn_body.replace(/<[^>]*>?/gm, '')}</p>
                            {news.hn_image && <div className="newImage">
                              <img src={`${process.env.REACT_APP_HP_API_URL}/index.php/hoas/view-image?hoa_id=${hoaId}&image=${news.hn_image}`} />
                            </div>}
                          </IonCardContent>
                        </IonCard>
                      )}

                      <IonRouterLink routerLink="/News">
                        <IonText>View All News</IonText>
                      </IonRouterLink>
                    </div>
                  }
                  {(newsData.length == 0) &&
                    <div className="no-data-placehoder">
                      <IonIcon src={newsIcon}></IonIcon>
                      <p>Nothing new here, but be sure to <br />check back soon!</p>
                    </div>}
                </div>
              </IonCol>
            }
             {/* MESSAGES */}
             {message && 
                <IonCol className="home__col1" size-md="7" size-xl="8">
                  {/* WHAT'S NEW #1 - visible only on higher screens (768+) */}
                    <Messages
                      isHomeWidget="true"
                    ></Messages>
                </IonCol>
              }

            <IonCol size-md="5" size-xl="4">
              {/* PAY MY BILL */}
              {paymentPortal && 
                <PayNow
                  isHomeWidget="true"
                ></PayNow>
              }

              {/* MESSAGES */}
              {message && 
               <div className="home__onmobile cardContainer">
                  <Messages
                    isHomeWidget="true"
                  ></Messages>
                </div>
              }

              {/* WHAT'S AHEAD */}
              {calendar && 
                <div className="cardContainer">
                  <div className="cardHeader">
                    <div className="cardTitle">
                      <IonIcon src={calendarIcon}></IonIcon><h6> What's ahead?</h6>
                    </div>
                  </div>
                  {(calendarEvent.length > 0) &&
                    <div className="cardBody">
                      {calendarEvent.map((aEvent) =>
                        <IonCard key={aEvent.ce_id} className="calendarNotification">
                          <IonCardContent>
                            <h3 className="calendarNotificationTitle"><a href="#">{aEvent.ce_title}</a></h3>
                            <p className="time">{aEvent.ce_start_date}<br />
                              {`${aEvent.ce_start_time} - ${aEvent.ce_end_time}`}</p>
                            <p>{aEvent.ce_description}</p>
                            <p>{aEvent.ce_location}</p>
                          </IonCardContent>
                        </IonCard>)}
                      <IonRouterLink routerLink="/Calendar">
                        <IonText>View Calendar</IonText>
                      </IonRouterLink>
                    </div>
                  }
                  {(calendarEvent.length == 0) &&
                    <div className="no-data-placehoder">
                      <IonIcon src={calendarIcon}></IonIcon>
                      <p>Your schedule is  <br />all clear!</p>
                    </div>}
                </div>
              }

              {/* WHAT'S NEW #2 - visible only on mobile */}
              {news && 
                <div className="home__rightColWhatsNew cardContainer">
                  <div className="cardHeader">
                    <div className="cardTitle">
                      <IonIcon src={newsIcon}></IonIcon><h6>  What’s New?</h6>
                    </div>
                  </div>
                  {(newsData.length > 0) &&
                    <div className="cardBody">
                      {newsData.map((news) =>
                        <IonCard key={news.hn_id} className="newsNotification">
                          <IonItem>
                            <IonAvatar slot="start">
                              <img src={`${process.env.REACT_APP_HP_API_URL}/index.php/auth/view-image?image=${news.hn_userPhoto}`} />
                            </IonAvatar>
                            <IonLabel>
                              <h3>{news.hn_createdByName}</h3>
                              <p>{news.hn_createdTime}</p>
                            </IonLabel>
                          </IonItem>
                          <IonCardContent>
                            <p>{news.hn_body.replace(/<[^>]*>?/gm, '')}</p>
                            {news.hn_image && <div className="newImage">
                              <img src={`${process.env.REACT_APP_HP_API_URL}/index.php/hoas/view-image?hoa_id=${hoaId}&image=${news.hn_image}`} />
                            </div>}
                          </IonCardContent>
                        </IonCard>
                      )}

                      <IonRouterLink routerLink="/News">
                        <IonText>View All News</IonText>
                      </IonRouterLink>
                    </div>
                  }
                  {(newsData.length == 0) &&
                    <div className="no-data-placehoder">
                      <IonIcon src={newsIcon}></IonIcon>
                      <p>Nothing new here, but be sure to <br />check back soon!</p>
                    </div>}
                </div>
              }

              {/* <div className="cardContainer">
                <div className="cardHeader">
                  <div className="cardTitle">
                    <IonIcon src={forumsIcon}></IonIcon><h6> New Discussions</h6>
                  </div>
                </div>
                {(forumApiData.length > 0) &&
                  <div className="cardBody">
                    {forumApiData.map((post) =>
                      <IonCard key={post.ftp_id} className="forumNotification">
                        <IonItem>
                          <IonAvatar slot="start">
                            <img src="https://bit.ly/3i4DjJJ" />
                          </IonAvatar>
                          <IonLabel>
                            <h3>Tree Tremmers</h3>
                            <p>{post.ftp_created_time}</p>
                          </IonLabel>
                        </IonItem>
                        <IonCardContent>
                          <p>{post.ftp_body} <a href="#">View Discussions</a></p>
                        </IonCardContent>
                      </IonCard>)}
                    <IonRouterLink routerLink="/Forums">
                      <IonText>View All Forums</IonText>
                    </IonRouterLink>
                  </div>
                }
                {(forumApiData.length == 0) &&
                  <div className="no-data-placehoder">
                    <IonIcon src={forumsIcon}></IonIcon>
                    <p>Nothing new here, but be sure to check back soon!</p>
                  </div>}
              </div> */}
            </IonCol>
          </IonRow>
        </IonGrid>
        {contactBox && <Contact mangerInfo={communitymanagerInfo} contactBoxHandler={contactBoxHandler} />}
        {showContactBtn && <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonButton onClick={contactBoxHandler}>
            <IonIcon icon={chatbubbles} slot="icon-only" /> &nbsp;
            Contact
          </IonButton>
        </IonFab>
        }
      </IonContent>
      
    </IonPage>
    </>
  );
};
export default HomeDesktop;
