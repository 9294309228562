export const customStyles = {
    headRow: {
      style: {
        background: "#606163",
        paddingTop: "15px",
        paddingBottom: "15px"
      },
    },
    headCells: {
      style: {
        color: "#ffffff",
        fontSize: "17px",
        fontWeight: "100",
        pointerEvents: "none"
      },
    },
  
    rows: {
      style: {
        backgroundColor: '#ffffff',
        borderBottomColor: '#ffffff',
        // outline: '1px solid #9d9fa6',
        paddingTop: "8px",
        paddingBottom: "8px"
      },
    },
    cells:{
      style:{
        color:"#7c7d7f",
        fontSize:"16px"
      }
    },
    highlightOnHoverStyle: {
      color: "#fffff",
    },
  };
  export const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  export var columnsDesktop = [
    {
      name: "NAME",
      cell: (row: any) => `${row.hpds_name}`,
      // sortable: true,
    },
    {
      name: "PROPERTY ADDRESS",
      selector: (row: any) => row.hpds_property,
      // sortable: true,
    },
    {
      name: "PHONE",
      selector: (row: any) => row.hpds_phone_number,
      // sortable: true,
    },
    {
      name: "EMAIL",
      selector: (row: any) => row.hpds_email_address,
      // sortable: true,
    }
  ];
  export var columnsMobile = [
    {
      name: "NAME",
      cell: (row: any) => `${row.hpds_name}`,
      // sortable: true,
    },
  ];

  