import React, {useState,useEffect}from "react";
import { isPlatform,IonGrid, IonRow, IonCol, IonButton, IonItem } from "@ionic/react"
import "./Documents.css";
import { get } from "./../../storage/storage";

const DocumentDetails = (data: any) => {
    const [isDesktop, setDesktop] = useState(false);
    const [isTablet, setTablet] = useState(false);
    const [hoaId,setHoaId]= useState('');
    useEffect(() => {
        
        if (isPlatform("desktop")) {
          setDesktop(true);
        }
        if (isPlatform("tablet")) {
          setTablet(true)
        }
        (async ()=>{
          setHoaId(await get('loginUserHoaId'));
      })();
      }, []);
    let lastDate = new Date(data.data.hd_created_time * 1000);// convert timestamp to date object
    let lastUpdate = `${lastDate.toLocaleString('default', { month: 'long' })} ${lastDate.getDate()} ${lastDate.getFullYear()}`
    
    return (
        <div className="documentDetailContainer">
        <IonGrid>
            <IonRow>
                <IonCol size={(isDesktop || isTablet)?'2':'4'} className="documentLable">Description:</IonCol>
                <IonCol size={(isDesktop || isTablet)?'10':'8'}>{data.data.hd_title}</IonCol>
            </IonRow>
            <IonRow>
                <IonCol size={(isDesktop || isTablet)?'2':'4'} className="documentLable">Last Updated:</IonCol>
                <IonCol size={(isDesktop || isTablet)?'10':'8'} >{lastUpdate}</IonCol>
            </IonRow>
        </IonGrid>
        <IonItem>
        {(isDesktop || isTablet) && <IonButton mode="md" size="default" slot="end" target="_blank" href={`${process.env.REACT_APP_HP_API_URL}/index.php/hoas/community-document-view?hoa_id=${hoaId}&document=${data.data.hd_id}`}  >View</IonButton>}
        <IonButton mode="md" size="default" slot="end" target="_blank" href={`${process.env.REACT_APP_HP_API_URL}/index.php/hoas/community-document-download?hoa_id=${hoaId}&document=${data.data.hd_id}`} >Download</IonButton>
    </IonItem>
    </div>
    )
}

export default DocumentDetails;
