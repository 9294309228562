
import React, { useRef, useState, useEffect } from "react";
import {
    IonContent,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonSearchbar,
    IonIcon,
    IonItem,
    IonButton,
    isPlatform
} from "@ionic/react";
import { Http } from "@capacitor-community/http";
import DataTable, { createTheme, defaultThemes } from "react-data-table-component";
import './Forums.css';
import Header from '../../components/Header/Header';
import { customStyles, columnsDesktop } from './Constants'
import { searchIcon, refreshIcon } from '../../Constants/general'
import { authActions } from './../../store/auth'
import { useDispatch } from "react-redux";
import NewDiscussion from './NewDiscussion/NewDiscussion'
import Modal from '../../components/Modal/Modal';
import plusIcon from '../../assets/menu-icons/plusIcon.svg';
import { get } from "./../../storage/storage";
const Forums: React.FC = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [successButton, setButtonText] = useState('');
    const [successtitle, setSuccessTitle] = useState('');
    const [successDescription, setSuccessDescription] = useState('');

    const [filterBy, setFilterBy] = useState('');
    const [filterText, setFilterText] = useState('');
    const [filterError, setFilterError] = useState(false);
    const [isDesktop, setDesktop] = useState(false);
    const [isTablet, setTablet] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [isIos,setIsIos] = useState(false);

    const dispatch = useDispatch();
   

    const fetchUsers = async (page: any) => {
        setLoading(true);

        let searchText = (filterText) ? `&ForumThread[${filterBy}]=${filterText}` : '';
        const url = `${process.env.REACT_APP_HP_API_URL}/index.php/forumthreads?hoa_id=${await get('loginUserHoaId')}&page=${page}${searchText}`;
        const options = {
            url: url,
            headers: {
                authorization: `Bearer ${await get('token')}`
            },
        };
        const response = await Http.get(options);
        if (response.status != 200) {
            dispatch(authActions.logout());
            return;
        }
        setData(response.data.items);
        setTotalRows(response.data._meta.totalCount);
        setLoading(false);
    };
    const handlePageChange = (page: any) => {
        fetchUsers(page);
    };

    const closeModal = async () => {
        await setShowModal(false);

        setSuccessModal(true);
        setSuccessTitle('DONE!');
        setSuccessDescription('Your post has been added successfully!');
        setButtonText('Continue');

    }
    const hidesuccessModal = async () => {
        await setSuccessModal(false);
    }
    useEffect(() => {
        
        if (isPlatform("desktop")) {
            setDesktop(true);
        }
        if (isPlatform("tablet")) {
            setTablet(true)
        }
        if (isPlatform("ios")) {
            setIsIos(true);
          }
        setFilterBy('')
        setFilterText('')
        fetchUsers(1);
    }, [refresh]);
    const searchForums = (e: any) => {
        e.preventDefault();
        if (!filterBy) {
            setFilterError(true);
            return
        }
        setFilterError(false);
        fetchUsers(1);
    }
    return (
        <IonPage >
            <Header title='Forums' />
            <IonContent >
            <div className="filter-container" >
                <h3 className="page-tile" >
                        Forums
                    </h3>
                    <form onSubmit={searchForums}   className="filtet-form">
                        <div className="filter-wrapper">
                        <img className="refresh-page" src={refreshIcon} onClick={() => setRefresh(!refresh)} />
                        <div className="filter-type-wrapper">
                            <select className="filter-type" name="filter_by" value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
                                <option value="">&nbsp;   Filter By</option>
                                <option value="forumContent">&nbsp; Content</option>
                            </select>
                            </div>
                            <div className={(isIos)?'filter-input-wrapper filter-input-wrapper-ios' :'filter-input-wrapper filter-input-wrapper-android'}>
                                <button type="submit"> <img src={searchIcon} /> </button>
                                <input type="text" className="filter-input" value={filterText} name="filter_text" onChange={(e) => setFilterText(e.target.value)} placeholder="&nbsp; Search" />
                            </div>
                            {(isDesktop || isTablet) && <div> <IonButton mode="md" size="default" onClick={() => setShowModal(true)}>New Discussion</IonButton> </div>}
                        </div>
                        {filterError && <div className="filter-error-msg">Please select column to filter by</div>}
                    </form>
                    

                </div>
                <br />
                <div className="filterBottomLine"></div>
                {(!isDesktop && !isTablet) && <IonItem><IonButton mode="md" size="default" slot="end" onClick={() => setShowModal(true)}>New Discussion</IonButton></IonItem>}
                <div className="forumTable">
                    <DataTable
                        columns={columnsDesktop}
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        customStyles={customStyles}
                        striped={true}
                        highlightOnHover={true}
                    />
                </div>
                <NewDiscussion
                    title="New Disscustion"
                    isOpen={showModal}
                    closeAction={closeModal}
                />
                <Modal
                    logo={plusIcon}
                    title={successtitle}
                    description={successDescription}
                    buttonText={successButton}
                    isOpen={successModal}
                    closeAction={hidesuccessModal}
                ></Modal>
            </IonContent >
        </IonPage >

    );
}
export default Forums;