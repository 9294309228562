/** */
import React, { useEffect, useState } from 'react';

import {
    IonContent,
    IonPage,
    IonLabel,
    IonInput,
    IonButton,
    IonItem
} from '@ionic/react';
import './MyAccount.css';
import Header from '../../components/Header/Header';
import { useDispatch } from 'react-redux'
import { menuActions } from '../../store/menuShowHide'
import { useForm, useFormState } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Http } from '@capacitor-community/http';
import { authActions } from './../../store/auth'
import Modal from '../../components/Modal/Modal';
import checkIcon from '../../assets/menu-icons/checkedIcon.svg'
import { get } from "./../../storage/storage";
import { returnUpBackOutline } from 'ionicons/icons';
const MyAccount: React.FC = () => {
    const dispatch = useDispatch();
    const updaeState = () => {
        dispatch(menuActions.toggleMenu({ showMainMenu: false }));
    }
    const [fullname, setFullname] = useState('');
    const [userAddress, setUserAddress] = useState('');

    const [successModal, setSuccessModal] = useState(false);
    const [buttonText, setButtonText] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [userRole,setUserRole] = useState('')

    const fetchAccountDetails = async () => {
        let url = `${process.env.REACT_APP_HP_API_URL}/index.php/auth/account?propertyId=${await get('loginUserPropertyId')}`;
        const options = {
            url: url,
            headers: {
                authorization: `Bearer ${await get('token')}`
            },
        };
        const response = await Http.get(options);
        if (response.status != 200) {
            dispatch(authActions.logout());
            return;
        }
        let userInfo = response.data.userModel
        setFullname(`${userInfo.user_fname} ${userInfo.user_lname}`);
        setValue('user_email', userInfo.user_email);
        setValue('user_fname', userInfo.user_fname);
        setValue('user_lname', userInfo.user_lname);
        setValue('user_phone_number', userInfo.user_phone_number);
    };

    const {
        handleSubmit,
        register,
        getValues,
        setValue,
        setError,
        formState: { errors },
    } =
        useForm({
            defaultValues: {
                user_email: '',
                user_fname: '',
                user_lname: '',
                user_phone_number: '',
                user_existing_password: '',
                user_new_password: '',
                user_password_repeat: '',
            },
            mode: 'onBlur',
        });
    const onSubmit = async (data: any) => {

        let url = `${process.env.REACT_APP_HP_API_URL}/index.php/auth/account-save?propertyId=${await get('loginUserPropertyId')}`;
        const options = {
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${await get('token')}`
            },
            data: {
                'fileuploader-list-image': [],
                'User[image][]': '',
                'User[user_email]': getValues('user_email'),
                'User[user_fname]': getValues('user_fname'),
                'User[user_lname]': getValues('user_lname'),
                'User[user_phone_number]': getValues('user_phone_number'),
                'User[user_existing_password]': getValues('user_existing_password'),
                'User[user_new_password]': getValues('user_new_password'),
                'User[user_password_repeat]': getValues('user_password_repeat'),
            },
        };
        const response = await Http.post(options);
        setRefresh(!refresh)
        if (response.status != 200) {
            dispatch(authActions.logout());
            return;
        }
        if (response.data.success) {
            setSuccessModal(true);
            setTitle(response.data.messageHeading);
            setDescription(response.data.messageContent);
            setButtonText('Continue');
        } else {
            let errorMsg = response.data
            for (const key in errorMsg) {
                if (Object.prototype.hasOwnProperty.call(errorMsg, key)) {
                    const element = errorMsg[key];
                   
                    let inputName: any = key.replaceAll('user-', '');
                    setError(inputName, {
                        type: "manual",
                        message: element[0],
                    });
                }
            }
        }
    };

    useEffect(() => {
        fetchAccountDetails()
        updaeState();
        (async ()=>{
            setUserAddress(`${await get('loginUserAddress')}, ${await get('loginUserCity')}, ${await get('loginUserState')}, ${await get('loginUserZipcode')}`);
            setUserRole(await get('loginUserRole'))
        })();
      
    }, [dispatch, errors,refresh]);
    const hidesuccessModal = async () => {
        await setSuccessModal(false);
      
    }

    return (

        <IonPage >
            <Header title="My Account" />

            <IonContent>
                <div className="my-account-form">
                    <IonItem className="user-info">
                        <img src={`/images/hoa-website/user_placeholder.png`} alt="" />
                        <div>
                            <h3>{fullname}</h3>
                            <div>{userAddress} <br />{userRole}</div>
                        </div>

                    </IonItem>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='form-input-group'>
                            <IonLabel className='form-input-label'>User Name*</IonLabel>
                            <IonInput
                                {...register('user_email', {
                                    required: 'Email is a required field',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Invalid email address',
                                    },
                                })}
                                type='email'
                                placeholder='Email Address'
                                className='form-input'
                                mode="md"
                            ></IonInput>
                            <ErrorMessage
                                errors={errors}
                                name='user_email'
                                as={<div className='error-message' />}
                            />
                        </div>
                        <div className='form-input-group-name'>
                            <div className='form-input-group'>
                                <IonLabel className='form-input-label'>First name*</IonLabel>
                                <IonInput
                                    {...register('user_fname', {
                                        required: 'This is required',
                                    })}
                                    type='text'
                                    placeholder='First Name'
                                    className='form-input'
                                    mode="md"
                                ></IonInput>
                                <ErrorMessage
                                    errors={errors}
                                    name='user_fname'
                                    as={<div className='error-message' />}
                                />
                            </div>
                            <div className='form-input-group'>
                                <IonLabel className='form-input-label'>Last name*</IonLabel>
                                <IonInput
                                    {...register('user_lname', {
                                        required: 'This is required',
                                    })}
                                    type='text'
                                    placeholder='Last Name'
                                    className='form-input'
                                    mode="md"
                                ></IonInput>
                                <ErrorMessage
                                    errors={errors}
                                    name='user_lname'
                                    as={<div className='error-message' />}
                                />
                            </div>
                        </div>
                        <div className='form-input-group'>
                            <IonLabel className='form-input-label'>Phone number*</IonLabel>
                            <IonInput
                                {...register('user_phone_number', {
                                    required: 'This is required',
                                })}
                                type='text'
                                placeholder='Mobile Number'
                                className='form-input'
                                mode="md"
                            ></IonInput>
                            <ErrorMessage
                                errors={errors}
                                name='user_phone_number'
                                as={<div className='error-message' />}
                            />
                        </div>
                        <div className='form-input-group'>
                            <IonLabel className='form-input-label'>Current Password*</IonLabel>
                            <IonInput
                                {...register('user_existing_password')}
                                type='password'
                                placeholder='Password'
                                className='form-input'
                                mode="md"
                            ></IonInput>
                            <ErrorMessage
                                errors={errors}
                                name='user_existing_password'
                                as={<div className='error-message' />}
                            />
                        </div>
                        <div className='form-input-group'>
                            <IonLabel className='form-input-label'>New Password</IonLabel>
                            <IonInput
                                {...register('user_new_password')}
                                type='password'
                                placeholder='Password'
                                className='form-input'
                                mode="md"
                            ></IonInput>
                            <ErrorMessage
                                errors={errors}
                                name='user_new_password'
                                as={<div className='error-message' />}
                            />
                        </div>
                        <div className='form-input-group'>
                            <IonLabel className='form-input-label'>Confirm New Password*</IonLabel>
                            <IonInput
                                {...register('user_password_repeat')}
                                type='password'
                                placeholder='Password'
                                className='form-input'
                                mode="md"
                            ></IonInput>
                            <ErrorMessage
                                errors={errors}
                                name='user_password_repeat'
                                as={<div className='error-message' />}
                            />
                        </div>
                        <div className='submit-button'>

                            <IonButton type='submit' >
                                Save Changes
                            </IonButton>
                        </div>
                    </form>
                </div>

            </IonContent >
            <Modal
                logo={checkIcon}
                title={title}
                description={description}
                buttonText={buttonText}
                isOpen={successModal}
                closeAction={hidesuccessModal}
            ></Modal>
        </IonPage >

    );
}


export default MyAccount;