import React from "react";
import { IonCard,IonCardContent } from '@ionic/react'
import './Directory.css'
const DirectoryDetails = (data: any) => {
    return (
        <IonCard className="directoryDetailContainer">
             <IonCardContent>
            <h2>Name</h2>
            <div>{`${data.data.hpds_name}`}</div>
            <h2>Property Address</h2>
            <div>{data.data.hpds_property}</div>
            <h2>Phone</h2>
            <div>{data.data.hpds_phone_number}</div>
            <h2>Email</h2>
            <div>{data.data.hpds_email_address}</div>
            </IonCardContent>
        </IonCard>
    )
}

export default DirectoryDetails;