import React, { useEffect } from "react";
import { personCircle } from "ionicons/icons";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import "./PaymentPortal.css";
import Header from "../../components/Header/Header";
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menuShowHide";
import PayNow from "../../components/PayNow/PayNow";
const PaymentPortal: React.FC = () => {
  const dispatch = useDispatch();
  const updaeState = () => {
    dispatch(menuActions.toggleMenu({ showMainMenu: false }));
  };
  useEffect(() => {
    updaeState();
  }, [dispatch]);
  return (
    <IonPage>
      <Header title="" />
      <IonContent>
        <PayNow isHomeWidget="false"></PayNow>
      </IonContent>
    </IonPage>
  );
};

export default PaymentPortal;
