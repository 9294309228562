
import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  isPlatform,
} from "@ionic/react";
import "./Directory.css";
import { customStyles, columnsDesktop, columnsMobile, alphabets } from './Constants'

import { Http } from '@capacitor-community/http';
import DataTable, { createTheme, defaultThemes } from "react-data-table-component";
import Header from "../../components/Header/Header";
import DirectoryDetails from './DirectoryDetails'
import { searchIcon, refreshIcon } from '../../Constants/general'
import { authActions } from './../../store/auth'
import { useDispatch } from "react-redux";
import { get } from "./../../storage/storage";


const Directory: React.FC = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [filterBy, setFilterBy] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterError, setFilterError] = useState(false);
  const [isDesktop, setDesktop] = useState(false);
  const [isTablet, setTablet] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const dispatch = useDispatch();

  const fetchDirectory = async (page: any, sortBy: string = '') => {
    setLoading(true);

    let sorting = (sortBy) ? `&letter=${sortBy.toLowerCase()}` : '';
    let searchText='';
    if (filterText) {
       searchText = `&HoaPerson[${filterBy}]=${filterText}`;
       sorting = ''
    }

    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/directory?hoa_id=${await get('loginUserHoaId')}&page=${page}${searchText}${sorting}`;
    const options = {
      url: encodeURI(url),
      headers: {
        authorization: `Bearer ${await get('token')}`
      },
    };

    const response = await Http.get(options);
    if (response.status !== 200) {
      dispatch(authActions.logout());
      return;
    }
    setData(response.data.items);
    setTotalRows(response.data._meta.totalCount);
    setLoading(false);
  };
  const handlePageChange = (page: any) => {
    fetchDirectory(page);
  };

  useEffect(() => {
    fetchDirectory(1);
    setFilterBy('')
    setFilterText('')
    if (isPlatform("desktop")) {
      setDesktop(true);
    }
    if (isPlatform("tablet")) {
      setTablet(true)
    }
    if (isPlatform("ios")) {
      setIsIos(true);
    }
   

  }, [refresh]);



  const searchDirectory = async (e: any) => {
    e.preventDefault();
    if (!filterBy) {
      setFilterError(true);
      return
    }
    setFilterError(false);
    fetchDirectory(1);
  }

  return (
    <IonPage>
      <Header title='Directory' />
      <IonContent>
        <div className="filter-container" >
          <h3 className="page-tile" >Directory</h3>
          <form onSubmit={searchDirectory} className="filtet-form" >
            <div className="filter-wrapper">
              <img alt="rfresh-icon" className="refresh-page" src={refreshIcon} onClick={() => setRefresh(!refresh)} />
              <div className="filter-type-wrapper">
                <select className="filter-type" name="filter_by" value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
                  <option value="">&nbsp;   Filter By</option>
                  <option value="full_name">&nbsp; Name</option>
                  <option value="property_address">&nbsp; Property Address</option>
                  <option value="hp_phone_number">&nbsp; Phone</option>
                  <option value="hp_email">&nbsp; Email</option>
                </select>
              </div>
              <div className={(isIos) ? 'filter-input-wrapper filter-input-wrapper-ios' : 'filter-input-wrapper filter-input-wrapper-android'}>
                <button type="submit"> <img alt="search-icon" src={searchIcon} /> </button>
                <input type="text" className="filter-input" value={filterText} name="filter_text" onChange={(e) => setFilterText(e.target.value)} placeholder="&nbsp; Search" />
              </div>
            </div>
            {filterError && <div className="filter-error-msg">Please select column to filter by</div>}
          </form>
        </div>
        <br />
        <div className="filterBottomLine"></div>
        <div>
          <ul className="letter-menu">
            {alphabets.map((letter: any) =>
              <li key={letter} >
                <a onClick={() => fetchDirectory(1, letter)}>{letter}</a>
              </li>
            )}
            <div className="ion-padding-start"></div>
          </ul>
        </div>
        <div className="directoryTable">
          {(isDesktop || isTablet) ?
            <DataTable
              data={data}
              columns={columnsDesktop}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              customStyles={customStyles}
              striped={true}
              highlightOnHover={true}
            />
            :
            <DataTable
              expandableRows={true}
              selectableRowsComponentProps={{ inkDisabled: true }}
              expandableRowsComponent={<DirectoryDetails />}
              expandOnRowClicked={true}
              columns={columnsMobile}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              customStyles={customStyles}
              striped={true}
              highlightOnHover={true}
            />
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Directory;
