import React, { useEffect } from "react";
import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonLabel,
    IonInput,
    IonTextarea
} from '@ionic/react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Http } from '@capacitor-community/http';
import './NewDiscussion.css'
type ModalProps = {

    title: string;
    isOpen: boolean;
    closeAction: Function;
};
const NewDiscussion: React.FC<ModalProps> = (props) => {
    const { title, isOpen, closeAction } = props;
    const {
        handleSubmit,
        register,
        getValues,
        setValue,
        formState: { errors },
    } =
        useForm({
            defaultValues: {
                discussionTopic: '',
                discussionDescription: ''
            },
            mode: 'onBlur',
        });
    const onSubmit = async (data: any) => {
        closeAction()
    }

    useEffect(() => {
    }, [errors]);
    return (<IonModal isOpen={isOpen} >
        <IonHeader >
            <IonToolbar>
                <IonTitle>{title}</IonTitle>
                <IonButtons slot='end'>
                    <IonButton onClick={() => closeAction()}>
                        <IonIcon name='close' slot='icon-only'></IonIcon>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding' >
            <div className="new-discussion-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-input-group'>
                        <IonLabel className='form-input-label'>Discussion Topic <span></span></IonLabel>
                        <IonInput
                            {...register('discussionTopic', {
                                required: 'This is required',
                            })}
                            type='text'
                            placeholder='Topic'
                            className='form-input'
                            mode="md"
                        ></IonInput>
                        <ErrorMessage
                            errors={errors}
                            name='discussionTopic'
                            as={<div className='error-message' />}
                        />
                    </div>

                    <div className='form-input-group'>
                        <IonLabel className='form-input-label'>Description<span></span></IonLabel>
                        <IonTextarea
                        mode="md"
                            className='form-input'
                            {...register('discussionDescription', {
                                required: 'This is required',
                            })}
                            placeholder='Type Something'
                            rows={5}
                        ></IonTextarea>
                        <ErrorMessage
                            errors={errors}
                            name='discussionDescription'
                            as={<div className='error-message' />}
                        />
                    </div>
                    <div className="submit-button"><IonButton mode="md" type="submit">Submit</IonButton></div>
                </form>
            </div>
        </IonContent>
    </IonModal>)
}

export default NewDiscussion;