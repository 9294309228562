import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonCardTitle,
  IonItem
} from "@ionic/react";
import { Http } from "@capacitor-community/http";
import "./BoardofDirectors.css";
import Header from "../../components/Header/Header";
import { isDesktop, isTablet, isIOS } from '../../Constants/general'
import { authActions } from './../../store/auth'
import { useDispatch } from "react-redux";
import { get } from "./../../storage/storage";


interface apiDataState {
  hb_id: string,
  hb_hoa_id: string,
  hb_first_name: string,
  hb_last_name: string,
  hb_position: string,
  hb_email: string,
  hb_description: string,
  hb_image: string,
  hb_phone_number: string,
  hb_order: 1
}
const BoardofDirectors: React.FC = () => {

  var [apiData, setApiData] = useState<apiDataState[]>([]);
  const [hoaId,setHoaId]= useState('');
  const dispatch = useDispatch();
  
  const fetchBoardMembers = async () => {
    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/boardmembers?hoa_id=${await get('loginUserHoaId')}`;
    const options = {
      url: url,
      headers: {
        authorization: `Bearer ${await get('token')}`
      },
    };
    const response = await Http.get(options);
    if (response.status != 200) {
      dispatch(authActions.logout());
      return;
    }
    setApiData(response.data.items);
  };
  useEffect(() => {
    (async ()=>{
      setHoaId(await get('loginUserHoaId'));
  })();
    fetchBoardMembers();
  }, [])
  return (
    <IonPage>
      <Header title='Board Of Directors' />
      <IonContent className="">
      <div className="filter-container" >
          <h3 slot="start" className="page-tile" > Board Of Directors </h3>
        </div>
        <div className="filterBottomLine hide-on-mobile"></div>
        <div className="boardOfDirectorContainer">
          <IonGrid>
            <IonRow>
              {apiData.map((member: any) =>
                <IonCol key={member.hb_id} size={(isDesktop) ? "auto" : (isTablet) ? "6" : "12"}>
                  <IonCard mode="md" className="boardOfDirector">
                    <div className="profile-image">
                      <img src={`${process.env.REACT_APP_HP_API_URL}/index.php/hoas/view-image?hoa_id=${hoaId}&image=${member.hb_image}`} />
                    </div>
                    <div>
                      <div className="bod-fullname">
                        {`${member.hb_first_name} ${member.hb_last_name}`}
                      </div>
                      <div className="bod-position">
                        {member.hb_position}<br />
                      </div>
                      <div className="bod-contact-info">
                        {member.hb_email}<br />
                        {member.hb_phone_number}<br />
                      </div>
                    </div>
                    <div className="boad-description">
                      {member.hb_description}
                    </div>
                  </IonCard>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default BoardofDirectors;
