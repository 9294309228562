/** */
import React, { useEffect, useState } from "react";

import {
  IonContent,
  IonPage,
  IonItem,
  IonRadioGroup,
  IonToggle,
  IonRadio,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton
} from "@ionic/react";
import "./Settings.css";
import Header from "../../components/Header/Header";
import { useDispatch } from 'react-redux'
import { menuActions } from '../../store/menuShowHide'
import DirectoryDetails from "./DirectoryDetails/DirectoryDetails";
import { Http } from '@capacitor-community/http';
import Modal from '../../components/Modal/Modal';
import checkedIcon from '../../assets/menu-icons/checkedIcon.svg';
import { isDesktop, isTablet } from '../../Constants/general'
import { authActions } from './../../store/auth'
import { get } from "./../../storage/storage";
import { returnUpBackOutline } from "ionicons/icons";


const Settings: React.FC = () => {
  const [showModal, setShowModal] = useState(false);


  const [successModal, setSuccessModal] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  
  const dispatch = useDispatch();
  const updaeState = () => {
    dispatch(menuActions.toggleMenu({ showMainMenu: false }));
  }

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [hpId, setHpId] = useState('');

  const [directoryEnabled, setDirectoryEnabled] = useState(false);
  const [messages, setMessages] = useState(false);
  const [documents, setDocuments] = useState(false);
  const [calendar, setCalendar] = useState(false);
  const [news, setNews] = useState(false);
  const [forms, setForms] = useState(false);
  const [groups, setGroups] = useState(false);
  const [forums, setForums] = useState(false);
  const [comments, setComments] = useState(false);
  const [forSale, setForSale] = useState(false);

  const fetchSettings = async () => {
    let url = `${process.env.REACT_APP_HP_API_URL}/index.php/auth/settings?propertyId=${await get('loginUserPropertyId')}`;
    const options = {
      url: url,
      headers: {
        authorization: `Bearer ${await get('token')}`
      },
    };
    const response = await Http.get(options);
    if (response.status != 200) {
      dispatch(authActions.logout());
      return;
    }
    setName(response.data.directoryModel.hpds_name)
    setEmail(response.data.directoryModel.hpds_email_address)
    setPhone(response.data.directoryModel.hpds_phone_number)
    setHpId(response.data.hoaPerson.hp_id)

    setDirectoryEnabled(!!response.data.directoryModel.hpds_directory_enabled)
    setMessages(!!response.data.userSettingsModel.uas_messages)
    setDocuments(!!response.data.userSettingsModel.uas_documents)
    setCalendar(!!response.data.userSettingsModel.uas_calendar)
    setNews(!!response.data.userSettingsModel.uas_news)
    setForms(!!response.data.userSettingsModel.uas_forms)
    setGroups(!!response.data.userSettingsModel.uas_groups)
    setForums(!!response.data.userSettingsModel.uas_forums)
    setComments(!!response.data.userSettingsModel.uas_comments)
    setForSale(!!response.data.userSettingsModel.uas_for_sale)
  };

  const onSubmit = async () => {
    let url = `${process.env.REACT_APP_HP_API_URL}/index.php/auth/settings-save?hp_id=${hpId}`;
    const options = {
      url: url,
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${await get('token')}`
      },
      data: {
        'HoaPersonDirectorySettings[hpds_directory_enabled]': Number(directoryEnabled).toString(),
        'UserAccountSettings[uas_messages]': Number(messages).toString(),
        'UserAccountSettings[uas_documents]': Number(documents).toString(),
        'UserAccountSettings[uas_calendar]': Number(calendar).toString(),
        'UserAccountSettings[uas_news]': Number(news).toString(),
        'UserAccountSettings[uas_forms]': Number(forms).toString(),
        'UserAccountSettings[uas_groups]': Number(groups).toString(),
        'UserAccountSettings[uas_forums]': Number(forums).toString(),
        'UserAccountSettings[uas_comments]': Number(comments).toString(),
        'UserAccountSettings[uas_for_sale]': Number(forSale).toString(),
      },
    };
    const response = await Http.post(options);
   
    if (response.status != 200) {
      dispatch(authActions.logout());
      return;
    }
    if (response.data.success) {
      fetchSettings();
      setSuccessModal(true);
      setTitle(response.data.messageHeading);
      setDescription(response.data.messageContent);
      setButtonText('Continue');
    } else {
      setSuccessModal(true);
      setTitle('Oops!');
      setDescription('Something Went Wrong');
      setButtonText('Try Again');
    }
  }

  const closeModal = async () => {
    await setShowModal(false);



  }
  const hidesuccessModal = async () => {
    await setSuccessModal(false);
  }
  const editDirectory = async () => {
    await setShowModal(true);
  }
  const includeProperty = (event: any) => {
    

  }

  useEffect(() => {
    fetchSettings();
    updaeState();
  }, [dispatch]);
  return (
    <IonPage>
      <Header title="Settings" />
      <IonContent className="">
        <IonItem className="filterContainer">
          <h3 slot="start" className="page-tile" > Settings</h3></IonItem>
        <div className="filterBottomLine hide-on-mobile"></div>

        <div className="settings-page">
          <div className="top-section">
            <h3 className="title">Directory Settings</h3>
            
            <div className="info">The Directory is only visible to other members of your community when logged into their Hoampage account. </div>
            <IonRadioGroup onIonChange={(e:any) => setDirectoryEnabled(e.detail.value) } value={directoryEnabled}>
              <IonItem>
                <IonRadio slot="start" value={true} mode="md" />
                <div className="radio-label">Include My Property in the Community Directory.</div>
              </IonItem>
              {directoryEnabled && <div className="setting-user-info">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      {/* <div className="user-edit-button" onClick={editDirectory}>Edit Directory Details</div> */}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size={(isDesktop && isTablet) ? '3' : '5'}><IonLabel>Name(s)</IonLabel></IonCol>
                    <IonCol size={(isDesktop && isTablet) ? '9' : '7'}>{name}</IonCol>
                    <IonCol size={(isDesktop && isTablet) ? '3' : '5'}><IonLabel>Phone Number</IonLabel></IonCol>
                    <IonCol size={(isDesktop && isTablet) ? '9' : '7'}>{phone}</IonCol>
                    <IonCol size={(isDesktop && isTablet) ? '3' : '5'}><IonLabel>Email Address</IonLabel></IonCol>
                    <IonCol size={(isDesktop && isTablet) ? '9' : '7'}>{email}</IonCol>
                  </IonRow>
                </IonGrid>
              </div>}

              <IonItem >
                <IonRadio slot="start" value={false} mode="md" />
                <div className="radio-label">DO NOT include My Property in the Community Directory.</div>
              </IonItem>
            </IonRadioGroup>
          </div>
          <div>
            <h3 className="title">Notifications</h3>
            <div className="notification-section">
              <div className="notification-sub-title">My Household Settings</div>
              <IonGrid className="notification-tables">
                <IonRow className="notification-table-head" >
                  <IonCol size-md="9" size="6" className="notification-first-head">Type </IonCol>
                  <IonCol size-md="3" size="6" className="notification-last-head">Email</IonCol>
                </IonRow >
                <IonRow className="notification-table-body">
                  <IonCol size-md="9" size="6" ><IonItem><div>New Message</div></IonItem></IonCol>
                  <IonCol size-md="3" size="6" ><div><IonItem>OFF<IonToggle mode="md" onIonChange={() => setMessages(!messages)} checked={messages} />ON</IonItem></div></IonCol>
                </IonRow>
              </IonGrid>
            </div>

            <div className="notification-section">
              <div className="notification-sub-title">My Community Settings</div>
              <IonGrid className="notification-tables">
                <IonRow className="notification-table-head">
                  <IonCol size-md="9" size="6" className="notification-first-head">Type </IonCol>
                  <IonCol size-md="3" size="6" className="">Email</IonCol>
                </IonRow >
                <IonRow className="notification-table-body">
                  <IonCol size-md="9" size="6" ><IonItem><div>Activity in Community Documents</div></IonItem></IonCol>
                  <IonCol size-md="3" size="6"><div><IonItem>OFF<IonToggle mode="md" onIonChange={() => setDocuments(!documents)} checked={documents} />ON</IonItem></div></IonCol>
                </IonRow>

                <IonRow className="notification-table-body">
                  <IonCol size-md="9" size="6" ><IonItem><div>Activity in Community Calendar</div></IonItem></IonCol>
                  <IonCol size-md="3" size="6" ><div><IonItem>OFF<IonToggle mode="md" onIonChange={() => setCalendar(!calendar)} checked={calendar} />ON</IonItem></div></IonCol>
                </IonRow>

                <IonRow className="notification-table-body">
                  <IonCol size-md="9" size="6" ><IonItem><div>Activity in Community News</div></IonItem></IonCol>
                  <IonCol size-md="3" size="6" ><div><IonItem>OFF<IonToggle mode="md" onIonChange={() => setNews(!news)} checked={news} />ON</IonItem></div></IonCol>
                </IonRow>

                <IonRow className="notification-table-body">
                  <IonCol size-md="9" size="6" ><IonItem><div>Activity in Community Forms	</div></IonItem></IonCol>
                  <IonCol size-md="3" size="6" ><div><IonItem>OFF<IonToggle mode="md" onIonChange={() => setForms(!forms)} checked={forms} />ON</IonItem></div></IonCol>
                </IonRow>
              </IonGrid>
            </div>

            <div className="notification-section">
              <div className="notification-sub-title">My Neighbors Settings</div>
              <IonGrid className="notification-tables">
                <IonRow className="notification-table-head" >
                  <IonCol size-md="9" size="6" className="notification-first-head">Type </IonCol>
                  <IonCol size-md="3" size="6" className="notification-last-head">Email</IonCol>
                </IonRow >
                <IonRow className="notification-table-body">
                  <IonCol size-md="9" size="6" ><IonItem><div>Activity in My Groups</div></IonItem></IonCol>
                  <IonCol size-md="3" size="6" ><div><IonItem>OFF<IonToggle mode="md" onIonChange={() => setGroups(!groups)} checked={groups} />ON</IonItem></div></IonCol>
                </IonRow>

                <IonRow className="notification-table-body">
                  <IonCol size-md="9" size="6" ><IonItem><div>Activity in Forums</div></IonItem></IonCol>
                  <IonCol size-md="3" size="6" ><div><IonItem>OFF<IonToggle mode="md" onIonChange={() => setForums(!forums)} checked={forums} />ON</IonItem></div></IonCol>
                </IonRow>

                <IonRow className="notification-table-body">
                  <IonCol size-md="9" size="6" ><IonItem><div>Comments/Replies to My Discussions/Comments</div></IonItem></IonCol>
                  <IonCol size-md="3" size="6" ><div><IonItem>OFF<IonToggle mode="md" onIonChange={() => setComments(!comments)} checked={comments} />ON</IonItem></div></IonCol>
                </IonRow>

                <IonRow className="notification-table-body">
                  <IonCol size-md="9" size="6" ><IonItem><div>Activity in For Sale</div></IonItem></IonCol>
                  <IonCol size-md="3" size="6" ><div><IonItem>OFF<IonToggle mode="md" onIonChange={() => setForSale(!forSale)} checked={forSale} />ON</IonItem></div></IonCol>
                </IonRow>
              </IonGrid>

            </div>
            <div className="notification-section">
              <div className="setting-button">
                <IonButton onClick={onSubmit}>Save Changes</IonButton>
              </div>
            </div>
          </div>
        </div>

        <DirectoryDetails
          isOpen={showModal}
          closeAction={closeModal}
          name={name}
          email={email}
          phone={phone}
          hpId={hpId}
        />

        <Modal
          logo={checkedIcon}
          title={title}
          description={description}
          buttonText={buttonText}
          isOpen={successModal}
          closeAction={hidesuccessModal}
        ></Modal>

      </IonContent>

    </IonPage>
  );
};

export default Settings;
