import React from 'react';
import {
  IonContent,
  IonButton,
  IonModal,
} from '@ionic/react';
import './Modal.css';

type ModalProps = {
  logo?: string;
  title: string;
  description: string;
  buttonText:string;
  isOpen: boolean;
  closeAction: Function;
  showCloseButton?: boolean,
  className?: string;
  secondaryButton?: string;
  secondaryButtonUrl?: string;
};

const LargeModal: React.FC<ModalProps> = (props) => {
  const { logo, title, description,buttonText, isOpen, closeAction, className, secondaryButton, secondaryButtonUrl, showCloseButton } = props;

  return (
    <IonModal isOpen={isOpen} cssClass={className ? className + ' large-modal-wrapper' : 'large-modal-wrapper'} mode="md" backdropDismiss={false}>
      <IonContent className='ion-padding' >
        <div className='modal-content'>
          {showCloseButton &&  <span className="close-button" onClick={() => closeAction(false)}>X</span>}
          {logo &&  <img src={logo} alt="" className="image-wrapper-modal" />}
          <div className="confirm-popup__title">{title}</div>
          <div className="confirm-popup__text">{description}</div>
          <div className="confirm-popup__buttons-wrapper">
            <IonButton className="confirm-popup__button" onClick={() => closeAction(true)}>{buttonText}</IonButton>
            {
              secondaryButton && 
                <div>
                  <a className="secondary-popup__button" rel="noreferrer" target="_blank" href={secondaryButtonUrl}>{secondaryButton}</a>
                </div>
            }
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};
export default LargeModal;
