import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './LoginWrapper.css';
import footerImage from '../../../assets/copyright.png';
import { Http } from "@capacitor-community/http";
import PolicyModal from '../policyModal/policyModal';

const LoginWrapper: React.FC<{ title?: String }> = ({ children, title }) => {
  const defaultTitle =
    'Log in to start building a stronger, more informed and connected community.';
  const [showModal, setShowModal] = useState(false);
  const [modaltitle, setModalTitle] = useState('');
  const [description, setDescription] = useState('');
  const [heading, setHeading] = useState('');
  
  const fetchTerms = async () => {

    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/auth/view-resource?resource=terms_of_use_page`;
    const options = {
      url: url,
      headers: { 'Content-Type': 'text/html' },
    };
    const response = await Http.get(options);
    if (response.status = 200) {
    setShowModal(true);
    setModalTitle('TERMS OF USE');
    setHeading('');
    setDescription(response.data.content);
    }

  };
  const fetchPolicy = async () => {

    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/auth/view-resource?resource=privacy_policy_page`;
    const options = {
      url: url,
      headers: { 'Content-Type': 'text/html' },
    };
    const response = await Http.get(options);
    if (response.status = 200) {
    setShowModal(true);
    setModalTitle('PRIVACY POLICY');
    setHeading('');
    setDescription(response.data.content);
    }
  };

  const hideModal = async () => {
    await setShowModal(false);
}

  return (
    <IonPage>
      <IonContent className='login-wrapper-container'>
        <div className=''>
          <IonGrid className='no-padding login-wrapper-main'>
            <IonRow className='login-wrapper-content'>
              <IonCol className='login-left no-padding'>
                <div className='image-wrapper-left'></div>
                <div className='image-overlay-left'></div>
                <div className='login-text-wrapper '>
                  <h2>Homeowners and HOAs, working together.</h2>
                  <h3>{title ? title : defaultTitle}</h3>
                </div>
              </IonCol>
              <IonCol className='login-right'>
                <img alt="HOAMPAGE" src="/images/common/login-blue-logo.png" className='login-logo-image' />
                {children}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <div className='login-public-footer'>
          <div className='login-footer-links'>
            <a  className="btn-tertiary btn-tertiary--white" title='Terms of Use' onClick={fetchTerms} >
              Terms of Use
            </a>{' '}
            <a target="_blank" className="btn-tertiary btn-tertiary--white" title='Privacy Policy' onClick={fetchPolicy}>
              Privacy Policy
            </a>
          </div>
          <div className='login-copyright'>
            <div>
              <img src={footerImage} />
            </div>
            <div>Powered by Page Per Page | © {new Date().getFullYear()}</div>
          </div>
        </div>
      </IonContent>
      

      <PolicyModal
    title={modaltitle}
    heading={heading}
    description={description}
    isOpen={showModal}
    closeAction={hideModal} />
    </IonPage>

    
    
  );
};

export default LoginWrapper;
