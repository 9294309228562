import React, { useState, useEffect } from 'react';
import {
    IonButton,
    IonSearchbar,
    IonSelectOption,
    IonSelect,
    IonItem,
    IonContent,
    IonPage,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardContent,
    isPlatform
} from '@ionic/react';
import './AllListings.css';
import Header from '../../../components/Header/Header';
import { Http } from "@capacitor-community/http";
import { searchIcon, refreshIcon, convetDateToString } from '../../../Constants/general'
import { authActions } from './../../../store/auth'
import { useDispatch } from "react-redux";
import NewListings from '../NewListings/NewListings'
import Modal from '../../../components/Modal/Modal';
import plusIcon from '../../../assets/menu-icons/plusIcon.svg';
import { get } from '../../../storage/storage';
interface allListingState {
    hl_id: string,
    hl_hoa_id: string,
    hl_category_id: string,
    hl_title: string,
    hl_price: string,
    hl_description: string,
    hl_owner_id: string,
    hl_contact_email: string,
    hl_contact_phone: string,
    hl_callable: string,
    hl_textible: string,
    hl_created_time: string,
    hl_updated_time: string,
    hl_active: string,
    hl_deleted: string,
    image: string,
    listedOn: string,
    ownerName: string
}
const AllListings: React.FC = () => {

    const [filterBy, setFilterBy] = useState('');
    const [filterText, setFilterText] = useState('');
    const [filterError, setFilterError] = useState(false);
    const [isDesktop, setDesktop] = useState(false);
    const [isTablet, setTablet] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [isIos,setIsIos] = useState(false);
    const [allListingData, setAllListingData] = useState<allListingState[]>([])
    const [showModal, setShowModal] = useState(false);

    const [successModal, setSuccessModal] = useState(false);
    const [successButton, setButtonText] = useState('');
    const [successtitle, setSuccessTitle] = useState('');
    const [successDescription, setSuccessDescription] = useState('');
    const dispatch = useDispatch();
    
    const fetchAllListing = async () => {

        let searchText = (filterText) ? `&HoaListingsSearch[${filterBy}]=${filterText}` : '';
        let url = `${process.env.REACT_APP_HP_API_URL}/index.php/listings/index?hoa_id=${await get('loginUserHoaId')}${searchText}`;
        const options = {
            url: url,
            headers: {
                authorization: `Bearer ${await get('token')}`
            },
        };
        const response = await Http.get(options);
        if (response.status != 200) {
            dispatch(authActions.logout());
            return;
        }
        setAllListingData(response.data.items);

    };


    const seacrGroup = (e: any) => {
        e.preventDefault();
        if (!filterBy) {
            setFilterError(true);
            return
        }
        fetchAllListing()
    }

    const closeModal = async () => {
        await setShowModal(false);

        setSuccessModal(true);
        setSuccessTitle('DONE!');
        setSuccessDescription('Your listing has been added successfully!');
        setButtonText('Continue');

    }
    const hidesuccessModal = async () => {
        await setSuccessModal(false);
    }

    useEffect(() => {
        fetchAllListing();
        if (isPlatform("desktop")) {
            setDesktop(true);
        }
        if (isPlatform("tablet")) {
            setTablet(true)
        }
        if (isPlatform("ios")) {
            setIsIos(true);
          }
        setFilterBy('')
        setFilterText('')
    }, [refresh]);
    return (

        <IonPage >
            <Header title="All Listings" />
            <IonContent >
            <div className="filter-container alllisting-filter" >
                <h3  className="page-tile" >
                        For Sale | All Listings
                    </h3>

                    <form onSubmit={seacrGroup}   className="filtet-form">
                        <div className="filter-wrapper">
                        <img className="refresh-page" src={refreshIcon} onClick={() => setRefresh(!refresh)} />
                        <div className="filter-type-wrapper">
                            <select className="filter-type" name="filter_by" value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
                                <option value="">&nbsp;   Filter By</option>
                                <option value="hl_title">&nbsp; Title</option>
                                <option value="hl_category_id">&nbsp; Category</option>
                                <option value="hl_description">&nbsp; Description</option>

                            </select>
                        </div>
                        <div className={(isIos)?'filter-input-wrapper filter-input-wrapper-ios' :'filter-input-wrapper filter-input-wrapper-android'}>
                                <button type="submit"> <img src={searchIcon} /> </button>
                                <input type="text" className="filter-input" value={filterText} name="filter_text" onChange={(e) => setFilterText(e.target.value)} placeholder="&nbsp; Search" />
                            </div>
                            {/* {(isDesktop || isTablet) && <IonButton size="default" slot="end" onClick={() => setShowModal(true)}>New Listing</IonButton>} */}
                        </div>
                        {filterError && <div className="filter-error-msg">Please select column to filter by</div>}
                    </form>
                   
                </div>
<br />
                <div className="filterBottomLine"></div>
                {(!isDesktop && !isTablet) && <IonItem>
                    {/* <IonButton size="default" slot="end" onClick={() => setShowModal(true)}>New Listing</IonButton> */}
                </IonItem>}
                <div className="all-listings-section">
                <IonGrid>
                    <IonRow>
                        {allListingData.map((listing) =>
                            
                            <IonCol key={listing.hl_id} size={(isDesktop) ? 'auto' : (isTablet) ? '5' : '12'}>
                                <IonCard className="listing-card">
                                    <div  className="listingImage"> <img src={`${process.env.REACT_APP_HP_API_URL}/index.php/auth/view-listing-image?image=${listing.image}&listing=${listing.hl_id}`} alt="" /></div>
                                    <IonCardHeader>
                                        <div className="listingTitle">{listing.hl_title}</div>
                                        <div className="listingPrice">${listing.hl_price.replace(/\.00$/, '')}</div>
                                        <IonCardContent>
                                            <div className="listingInfo">
                                                <div>{listing.listedOn}</div>
                                                <div>{listing.ownerName}</div>
                                                <IonButton href="/ForSales/listingDetails/12">View Listing</IonButton>
                                            </div>
                                        </IonCardContent>
                                    </IonCardHeader>
                                </IonCard>
                            </IonCol>

                        )}

                    </IonRow>
                </IonGrid>
                </div>
            </IonContent >
            <NewListings
                title="New Listing"
                isOpen={showModal}
                closeAction={closeModal}
            />
            <Modal
                logo={plusIcon}
                title={successtitle}
                description={successDescription}
                buttonText={successButton}
                isOpen={successModal}
                closeAction={hidesuccessModal}
            ></Modal>
        </IonPage >

    );
}


export default AllListings;