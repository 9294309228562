import React, { useEffect } from "react";
import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonLabel,
    IonInput,
    IonTextarea,
    IonSelect,
    IonSelectOption
} from '@ionic/react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Http } from '@capacitor-community/http';
import './NewListings.css'
type ModalProps = {

    title: string;
    isOpen: boolean;
    closeAction: Function;
};
const NewListings: React.FC<ModalProps> = (props) => {
    const { title, isOpen, closeAction } = props;
    
    const {
        handleSubmit,
        register,
        getValues,
        setValue,
        formState: { errors },
    } =
        useForm({
            defaultValues: {
                listingTitle: '',
                listingCategory: '',
                listingPrice: '',
                listingDescription: '',
                listingContactEmail: '',
                listingContactPhone: ''
            },
            mode: 'onBlur',
        });
    const onSubmit = async (data: any) => {
        closeAction()
    }

    useEffect(() => {
    }, [errors]);
    return (<IonModal mode="md" isOpen={isOpen} cssClass="new-listing-modal">
        <IonHeader >
            <IonToolbar>
                <IonTitle>{title}</IonTitle>
                <IonButtons slot='end'>
                    <IonButton onClick={() => closeAction()}>
                        <IonIcon name='close' slot='icon-only'></IonIcon>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding' >
            <div className="new-listing-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-input-group'>
                        <IonLabel className='form-input-label'>Title<span></span></IonLabel>
                        <IonInput
                            {...register('listingTitle', {
                                required: 'This is required',
                            })}
                            type='text'
                            placeholder='Title'
                            className='form-input'
                            mode="md"
                        ></IonInput>
                        <ErrorMessage
                            errors={errors}
                            name='listingTitle'
                            as={<div className='error-message' />}
                        />
                    </div>
                    <div className="form-input-group-name">
                        <div className="form-input-group">
                        <IonLabel className='form-input-label'>Category<span></span></IonLabel>
                            <IonSelect
                                mode="md"
                                interface="popover"
                                placeholder="&nbsp; Select one"
                                className='form-input'
                                {...register('listingCategory', {
                                    required: 'You must select one Category',
                                })}
                            >
                                <IonSelectOption value="hl_title"> &nbsp; Appliances & Electronics</IonSelectOption>
                                <IonSelectOption value="hl_category_id"> &nbsp; Auto & Transportation</IonSelectOption>
                                <IonSelectOption value="hl_description"> &nbsp; General</IonSelectOption>
                                <IonSelectOption value="hl_description"> &nbsp; Housing & Decor</IonSelectOption>
                            </IonSelect>
                            <ErrorMessage
                                errors={errors}
                                name='listingCategory'
                                as={<div className='error-message' />}
                            />
                        </div>
                        <div className='form-input-group'>
                            <IonLabel className='form-input-label'>Price<span></span></IonLabel>
                            <IonInput
                                {...register('listingPrice', {
                                    required: 'This is required',
                                })}
                                type='text'
                                placeholder='Price'
                                className='form-input'
                                mode="md"
                            ></IonInput>
                            <ErrorMessage
                                errors={errors}
                                name='listingPrice'
                                as={<div className='error-message' />}
                            />
                        </div>

                    </div>

                    <div className='form-input-group'>
                        <IonLabel className='form-input-label'>Description<span></span></IonLabel>
                        <IonTextarea
                            className='form-input'
                            {...register('listingDescription', {
                                required: 'This is required',
                            })}
                            placeholder='Type Something'
                            rows={5}
                            mode="md"
                        ></IonTextarea>
                        <ErrorMessage
                            errors={errors}
                            name='listingDescription'
                            as={<div className='error-message' />}
                        />
                    </div>

                    <div className="form-input-group-name">
                        <div className='form-input-group'>
                            <IonLabel className='form-input-label'>Contact Email Address<span></span></IonLabel>
                            <IonInput
                               
                                {...register('listingContactEmail', {
                                    required: 'This is required',
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                      message: 'Invalid email address',
                                    },
                                  })}
                                type='text'
                                placeholder='Contact Email Address'
                                className='form-input'
                                mode="md"
                            ></IonInput>
                            <ErrorMessage
                                errors={errors}
                                name='listingContactEmail'
                                as={<div className='error-message' />}
                            />
                        </div>

                        <div className='form-input-group'>
                            <IonLabel className='form-input-label'>Contact Phone Number <span></span></IonLabel>
                            <IonInput
                                {...register('listingContactPhone', {
                                    required: 'This is required',
                                })}
                                type='text'
                                placeholder='Contact Phone Number'
                                className='form-input'
                                mode="md"
                            ></IonInput>
                            <ErrorMessage
                                errors={errors}
                                name='listingContactPhone'
                                as={<div className='error-message' />}
                            />
                        </div>

                    </div>
                    <div className="submit-button"><IonButton mode="md" type="submit">Submit</IonButton></div>
                </form>
            </div>
        </IonContent>
    </IonModal>)
}

export default NewListings;