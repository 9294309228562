
import React, { useState, useEffect } from 'react';
import {
    IonButton,
    IonSearchbar,
    IonSelectOption,
    IonSelect,
    IonItem,
    IonContent,
    IonPage,
    IonIcon,
    isPlatform
} from '@ionic/react';
import { Http } from "@capacitor-community/http";
import DataTable, { createTheme, defaultThemes } from "react-data-table-component";
import { customStyles, columnsDesktop, columnsMobile } from './Constants'
import { searchIcon, refreshIcon } from '../../../Constants/general'

import './MyListings.css';
import Header from '../../../components/Header/Header';
import salesIcon from "../../../assets/menu-icons/sales-icon.svg";
import MyListingDetail from './MyListingDetail';
import { authActions } from './../../../store/auth'
import { useDispatch } from "react-redux";

import NewListings from '../NewListings/NewListings';
import Modal from '../../../components/Modal/Modal';
import plusIcon from '../../../assets/menu-icons/plusIcon.svg';
import { get } from '../../../storage/storage';

const MyListings: React.FC = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [filterBy, setFilterBy] = useState('');
    const [filterText, setFilterText] = useState('');
    const [filterError, setFilterError] = useState(false);
    const [isDesktop, setDesktop] = useState(false);
    const [isTablet, setTablet] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [isIos, setIsIos] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const [successModal, setSuccessModal] = useState(false);
    const [successButton, setButtonText] = useState('');
    const [successtitle, setSuccessTitle] = useState('');
    const [successDescription, setSuccessDescription] = useState('');
    

    const dispatch = useDispatch();

    const fetchMyListing = async (page: any) => {
        setLoading(true);
        let searchText = (filterText) ? `&HoaMyListingsSearch[${filterBy}]=${filterText}` : '';
        let url = `${process.env.REACT_APP_HP_API_URL}/index.php/listings/my-listings?hoa_id=${await get('loginUserHoaId')}&page=${page}${searchText}`;
        const options = {
            url: url,
            headers: {
                authorization: `Bearer ${await get('token')}`
            },
        };
        const response = await Http.get(options);
        if (response.status != 200) {
            dispatch(authActions.logout());
            return;
        }
        setData(response.data.items);

        setTotalRows(response.data._meta.totalCount);
        setLoading(false);
    };
    const handlePageChange = (page: any) => {
        fetchMyListing(page);
    };

    const seacrGroup = (e: any) => {
        e.preventDefault();
        if (!filterBy) {
            setFilterError(true);
            return
        }
        setFilterError(false);
        fetchMyListing(1);
    }

    const closeModal = async () => {
        await setShowModal(false);

        setSuccessModal(true);
        setSuccessTitle('DONE!');
        setSuccessDescription('Your listing has been added successfully!');
        setButtonText('Continue');

    }
    const hidesuccessModal = async () => {
        await setSuccessModal(false);
    }

    useEffect(() => {
        fetchMyListing(1);
        if (isPlatform("desktop")) {
            setDesktop(true);
        }
        if (isPlatform("tablet")) {
            setTablet(true)
        }
        if (isPlatform("ios")) {
            setIsIos(true);
        }
        setFilterBy('')
        setFilterText('')
    }, [refresh]);

    return (

        <IonPage >
            <Header title="My Listings" />
            <IonContent >
                <div className="filter-container mylisting-filter" >
                    <h3  className="page-tile" >
                        For Sale | My Listings
                    </h3>

                    <form onSubmit={seacrGroup}  className="filtet-form">
                        <div className="filter-wrapper">
                            <img className="refresh-page" src={refreshIcon} onClick={() => setRefresh(!refresh)} />
                            <div className="filter-type-wrapper">
                                <select className="filter-type" name="filter_by" value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
                                    <option value="">&nbsp;   Filter By</option>
                                    <option value="hd_title">&nbsp; Title</option>
                                    <option value="hl_category_id">&nbsp; Category</option>
                                    <option value="hl_description">&nbsp; Description</option>

                                </select>
                            </div>
                            <div className={(isIos) ? 'filter-input-wrapper filter-input-wrapper-ios' : 'filter-input-wrapper filter-input-wrapper-androin'}>
                                <button type="submit"> <img src={searchIcon} /> </button>
                                <input type="text" className="filter-input" value={filterText} name="filter_text" onChange={(e) => setFilterText(e.target.value)} placeholder="&nbsp; Search" />
                            </div>
                            {(isDesktop || isTablet) && <IonButton mode="md" size="default" slot="end" onClick={() => setShowModal(true)} >New Listing</IonButton>}
                        </div>
                        {filterError && <div className="filter-error-msg">Please select column to filter by</div>}
                    </form>

                </div>
<br />
                <div className="filterBottomLine"></div>
                {(!isDesktop && !isTablet) && <IonItem>
                    <IonButton mode="md" size="default" slot="end" onClick={() => setShowModal(true)} >New Listing</IonButton>
                </IonItem>}
                <div className="myListingTable">
                    <DataTable
                        columns={(isDesktop || isTablet) ? columnsDesktop : columnsMobile}
                        data={data}
                        progressPending={loading}
                        expandableRows={true}
                        selectableRowsComponentProps={{ inkDisabled: true }}
                        expandableRowsComponent={<MyListingDetail />}
                        expandOnRowClicked={true}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        customStyles={customStyles}
                        striped={true}
                        highlightOnHover={true}
                    />
                </div>
                {!data.length &&
                    <div className="noListingContainer">
                        <div className="noListingImage"><img src={salesIcon} alt="" /></div>
                        <div className="noListingTitle">START SELLING!</div>
                        <div className="noListingText">
                            <div>Looks like you haven’t listed anything for sale, yet!<br />
                                Creating a listing is simple. Give it a try!</div>
                            <IonButton>New Listing</IonButton>
                        </div>

                    </div>
                }


            </IonContent >

            <NewListings
                title="New Listing"
                isOpen={showModal}
                closeAction={closeModal}
            />
            <Modal
                logo={plusIcon}
                title={successtitle}
                description={successDescription}
                buttonText={successButton}
                isOpen={successModal}
                closeAction={hidesuccessModal}
            ></Modal>
        </IonPage >

    );
}


export default MyListings;