import React from "react";
import { IonCard, IonCardContent } from "@ionic/react"
import "./News.css";

const NewsDetails = (data :any) => {
    return(
        <IonCard className="newsDetailContainer">
            <IonCardContent>
                <h2>Body</h2>
                <div>{data.data.hn_body.replace(/<[^>]*>?/gm, '')} </div> {/*remove html tags */}
                <h2>Created By</h2>
                <div>{data.data.hn_createdByName}</div>
            </IonCardContent>
        </IonCard>
    )
}

export default NewsDetails;