
import React, { useState, useEffect } from "react";
import {
    IonContent,
    IonPage,
    isPlatform,
    IonItem,
    IonButton,
} from "@ionic/react";
import { Http } from "@capacitor-community/http";
import DataTable, { createTheme, defaultThemes } from "react-data-table-component";
import './Members.css';
import Header from '../../components/Header/Header';
import { customStyles, columnsDesktop, columnsMobile } from './Constants'
import { menuActions } from '../../store/menuShowHide'
import { searchIcon, refreshIcon } from '../../Constants/general'
import { authActions } from './../../store/auth'
import { useDispatch } from "react-redux";
import MemberDetails from "./MemberDetails";
import { get } from "./../../storage/storage";

const Members: React.FC = () => {
    const dispatch = useDispatch();
    const updaeState = () => {
        dispatch(menuActions.toggleMenu({ showMainMenu: false }));
    }
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [isDesktop, setDesktop] = useState(false);
    const [isTablet, setTablet] = useState(false);
   

    const fetchMembers = async (page: any) => {
        setLoading(true);
        const url = `${process.env.REACT_APP_HP_API_URL}/index.php/hoa-person?hoa_id=${await get('loginUserHoaId')}&hh_id=${await get('loginUserPropertyId')}&page=${page}`;
        const options = {
            url: url,
            headers: {
                authorization: `Bearer ${await get('token')}`
            },
        };
        const response = await Http.get(options);
        if (response.status != 200) {
            dispatch(authActions.logout());
            return;
        }
        setData(response.data.items);
        setTotalRows(response.data._meta.totalCount);
        setLoading(false);
    };
    const handlePageChange = (page: any) => {
        fetchMembers(page);
    };
    useEffect(() => {
        fetchMembers(1);
        updaeState();
        if (isPlatform("desktop")) {
            setDesktop(true);
        }
        if (isPlatform("tablet")) {
            setTablet(true)
        }
      
    }, [dispatch]);

    return (

        <IonPage >
            <Header title={(!isDesktop && !isTablet) ? 'Members' : ''} />
            <IonContent >
                <IonItem className="filterContainer">
                    {(isDesktop || isTablet) && <h3 slot="start" className="page-tile" >
                        Members
                    </h3>}
                    {/* <IonButton size="default" slot="end">Add Members</IonButton> */}
                </IonItem>
                <div className="filterBottomLine"></div>
                <div className="members-tabe">
                    <DataTable
                        columns={(isDesktop || isTablet) ? columnsDesktop : columnsMobile}
                        data={data}
                        progressPending={loading}
                        expandableRows={true}
                        selectableRowsComponentProps={{ inkDisabled: true }}
                        expandableRowsComponent={<MemberDetails />}
                        expandOnRowClicked={true}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        customStyles={customStyles}
                        striped={true}
                        highlightOnHover={true}
                    />
                </div>
            </IonContent >
        </IonPage >

    );
}


export default Members;