/*
* 
*
* 
*
* 
* 
*/
import React from 'react';
import { IonContent, IonPage} from '@ionic/react';
import './Household.css';
import Header from '../../components/Header/Header';
const Household: React.FC = ()=>{
       
        return (
          
                <IonPage >
                    <Header title="Household" />

                    <IonContent className='cameraContent'>
                        <h1 className="ion-text-center">Household</h1>
                    </IonContent >
                </IonPage >
          
        );
    }


export default Household;