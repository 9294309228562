import React, { useEffect, useState, useRef } from "react";
import {
  IonButton,
  IonInput,
  IonLabel,
  IonRouterLink,
  IonText,
} from "@ionic/react";
import "./Forgot.css";
import { Http } from "@capacitor-community/http";
import { useHistory } from "react-router";
import LargeModal from "../../../components/Modal/LargeModal";
import LoginWrapper from "../LoginWrapper/LoginWrapper";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";

const Forgot: React.FC = () => {
  const history = useHistory();
  const [serverErrors, setServerErrors] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const emailRef = useRef<HTMLIonInputElement>(null);
  async function closeModal() {
    // await setShowModal(false); nu mai trebuie folosit deoarece va da memory leek oricum nu mai e nevoie deoparece se navigheaza la alt page
    history.replace("/login");
  }

  const onSubmit = async (data: any) => {
    setShowModal(false);
    if (!emailRef.current?.value) {

      setServerErrors('This filed is required.');
    }
    if (emailRef.current?.value) {
      const options = {
        url: process.env.REACT_APP_HP_API_URL + "/index.php/auth/forgot-password",
        headers: { "Content-Type": "multipart/form-data" },
        data: {'user_email':emailRef.current?.value},
      };
      const response = await Http.post(options);
      if (response.status == 200) {
        if (!response.data.success) {
          setServerErrors(response.data.message);
          return
        }
        setTitle("DONE!");
        setDescription(
          "The password reset request has been received, please check your e-mail and folow the instructions"
        );
        setButtonText("Return to Login Page");
        setShowModal(true);
      } else {
        setServerErrors(response.data.message);
      }
    }

  };

  return (
    <LoginWrapper>
      <div className="form-forgot">
        <div className="forgot-main-content">
          <div className="heading--blue">Reset your password</div>
          <div className="login-email">
            <IonLabel className="control-label label--required">Email Address</IonLabel>
            <IonInput
              className={
                serverErrors ? "login-input-styles-error" : "login-input-styles"
              }
              type="text"
              placeholder="Email Address"
              ref={emailRef}
              onIonChange={() => setServerErrors('')}
            />
            {serverErrors && <ErrorMessage description={serverErrors} />}
          </div>
          <div className="login-sub-text-5">
            <div className="login-button">
              <IonButton expand="block" type="button" onClick={onSubmit}>
                Request Reset Link
              </IonButton>
            </div>
            <div className="login-forgot">
              <IonRouterLink routerLink="/Login">
                <IonText>Back to Login</IonText>
              </IonRouterLink>
            </div>

          </div>
        </div>
      </div>
      <LargeModal
        logo={"/images/common/house-logo.svg"}
        title={title}
        description={description}
        buttonText={buttonText}
        isOpen={showModal}
        closeAction={closeModal}
      ></LargeModal>
    </LoginWrapper>
  );
};

export default Forgot;
