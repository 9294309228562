
import React, { useState, useEffect } from "react";
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonContent,
    IonGrid,
    IonItem,
    IonPage,
    IonRow,
} from "@ionic/react";
import "./UsefulLinks.css";
import Header from "../../components/Header/Header";
const UsefulLinks: React.FC = () => {
  
    return (
        <IonPage>
            <Header title="Useful Links" />
            <IonContent className="cameraContent">
            <div className="filter-container" >
                    <h3 slot="start" className="page-tile" >
                        Useful Links
                    </h3></div>
                <div className="filterBottomLine hide-on-mobile"></div>
                <div className="usefull-links-container">
                <IonGrid>
                    <IonRow className="">
                        <IonCol size="auto" >
                            <IonCard>
                                <IonCardHeader>
                                    <h3 className="usefullLinkHeadings"> SCHOOLS</h3>
                                </IonCardHeader>
                                <IonCardContent>

                                    <p><a href="">CHANDLER HIGH SCHOOL</a> </p>
                                    <p><a href="">CHANDLER HIGH SCHOOL</a> </p>
                                    <p><a href="">CHANDLER HIGH SCHOOL</a> </p>
                                    <p><a href="">CHANDLER HIGH SCHOOL</a> </p>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="auto">
                            <IonCard>
                                <IonCardHeader>
                                    <h3 className="usefullLinkHeadings"> MUNICIPALITIES</h3>
                                </IonCardHeader>
                                <IonCardContent>

                                    <p><a href="">CHANDLER HIGH SCHOOL</a> </p>
                                    <p><a href="">CHANDLER HIGH SCHOOL</a> </p>
                                    <p><a href="">CHANDLER HIGH SCHOOL</a> </p>
                                    <p><a href="">CHANDLER HIGH SCHOOL</a> </p>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                </div>
                

            </IonContent>
        </IonPage>
    );
};

export default UsefulLinks;
