export const customStyles = {
    headRow: {
      style: {
        background: "#606163",
        paddingTop: "12px",
        paddingBottom: "12px"
      },
    },
    headCells: {
      style: {
        color: "#ffffff",
        fontSize: "18px",
        fontWeight: "500",
        pointerEvents: "none"
      },
    },
  
    rows: {
      style: {
        backgroundColor: '#ffffff',
        borderBottomColor: '#ffffff',
        // outline: '1px solid #9d9fa6',
        paddingTop: "8px",
        paddingBottom: "8px",textDecoration:"none"
      },
    },
    cells:{
      style:{
        textDecoration: "none",
        color: "#7c7d7f",
        fontSize: "16px",
      }
  },
    highlightOnHoverStyle: {
      color: "#fffff",
    }
    
  };

  export var columnsDesktop = [
    {
      name: "DISCUSSION",
      cell: (row: any) => <a href="/Forums/Threads/1" className="forumDiscussionlink">{row.ft_subject}</a>,
      // sortable: true,
    },
    {
      name: "POSTED BY",
      selector: (row: any) => <a href="/Forums/Threads/2" className="forumDiscussionlink" >{row.posted_by}</a>,
      // sortable: true,
    },
    {
        name: "LAST ACTIVITY",
        selector: (row: any) => <a href="/Forums/Threads/3"  className="forumDiscussionlink">{row.last_activity}</a>,
        // sortable: true,
      }
  ];
 

  