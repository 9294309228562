export const customStyles = {
    headRow: {
      style: {
        background: "#606163",
        paddingTop: "8px",
        paddingBottom: "8px"
      },
    },
    headCells: {
      style: {
        color: "#ffffff",
        fontSize: "16px",
        fontWeight: "100",
        pointerEvents: "none"
      },
    },
  
    rows: {
      style: {
        backgroundColor: '#ffffff',
        borderBottomColor: '#ffffff',
        // outline: '1px solid #9d9fa6',
        paddingTop: "8px",
        paddingBottom: "8px",
       
      },
    },
    cells:{
      style:{
        color:"#3a3a3a",
        fontSize:"16px"
      }
    },
    highlightOnHoverStyle: {
      color: "#fffff",
    },
  };

  export var columnsDesktop = [
    {
      name: "TITLE",
      cell: (row: any) => row.hl_title,
      // sortable: true,
    },
    {
      name: "PRICE",
      selector: (row: any) => `$${row.hl_price.replace(/\.00$/,'')}`,
      // sortable: true,
    },
    {
      name: "CATEGORY",
      selector: (row: any) => row.hl_category_id,
      // sortable: true,
    },
    {
      name: "DATE",
      selector: (row: any) => row.listedOn,
      // sortable: true,
    }
  ];
  export var columnsMobile = [
    {
      name: "TITLE",
      cell: (row: any) => row.hl_title,
      // sortable: true,
    },
  ];

  