
import React, { useState, useEffect } from 'react';
import {
    IonItem,
    IonContent,
    IonPage,
    IonList,
    IonIcon,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
} from '@ionic/react';
import Header from '../../components/Header/Header'
import starIcon from '../../assets/menu-icons/starIcon.svg'
import './Calendar.css';
import { Http } from "@capacitor-community/http";
import { convetDateToString, timeConvert, isDesktop, isTablet, isIOS} from '../../Constants/general'
import { authActions } from './../../store/auth'
import { useDispatch } from "react-redux";
import { get } from "./../../storage/storage";

interface eventState {
    ce_id: string,
    ce_title: string,
    ce_start_date: string,
    ce_start_time: string,
    ce_end_date: string,
    ce_end_time: string,
    ce_category: string,
    ce_group_id: string,
    ce_hoa_id: string,
    ce_description: string,
    ce_location: string,
    ce_deleted: string,
    ce_created_by: string
}
const eventDetailState = {
    ce_id: '',
    ce_title: '',
    ce_start_date: '',
    ce_start_time: '',
    ce_end_date: '',
    ce_end_time: '',
    ce_category: '',
    ce_group_id: '',
    ce_hoa_id: '',
    ce_description: '',
    ce_location: '',
    ce_deleted: '',
    ce_created_by: ''
}
const Calendar: React.FC = () => {
    const [upcomingEvents, setUpcomingEvents] = useState<eventState[]>([]);
    const [eventDetails, setEventDetails] = useState(eventDetailState);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    
    // show data for mobile view
    const fetchUpcomingEvents = async () => {
        const url = `${process.env.REACT_APP_HP_API_URL}/index.php/hoacalendarevents/get-upcoming-events?hoa_id=${await get('loginUserHoaId')}`;
        const options = {
            url: url,
            headers: {
                authorization: `Bearer ${await get('token')}`
            },
        };
        const response = await Http.get(options);
        if (response.status != 200) {
            dispatch(authActions.logout());
            return;
          }
        let alterResponse = [];
        for (let i = 0; i < response.data.length; i++) {
            const aObject = response.data[i];
            aObject.ce_start_date = convetDateToString(aObject.ce_start_date);
            aObject.ce_start_time = timeConvert(aObject.ce_start_time)
            aObject.ce_end_time = timeConvert(aObject.ce_start_time)
            alterResponse.push(aObject)
        }
        setUpcomingEvents(alterResponse);
    };

    const fetchEventDetails = async (id: any) => {
        const url = `${process.env.REACT_APP_HP_API_URL}/index.php/hoacalendarevents/get-calendar-event-details?id=${id}`;
        const options = {
            url: url,
            headers: {
                authorization: `Bearer ${await get('token')}`
            },
        };
        let result = await Http.get(options);
        if (result.status != 200) {
            dispatch(authActions.logout());
            return;
          }
        result.data.ce_start_date = convetDateToString(result.data.ce_start_date);
        result.data.ce_start_time = timeConvert(result.data.ce_start_time)
        result.data.ce_end_time = timeConvert(result.data.ce_start_time)
        setEventDetails(result.data);
        setShowModal(true);
    };


    useEffect(() => {
        fetchUpcomingEvents();
    }, [])

    return (
        <IonPage >
            <Header title='Calendar' />
            <IonContent className=''>
            <div className="filter-container" >
                    <h3 slot="start" className="page-tile" >
                        Calendar
                    </h3></div>
                <div className="filterBottomLine hide-on-mobile"></div>
                {(1) &&
                    <>
                        <h1 className="calendarTitle">Upcoming events to remember</h1>
                        <div className="eventCalendar">
                        <IonList>
                            {upcomingEvents.map((aEvent) =>
                                <IonItem key={aEvent.ce_id} onClick={() => fetchEventDetails(aEvent.ce_id)}>
                                   
                                        <IonIcon slot="start" src={starIcon} />
                                        <div className="calendarEventInfo">
                                            <h2>{aEvent.ce_start_date}</h2>
                                            <div>{`${aEvent.ce_title} (${aEvent.ce_start_time} - ${aEvent.ce_end_time})`}</div>
                                            <div>{aEvent.ce_location}</div>
                                        </div>
                                    
                                </IonItem>)}
                        </IonList>
                        </div>
                    </>}
            </IonContent >

            <IonModal isOpen={showModal} cssClass='calendarModal'>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle slot="start">New Discussion</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div className="calendarModalBody">
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12" className="eventLable">Event Description</IonCol>
                                <IonCol size="auto">{eventDetails.ce_description}</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3" className="eventLable">Date</IonCol>
                                <IonCol size="auto">{eventDetails.ce_start_date}</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3" className="eventLable">Detail</IonCol>
                                <IonCol size="auto">{`${eventDetails.ce_start_time} - ${eventDetails.ce_end_time}`}</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="3" className="eventLable">Location</IonCol>
                                <IonCol size="auto">{eventDetails.ce_location}</IonCol>
                            </IonRow>
                        </IonGrid>
                        <div className="calendarModalButton">
                            <IonButton mode="md" onClick={() => setShowModal(false)}>
                                OK
                            </IonButton>
                        </div>
                    </div>
                </IonContent>
            </IonModal>
        </IonPage >

    );
}
export default Calendar;