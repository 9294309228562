import React from "react";
import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from "@ionic/react"
import "./Members.css";

const MemberDetails = (data: any) => {
    return (
        <IonGrid className="member-details">
            <IonRow>
                <IonCol size="2">Name</IonCol>
                <IonCol size="auto">{`${data.data.hp_fname} ${data.data.hp_lname}`}</IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="2">Type</IonCol>
                <IonCol size="auto">{data.data.type}</IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="2">Phone</IonCol>
                <IonCol size="auto">{data.data.hp_phone_number}</IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="2">Email</IonCol>
                <IonCol size="auto">{data.data.hp_email}</IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="2">Status</IonCol>
                <IonCol size="auto">{data.data.statusLabel}</IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default MemberDetails;