import React from "react";
import { IonSlides, IonSlide, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow } from '@ionic/react'
import { isDesktop, isTablet } from '../../../Constants/general'
import './ListingDetails.css'
import phoneIcon from '../../../assets/menu-icons/phoneIcon.svg'
import mailIcon from '../../../assets/menu-icons/mailIcon.svg'
import Header from "../../../components/Header/Header";

const slideOpts = {
    initialSlide: 1,
    speed: 400
};

const ListingDetails: React.FC = () => {
    return (
        <IonPage>
            <Header title="" />
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol size={(isDesktop && isTablet) ? '5' : '10'}>
                            <a href="#" className="backToListing">Back to All Listings</a>
                            <div className="listingDetails">
                                <br />
                                <div className="title">World Market Dining Room Table</div>
                                <div className="price">$450 </div>
                                <hr />
                                <div className="dscription">
                                    <label>Description</label>
                                    <div>Purchased from World Market a few years ago.Solid dark brown wooden table.Table measures 86.5” L x 39.5” D x 31” H</div>
                                </div>
                                <div className="contact">
                                    <label> Contact Allyson Heilos</label>
                                    <div>
                                        <IonIcon src={phoneIcon} slot="start" />
                                        <span>&nbsp; &nbsp;   4808210985 (Call or Text)</span>
                                    </div>
                                    <div>
                                        <IonIcon src={mailIcon} slot="start" />
                                        <a href="mailto:allyson@pageperpage.com">&nbsp; &nbsp; allyson @pageperpage.com</a>
                                    </div>
                                </div>
                                <div className="reportButton">
                                    <a className="showModalButton btn-tertiary" title="Report Listing"><i className="glyphicon glyphicon-flag"></i> Report Listing</a>            </div>
                            </div>
                        </IonCol>
                        <IonCol size={(isDesktop && isTablet) ? '7' : '12'}>
                           <div className="listingSlider">
                           <IonSlides scrollbar={true} pager={true} options={slideOpts}>
                                <IonSlide >
                                    <div className="sliderImage"><img src="https://bit.ly/3p7gbic" alt="" /></div>
                                </IonSlide>
                                <IonSlide>
                                    <div className="sliderImage"><img src="https://bit.ly/3DKLDHd" alt="" /></div>
                                </IonSlide>
                                <IonSlide>
                                    <div className="sliderImage"><img src="https://bit.ly/3vi99s9" alt="" /></div>
                                </IonSlide>
                            </IonSlides>
                           </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default ListingDetails;