import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonInput,
  IonText,
  IonCheckbox,
  IonLabel,
  getPlatforms,
  IonRouterLink,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import { useHistory, useLocation } from "react-router-dom";

import "./Signup.css";
import LoginWrapper from "../LoginWrapper/LoginWrapper";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Http } from "@capacitor-community/http";
import LargeModal from "../../../components/Modal/LargeModal";
import blueWarning from "../../../assets/menu-icons/blue-warning.svg";
import { authActions } from '../../../store/auth';
import { useDispatch } from 'react-redux';

const Signup1: React.FC = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const history = useHistory();
  const [regCodeCommunity, setRegCodeCommunity] = useState("");
  const [regCodeProperty, setRegCodeProperty] = useState("");
  const [regCodeAccountNumber, setRegCodeAccountNumber] = useState("");
  const [unknownAccountNumber, setUnknownAccountNumber] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [comunityList, setComunityList] = useState([]);
  const [zipcodeState, setZipcodeState] = useState("");
  const [communityState, setCommunityId] = useState("");
  const [property, setProperty] = useState("");
  const [propertyList, setPropertyList] = useState([]);
  const [platform, setPlatform] = useState("");
  const [regCode, setRegCode] = useState(urlParams.get('reg_code'));

  const [successModal, setSuccessModal] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageName, setImageName] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      zipcode: "",
      comunity: "",
      property_address: "",
      account_number: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
      password_repeat: "",
    },
    mode: "onBlur",
  });

  const getComunityList = () => {
    return Object.entries(comunityList).map((el) => ({
      key: Object.values(el)[0],
      value: Object.values(el)[1],
    }));
  };

  const getPropertyList = () => {
    return propertyList;
  };

  const clearPropertiesList = () => {
    setPropertyList([]);
    setProperty("");
  }
  
  // ZIP state change
  useEffect(
    () => {

      const clearCommunityList = () => {
        setComunityList([]);
        setCommunityId("");
        clearPropertiesList();
      }

      const getCommunitiesByZipCode = async (zip: any) => {
        if (zip) {
          const url =
            process.env.REACT_APP_HP_API_URL +
            `/index.php/hoas/get-by-zip?zipcode=${zip}`;
          const options = {
            url,
          };
          const response = await Http.get(options);
          if (response.status == 200) {
            if (response.data.length == 0) {
              setError("zipcode", {
                type: "manual",
                message: "There are no communities for the zip code",
              });
              clearCommunityList();
            } else {
              clearErrors("zipcode");
              setComunityList(response.data);
            }
          }
        } else {
          clearCommunityList();
        }
      };

      setValue("zipcode", zipcodeState);
      getCommunitiesByZipCode(zipcodeState);
    },
    [zipcodeState]
  );

  // Community 
  useEffect(
    () => {

      const getPropertiesList = async (communityState: any) => {
        if (communityState) {
          const url =
            process.env.REACT_APP_HP_API_URL +
            `/index.php/hoas/get-properties?hoaId=${communityState}`;
          const options = {
            url,
            headers: { "Content-Type": "application/json" },
          };
          const response = await Http.get(options);
          if (response.status == 200) {
            // Refresh community list
            clearPropertiesList();
            setPropertyList(response.data);
          } else {
            clearPropertiesList();
          }
        }
      };
      setValue("comunity", communityState);
      if (communityState) {
        clearErrors('comunity');
      }

      getPropertiesList(communityState);
    },
    [communityState]
  );

  useEffect(
    () => {
      setValue("property_address", property);
      if (property) {
        clearErrors('property_address');
      }
    },
    [property]
  );
  
  useEffect(() => {

  }, []);

  useEffect(() => {
    if (getPlatforms().includes("desktop")) {
      setPlatform("desktop");
    } else {
      setPlatform("mobile");
    }
    setInitialLoad(false);
    getPropertyFromRegCode(regCode);
  }, []);


  const changeUnknownAccountNumber = (event: any) => {
    if (event.target.checked) {
      setUnknownAccountNumber(true);
      // Account # is not required when "I don't know is checked"
      setValue("account_number", "");
      clearErrors("account_number");
    } else {
      setUnknownAccountNumber(false);
    }
  };

  const switchNext = async (change_page: any = false) => {

    if (getValues('zipcode') == '') {
      setError('zipcode', {
        type: "manual",
        message: 'ZIP is required',
      });
    }

    if (getValues('comunity') == '') {
      setError('comunity', {
        type: "manual",
        message: 'Community is required',
      });
    }

    if (getValues('property_address') == '') {
      setError('property_address', {
        type: "manual",
        message: 'Property address is required',
      });
    }

    if (!unknownAccountNumber && getValues('account_number') == '') {
      setError('account_number', {
        type: "manual",
        message: 'Account number is required',
      });
    }

    if (
      getValues('zipcode') != '' &&
      getValues('comunity')    != '' &&
      getValues('property_address') != '' &&
      (unknownAccountNumber || getValues('account_number') != '' )
    ) {
      if (!stepTwo && initialLoad) {
        /* Direct link on Step 2, not sure how */
        setStepTwo(true);
        return;
      }else if (!stepTwo && !initialLoad && !regCode) {
        /* Step 2, after click next, not from the reg code */
        setStepTwo(true);
        return;
      }else if(!stepTwo && change_page){
        /* Step after click next */
        setStepTwo(true);
        return;
      }
    }
  };

  const moveBack = () => {
    setStepTwo(false);
  };

  const onSubmit = async () => {
    if (getValues('password') !== getValues('password_repeat')) {
      setError('password_repeat', {
        type: "manual",
        message: 'Password and confirm password are not the same',
      });
      return;
    }

    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/auth/register`;
    const options = {
      url,
      headers: { "Content-Type": "multipart/form-data" },
      data: {
        "HomeownerRegistrationForm[zipcode]": getValues("zipcode"),
        "HomeownerRegistrationForm[community]": getValues("comunity"),
        "HomeownerRegistrationForm[property_address]": getValues("property_address"),
        "HomeownerRegistrationForm[account_number]": getValues("account_number"),
        "HomeownerRegistrationForm[unknown_account]": unknownAccountNumber ? 1 : 0,
        "HomeownerRegistrationForm[fname]": getValues("first_name"),
        "HomeownerRegistrationForm[lname]": getValues("last_name"),
        "HomeownerRegistrationForm[phone_number]": getValues("phone_number"),
        "HomeownerRegistrationForm[email]": getValues("email"),
        'HomeownerRegistrationForm[password]': getValues('password'),
        'HomeownerRegistrationForm[password_repeat]': getValues('password_repeat'),
      },
    };
    const response = await Http.post(options);
    if (response.data.success && response.data.readyToLogin) {
      const url = process.env.REACT_APP_HP_API_URL + '/index.php/auth/login';

      const options = {
        url: url,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          'LoginForm[username]': getValues("email"),
          'LoginForm[password]': getValues('password'),
        },
      };
      const response = await Http.post(options);
      if (response.status == 200) {
        let properties = response.data.properties.propertiesList
        dispatch(
          authActions.login({
            isAuthenticated: true,
            token: response.data.token,
            loginUserFname: response.data.user_fname,
            loginUserLname: response.data.user_lname,
            loginUserPhoto: response.data.userPhoto,

            loginUserHoaId: response.data.properties.propertiesList[0].hoaId,
            loginUserPropertyId: response.data.properties.propertiesList[0].propertyId,

            loginUserRole: response.data.properties.propertiesList[0].role,
            loginUserAddress: response.data.properties.propertiesList[0].address,
            loginUserCity: response.data.properties.propertiesList[0].city,
            loginUserState: response.data.properties.propertiesList[0].state,
            loginUserZipcode: response.data.properties.propertiesList[0].zipcode,
            loginUserLeftMenu: JSON.stringify(response.data.properties.propertiesList[0].hoaLeftMenu),
            loginUserHoaName: response.data.properties.propertiesList[0].hoaName,
            loginUserHoaSubscription: response.data.properties.propertiesList[0].subscription
          })
        );
      }
    } else {
      setImageName(blueWarning);
      setSuccessModal(true);
      setTitle(response.data.messageHeading);
      setDescription(response.data.messageContent);
      setButtonText("Ok");
    }
  };

  const hidesuccessModal = async () => {
    await setSuccessModal(false);
    history.replace("/Login");
  };

  const getPropertyFromRegCode = async (regCode: any) => {

    if(regCode) {

      const url = `${process.env.REACT_APP_HP_API_URL}/index.php/hoas/get-property-from-reg-code?reg_code=${regCode}`;
      const options = {
        url: url
      };

      const response = await Http.get(options);

      if (response && response.status == 200 && response.data?.properties) {
        setRegCodeCommunity(response.data.communities[response.data.community]);
        setRegCodeProperty(response.data.idInPropertyList);
        setRegCodeAccountNumber(response.data.account_number);
        setZipcodeState(response.data.zipcode);
        setCommunityId(response.data.community);
        setValue("property_address", response.data.property_address);
        setValue("account_number", response.data.account_number);
        switchNext();
      } else {
        setStepTwo(false);
      }
    }
  };

  return (
    <LoginWrapper>
      <div className="mainContent">
        <div className={`${regCode ? "heading--blue reg-code" : "heading--blue"}`} >
          {regCode ? <div>Hi there! <br /> To sign up for Payment Reminders, please complete your account setup by providing a few details below.</div> : "Let's get started!"}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`${stepTwo ? "hidden" : "first-page"}`}>
            {/* ZIP */}
            <div className="form-input-group">
              <IonLabel className="form-input-label label--required">ZIP</IonLabel>
              <IonInput
                mode="md"
                {...register("zipcode", {
                  required: "Zip Code is required",
                })}
                type="text"
                placeholder="Type ZIP"
                className={regCodeProperty ? "form-input hidden" : "form-input"}
                onBlur={(e) => {
                  setZipcodeState(e.target.value ? e.target.value.toString() : "");
                }}
              ></IonInput>
              {regCodeProperty && <div>{zipcodeState}</div>}
              <ErrorMessage
                errors={errors}
                name="zipcode"
                as={<div className="error-message" />}
              />
            </div>

            {/* Community */}
            <div className="input-group">
              <IonLabel className="form-input-label label--required">Community</IonLabel>

              {/* <select
                className="form-select"
                name="comunity"
              >
                {getComunityList().map(({ key, value }) => (
                  <option key={key}>{value}</option>
                ))}
              </select> */}
              {!regCodeCommunity && <IonSelect 
                interface="action-sheet" 
                disabled={comunityList instanceof Array}
                value={communityState}
                placeholder={
                  !zipcodeState ? "Enter ZIP first" : "Select Community"
                }
                className="form-select"
                id="communitySelect"
                cancelText="Dismiss"
                onIonChange={
                  e => {
                   setCommunityId(e.detail.value);
                  }
                }
              >
                {/* Add enter ZIP option if needed */}
                {!zipcodeState && <IonSelectOption key="" value={communityState}>Enter ZIP first</IonSelectOption>}
                
                {getComunityList().map(({ key, value }) => (
                  <IonSelectOption key={key} value={key}>{value}</IonSelectOption>
                ))}
              </IonSelect>}
              {/* Read-only when populated from reg. code */}
              {regCodeCommunity && <div>{regCodeCommunity}</div>}

              <input
                {...register("comunity", {})}
                type="text"
                className="hidden"
                name="comunity"
                value={communityState} 
              />

              <ErrorMessage
                errors={errors}
                name="comunity"
                as={<div className="error-message" />}
              />
            </div>

            {/* Property Address */}
            <div className="input-group">
              <IonLabel className="form-input-label label--required">Property address</IonLabel>
              {!regCodeProperty && <IonSelect
                interface="action-sheet" 
                // disabled if property list is empty
                disabled={propertyList.length < 1}
                placeholder={communityState ? "Select Property address" : "Select Community first"} 
                className="form-select" 
                cancelText="Dismiss" 
                value={property}
                onIonChange={
                  e => {
                      setProperty(e.detail.value);
                    }
                  }
                >
                {!communityState && <IonSelectOption value="">Select Community first</IonSelectOption>}
                {getPropertyList().map(({ key, value }) => (
                  <IonSelectOption value={key} key={key}>
                    {value}
                  </IonSelectOption>
                ))}
              </IonSelect>}
              <input
                {...register("property_address", {})}
                type="text"
                className="hidden"
                name="property_address"
                value={property}
              />
              <ErrorMessage
                errors={errors}
                name="property_address"
                as={<div className="error-message" />}
              />
            </div>
            {/* Read-only when populated from reg. code */}
            {regCodeProperty && <div>{regCodeProperty}</div>}  


            {/* Account Number */}
            <div className="form-input-group">
              <IonLabel className="form-input-label">Account number</IonLabel>
              <IonInput
                mode="md"
                type="text"
                disabled={unknownAccountNumber}
                placeholder="As displayed on your assessment."
                className={regCodeProperty ? "form-input hidden" : "form-input"}
                {...register("account_number", {})}
              ></IonInput>
              {regCodeProperty && <div>{regCodeAccountNumber}</div>}  
              <ErrorMessage
                errors={errors}
                name="account_number"
                as={<div className="error-message" />}
              />
            </div>
            {!regCodeProperty && <div className="sub-text-3">
              <div className="check">
                <IonCheckbox
                  mode="md"
                  className="checkbox-style"
                  onClick={changeUnknownAccountNumber}
                ></IonCheckbox>
                I don't know my Account Number
              </div>
            </div>}
            <div className="next-button">
              <IonButton mode="md" type="button" onClick={switchNext.bind(true)}>
                Next
              </IonButton>
            </div>
          </div>

          <div className={`${!stepTwo ? "hidden" : "second-page"}`}>
            {/* First Name */}
            <div className="form-input-group">
              <IonLabel className="form-input-label label--required">First Name</IonLabel>
              <IonInput
                mode="md"
                {...register("first_name", {
                  required: "First Name is required",
                })}
                type="text"
                placeholder="First Name"
                className="form-input"
              ></IonInput>
              <ErrorMessage
                errors={errors}
                name="first_name"
                as={<div className="error-message" />}
              />
            </div>

            {/* Last Name */}
            <div className="form-input-group">
              <IonLabel className="form-input-label label--required">Last Name</IonLabel>
              <IonInput
                mode="md"
                {...register("last_name", {
                  required: "Last Name is required",
                })}
                type="text"
                placeholder="Last Name"
                className="form-input"
              ></IonInput>
              <ErrorMessage
                errors={errors}
                name="last_name"
                as={<div className="error-message" />}
              />
            </div>

            {/* Mobile Phone Number */}
            <div className="form-input-group">
              <IonLabel className="form-input-label label--required">Mobile Phone Number</IonLabel>
              <IonInput
                mode="md"
                {...register("phone_number", {
                  required: "Mobile number is required",
                })}
                type="text"
                placeholder="Mobile Phone Number"
                className="form-input"
              ></IonInput>
              <ErrorMessage
                errors={errors}
                name="phone_number"
                as={<div className="error-message" />}
              />
            </div>
            <div className="form-input-group">
              <IonLabel className="form-input-label label--required">Email Address</IonLabel>
              <IonInput
                mode="md"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                })}
                type="text"
                placeholder="Email Address"
                className="form-input"
              ></IonInput>
              <ErrorMessage
                errors={errors}
                name="email"
                as={<div className="error-message" />}
              />
            </div>

            {/** Password */}
            {!unknownAccountNumber && <div className="form-input-group">
              <IonLabel className="form-input-label label--required">Password</IonLabel>
              <IonInput
                mode="md"
                {...register("password", {
                  required: "Password is required",
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&\]*[_])(?=.*[1-9]).{8,}$/i,
                    message: "Password must contain at least 8 characters, 1 uppercase, 1 lower case, 1 symbol and 1 number",
                  },
                })}
                type="password"
                placeholder="Password"
                className="form-input"
              ></IonInput>
              <ErrorMessage
                errors={errors}
                name="password"
                as={<div className="error-message" />}
              />
            </div>}

            {/** Repeat Password */}
            {!unknownAccountNumber && <div className="form-input-group">
              <IonLabel className="form-input-label label--required">Confirm Password</IonLabel>
              <IonInput
                mode="md"
                {...register("password_repeat", {
                  required: "Confirm Password is required"
                })}
                type="password"
                placeholder="Confirm Password"
                className="form-input"
              ></IonInput>
              <ErrorMessage
                errors={errors}
                name="password_repeat"
                as={<div className="error-message" />}
              />
            </div>}

            <div className="submit-button-group">
              <IonButton fill="clear" mode="md" type="button" onClick={moveBack}>
                Back
              </IonButton>
              <IonButton mode="md" type="submit">
                Sign Up
              </IonButton>

            </div>
          </div>
        </form>
      </div>
      <div className="sub-text-4">
        <IonText>Already have an account?</IonText>
        <br />
        <IonRouterLink routerLink="/Login">
          <IonText class="btn-tertiary" color="tertiary">
            Log In
          </IonText>
        </IonRouterLink>
      </div>
      <LargeModal
        logo={imageName}
        title={title}
        description={description}
        buttonText={buttonText}
        isOpen={successModal}
        closeAction={hidesuccessModal}
      ></LargeModal>
    </LoginWrapper>
  );
};

export default Signup1;
